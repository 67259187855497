import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  redirectFromLogIn: false,
  user: null,
  tempUser: null, // This is for users who have a password challenge when creating a new user with a temp password
  idToken: null,
  accessToken: null,
  refreshToken: null,
  role: null,
  realRole: null,
  orgId: null,
  isLoading: false,
  isLoaded: false,
  error: false,
};

const checkAuthStateStart = (state, action) => {
  return update(state, {
    idToken: { $set: null },
    accessToken: { $set: null },
    refreshToken: { $set: null },
  });
};

const checkAuthStateSuccess = (state, action) => {
  // HACK: to prevent the role from changing when the admin is viewing the site as another role
  let role, orgId;
  if (action.isAuthenticated.isCleverUser) {
    role = state.realRole ? state.realRole : action.isAuthenticated['custom:role'];
    orgId = Array.isArray(action.isAuthenticated['custom:orgId']) ? action.isAuthenticated['custom:orgId'][0] : action.isAuthenticated['custom:orgId'];
  } else {
    role = state.realRole ? state.realRole : action.isAuthenticated.attributes['custom:role'];
    orgId = action.isAuthenticated.attributes['custom:orgId'];
  }
  return update(state, {
    isAuthenticated: { $set: action.isAuthenticated },
    realRole: { $set: role },
    role: { $set: role },
    orgId: { $set: orgId },
    idToken: { $set: action.idToken },
    accessToken: { $set: action.accessToken },
    refreshToken: { $set: action.refreshToken },
    orgs: { $set: action.orgs }
  });
};

const loginStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoaded: { $set: false },
    isLoading: { $set: true },
  });
};

const loginSuccess = (state, action) => {
  return update(state, {
    loggedIn: { $set: true },
    redirectFromLogIn: { $set: true },
    user: { $set: action.user },
    isLoaded: { $set: true },
    isLoading: { $set: false },
    idToken: { $set: action.idToken },
  });
};

const loginFail = (state, action) => {
  return update(state, {
    error: { $set: action.error },
    isLoading: { $set: false },
  });
};

const setTempUser = (state, action) => {
  return update(state, {
    tempUser: { $set: action.tempUser },
  });
};

const logoutStart = (state, action) => {
  return update(state, {
    error: { $set: null },
  });
};

const logoutFail = (state, action) => {
  return update(state, {
    loggedIn: { $set: false },
    user: { $set: null },
    error: { $set: action.error },
  });
};

const changePasswordStart = (state, action) => {
  return update(state, {
    error: { $set: null },
  });
};

const changePasswordSuccess = (state, action) => {
  return state;
};

const changePasswordFail = (state, action) => {
  return update(state, {
    error: { $set: action.error },
  });
};

const resetRole = (state, action) => {
  return update(state, {
    role: { $set: state.realRole },
    realRole: { $set: null },
  });
};

const changeRole = (state, action) => {
  return update(state, {
    role: { $set: action.role },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_AUTH_STATE_START:
      return checkAuthStateStart(state, action);
    case actionTypes.CHECK_AUTH_STATE_SUCCESS:
      return checkAuthStateSuccess(state, action);
    // login
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.SET_TEMP_USER:
      return setTempUser(state, action);
    // logout
    case actionTypes.LOGOUT_START:
      return logoutStart(state, action);
    case actionTypes.LOGOUT_SUCCESS:
      return initialState;
    case actionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);
    case actionTypes.CHANGE_ROLE:
      return changeRole(state, action);
    case actionTypes.RESET_ROLE:
      return resetRole(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
