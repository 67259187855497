import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/Spinner/Spinner';

function SyncButton(props) {
  return (
    <button onClick={() => props.startDistrictSync(props.orgId)} className="xtra-small-button green btn btn-primary">
      Sync
    </button>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.organizations.isDistrictSyncLoading,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    startDistrictSync: orgId => dispatch(actions.startDistrictSync(orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncButton);
