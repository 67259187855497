import * as actionTypes from '../actionTypes';

export const setLang = (value) => {
  return {
    type: actionTypes.SET_LANG,
    lang: value,
  };
};

export const playPause = (value) => {
  return {
    type: actionTypes.PLAY_PAUSE,
    playingAudio: value,
  };
};
