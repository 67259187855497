import React from 'react';
import rightArrow from '../../assets/imgs/icon-arrow-right.svg';

const ButtonArrow = props => (
  <button {...props}>
    <div className="row align-content-center">
      <div className="offset-3 col-6 align-content-center">
        {props.text}
      </div>
      <div className="col-3 align-content-center">
        <img alt={props.text} src={rightArrow} />
      </div>
    </div>
  </button>
);

export default ButtonArrow;
