import React from 'react';
import classnames from 'classnames';

const TMButton = props => (
  <button id={props.locatorId} disabled={props.disabled} className={classnames(`tm-button btn`, `${props.className}`)} onClick={props.onClick} title={props.title}>
    <div className="btn-content">{props.children}</div>
    {props.iconRight ? (
      <div className="btn-icon-right">
        <img src={props.iconRight} className="icon icon-pos-right" alt="icon" />
      </div>
    ) : (
      ''
    )}
  </button>
);

export default TMButton;
