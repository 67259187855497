import moment from 'moment';

export const months = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
];

export const days = [...Array(32).keys()].slice(1).map(n => (n.toString().length === 1 ? `0${n}` : n));

function getYears() {
  var yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let index = 0; index < 79; index++) {
    yearOptions.push(currentYear - index);
  }
  return yearOptions;
}

export const years = getYears();

export const constructBirthday = (year, month, day) => {
  return moment(`${year}-${month}-${day}`)
    .startOf('day')
    .valueOf(); // should be .unix() but that would cause backwards compatability issuesg
};

// export const createStringDate = (year,month,day)=>{
//   const date = moment(`${year}-${month}-${day}`)
//     .startOf('day')
//     .valueOf();
//     return moment(date).format('YYYY-MM-DD')
// };
// 
export const adjustDate = dateTime => {
  return moment.utc(dateTime * 1000);
};

export const dateFormat = process.env.REACT_APP_STAGE === 'uk' ? 'DD-MMM-YYYY' : 'MMM-DD-YYYY';
