import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Contact extends Component {
  

  render() {
    const isUK = process.env.REACT_APP_STAGE === 'uk';
    return (
      <div className="content">
        <div className="row">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a aria-current="page" className="active" href="/admin/">Home</a>
            </li>
            <li className="breadcrumb-item active">
              <span>Contact {isUK ? 'MyMynd' : 'Terrace Metrics'}</span>
            </li>
          </ol>
        </div>
        <h1>Contact {isUK ?'MyMynd' :'Terrace Metrics'}</h1>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              We are looking forward to hearing from you. Please feel free to get in touch and we will get back to you as soon as possible. 
            </p>
          </div>
          <div className="col-12 col-md-6">
            <ul className="no-list-style">
              {isUK ? null : <li><i class="zmdi zmdi-phone"></i> <a href="tel:1-800-470-4401">1-800-470-4401</a></li>}
              <li><i class="zmdi zmdi-email"></i> <a href={isUK ? "mailto:support@mymynd.co.uk": "mailto:info@terracemetrics.org"}>{isUK ? 'support@mymynd.co.uk' : 'info@terracemetrics.org'}</a></li>
              {isUK ? null : <li><i class="zmdi zmdi-time"></i> 8 a.m. to 10 p.m. EST</li>}
              <li><i class="zmdi zmdi-globe"></i> <a href={isUK ? "http://www.mymynd.co.uk" : "http://www.terracemetrics.org/"} target="_blank" rel="noopener noreferrer">{isUK ? 'www.mymynd.co.uk':'www.terracemetrics.org'}</a></li>
            </ul>
          </div>
        </div>

      </div>
    );
  }
}

export default connect()(withRouter(Contact));
