import axios from 'axios';
//import axios from '../../../utilities/http';

import * as actionTypes from '../actionTypes';
import { logError } from '../../../utilities/logging';

// eslint-disable-next-line no-unused-vars
const { REACT_APP_API_URL, STAGE } = process.env;

export const toggleDemographicRadioButton = (index, value) => {
  return {
    type: actionTypes.TOGGLE_DEMO_RADIO,
    index: index,
    value: value
  }
}

export const incrementDemographicQuestionIndex = () => {
  return {
    type: actionTypes.INC_DEMO_INDEX
  }
}

export const demographicAnswerSelected = questionText => {
  return {
    type: actionTypes.DEMO_ANSWER_SELECTED,
    questionText: questionText
  }
}

export const createInitialSurveyResponse = surveyData => {
  return {
    type: actionTypes.CREATE_SURVEY_RESPONSE,
    initialSurveyData: surveyData,
  };
};

export const pushSelectedAnswer = payload => {
  const re = /^validity+/g;
  return (dispatch) => {
    if (re.test(payload.answerData.questionResponseOptionsType)) {
      dispatch({
        type: actionTypes.PUSH_SELECTED_VALIDITY_ANSWER,
        answerData: payload.answerData
      })
    } else {
      dispatch({
        type: actionTypes.PUSH_SELECTED_ANSWER,
        indicatorData: payload.indicatorData,
        answerData: payload.answerData
      })
    }
  }
};

export const pushSecondaryIndicatorResponses = () => {
  return {
    type: actionTypes.PUSH_SECONDARY_INDICATOR_RESPONSES,
  };
};

export const incrementSurveyIndicatorIndex = () => {
  return {
    type: actionTypes.INCREMENT_SURVEY_INDICATOR_INDEX,
  }
};

export const incrementSurveyIndicatorResponseIndex = () => {
  return {
    type: actionTypes.INCREMENT_SURVEY_INDICATOR_RESPONSE_INDEX,
  }
};

export const saveSurveyResponseStart = () => {
  return {
    type: actionTypes.SAVE_SURVEY_RESPONSE_START
  };
};

export const saveSurveyResponseFail = err => {
  return {
    type: actionTypes.SAVE_SURVEY_RESPONSE_FAIL,
    err
  };
};

export const saveSurveyResponseSuccess = () => {
  return {
    type: actionTypes.SAVE_SURVEY_RESPONSE_SUCCESS

  };
};
export const incrementQuestionIndex = ()=> {
  return{
    type: actionTypes.INCREMENT_QUESTION_INDEX
  };
};

export const saveSurveyParticipantResponseData = (payload) => {
  // instead of payload do getState() or w/e
  // https://6du3j6ziaa.execute-api.us-east-1.amazonaws.com/test/
  return (dispatch) => {
    // SAVE_SURVEY_RESPONSE_START
    dispatch(saveSurveyResponseStart());
    axios({
      method: 'post',
      url: `${REACT_APP_API_URL}/participant/save`,
      data: payload,
    })
      .then((res) => {
        dispatch(saveSurveyResponseSuccess());
      })
      .catch((err) => {
        console.log('Save Survey Response Error: ', err);
        logError(err);
        dispatch(saveSurveyResponseFail(err));
      });
  };
};

export const toggleSurveyAnswer = (payload) => {
  const { isSelected, indexOptionSelected, answerValue } = payload;
  console.log('actionCreator: isSelected', isSelected)
  return {
    type: actionTypes.TOGGLE_SURVEY_ANSWER,
    answerValue,
    indexOptionSelected,
    isSelected,
  };
};
