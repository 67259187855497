import React, { Component } from 'react';
import TMCard from '../../../components/TMCard/TMCard';
import { getLogo } from './../../../assets/helper';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import AddStudent from '../../../assets/imgs/icon-plus-circle.svg';
import ButtonArrow from '../../../components/Button/ButtonArrow';

class SurveyEmailResults extends Component {
	state = {
		emailAddresses: [''],
		inputs: ['input-0']
	};

	createUI = () =>
		this.state.inputs.map(input => {
			return (
				<input
					className="mb-2"
					type="text"
					key={input}
					placeholder="email@address.com"
					onChange={this.emailAddressHandler.bind(this, input)}
				/>
			);
		});

	addInput = () => {
		const newInput = `input-${this.state.inputs.length}`;
		this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
	};

	completeSurveyAndRedirect = () => {
		console.log('Email address', this.props.surveyDataToSend.participantEmail);
		const { surveyDataToSend, saveSurveyParticipantResponseData } = this.props;
		if (this.props.receivedSurveyData.participantEmail && process.env.REACT_APP_STAGE === 'uk') {
			this.props.adultNotifyReport({
				surveyParticipantId: this.props.surveyDataToSend.surveyParticipantId,
				emailAddress: this.props.receivedSurveyData.participantEmail
			});
		}

		saveSurveyParticipantResponseData({
			...surveyDataToSend,
			surveySubmittedAt: Date.now()
		});
		this.props.history.push('/survey/submitting');
	};

	emailAddressHandler = (input, event) => {
		const newEmailArray = this.state.emailAddresses;
		newEmailArray[input.replace('input-', '')] = event.target.value;
		console.log(newEmailArray);
		this.setState({ emailAddresses: newEmailArray });
	};

	sendEmailHandler = async () => {
		this.state.emailAddresses.map(async emailAddress => {
			const emailData = {
				surveyParticipantId: this.props.surveyDataToSend.surveyParticipantId,
				emailAddress
			};
			await this.props.adultNotifyReport(emailData);
		});
		this.completeSurveyAndRedirect();
	};

	render() {
		return (
			<div className="page-container-survey-complete">
				<TMCard
					className="tm-card-stepper"
					header={[
						<div className="text-center mb-4">
							<img src={getLogo()} alt="Logo" />
						</div>
					]}
				>
					<p>
						In addition to the email already provided, would you like to send the results of this survey to
						another email address(if not, click continue and your report will be sent to you)?
					</p>
					{this.createUI()}
					<div className="mb-2">
						<img
							onClick={this.addInput}
							className="add-student-button"
							alt="Add Participant"
							src={AddStudent}
						/>
					</div>

					<div className="text-center mb-4">
						{this.state.emailAddresses && this.state.emailAddresses[0].length > 0 ? (
							<ButtonArrow
								className="button green"
								onClick={this.sendEmailHandler}
								type="submit"
								text="Send Email"
							/>
						) : (
							<ButtonArrow
                id='locator_complete-survey-button'
								className="button green"
								onClick={this.completeSurveyAndRedirect}
								type="submit"
								text="Continue"
							/>
						)}
					</div>
				</TMCard>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		survey: state.survey,
		surveyDataToSend: state.survey.surveyDataToSend,
		currentIndicatorIndex: state.survey.indicatorIndex,
		indicatorResponseIndex: state.survey.indicatorResponseIndex,
		answerValue: state.survey.answerValue,
		receivedSurveyData: state.survey.receivedSurveyData,
		isAnswerSelected: state.survey.isAnswerSelected
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveSurveyParticipantResponseData: surveyDataToSend =>
			dispatch(actions.saveSurveyParticipantResponseData(surveyDataToSend)),
		adultNotifyReport: emailData => dispatch(actions.adultNotifyReport(emailData))
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SurveyEmailResults)
);
