import React from 'react';
import {Dropdown} from 'react-bootstrap';

const DropdownButton = ({label,items,className}) => {
  console.log('Items',items)
  return <Dropdown alignRight>
    <Dropdown.Toggle className={className} size="lg" variant="success">
      {label}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {items.map((item,index)=>{
        console.log(item)
        return <Dropdown.Item key={`dropdownKey-${index}`} onClick={item.onClick}>{item.label}</Dropdown.Item>
      })}
    </Dropdown.Menu>
  </Dropdown>;
};

export default DropdownButton;