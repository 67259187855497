const supportedLanguages = {
  default: [
    {
      label: 'English (US)',
      value: 'en-US',
      audio: true,
    },
    {
      label: 'Español (US)',
      value: 'es-US',
    },
  ],
  uk: [
    {
      label: 'English (UK)',
      value: 'en-UK',
      audio: true,
    },
    {
      label: 'Español (US)',
      value: 'es-US',
    },
  ],
};

const getSupportedLanguages = () => {
  const fallback = 'default';
  const env = process.env.REACT_APP_STAGE || fallback;
  return supportedLanguages[env] || supportedLanguages[fallback];
};

// Using this syntax for polly build just in case its needed;
module.exports = getSupportedLanguages;
