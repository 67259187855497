import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import IconEmail from '../../../../../assets/imgs/icon-email.svg';
import Spinner from '../../../../../components/Spinner/Spinner';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import { json2csv } from 'json-2-csv';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import SyncButton from './SyncButton';
import formatPhoneNumber from '../../../../../utilities/formatPhoneNumber';
import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const ListDistricts = props => {
  const isUK = process.env.REACT_APP_STAGE === 'uk';

  async function exportFile(csv, fileTitle)
  {
    let exportedFilename = fileTitle + '.csv' || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  async function exportCSVFile(items, fileTitle) {
    
    console.log("CSF Items to Export:", items);
    await json2csv(
      items,
      (err, csv) => {
        let exportedFilename = fileTitle + '.csv' || 'export.csv';
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement('a');
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      { emptyFieldValue: '^' },
    );
  }
  const generateDistrictTableData = () => {
    // Waits for districts
    if (props.organizations && props.organizations.length > 0) {
      const result = props.organizations.map(item => {
        return {
          country: item.country,
          state: item.state,
          districtName:
            props.realRole === 'tm_admin' || props.role === 'tm_admin' ? (
              <button className="button-text" id={`locator_${item.name.replace(/\s/g, '-').toLowerCase()}`} onClick={() => props.changeRoleAndOrg(item, true)}>
                {item.name}
              </button>
            ) : (
              item.name
            ),
          championName: `${item.firstName || ''} ${item.lastName || ''}`,
          email: (
            <a href={`mailto:${item.email}`}>
              <img src={IconEmail} alt="envelope icon" />
            </a>
          ),
          employerProvider: item.employerProvider || '',
          report:
            //item.report && item.report.indicators.length > 0 ? (
              <button
                className="button-text"
                onClick={() => {
                  const url = `${REACT_APP_API_URL}/org/data?orgId=${item.orgId}`;
                  console.log(props);
                  console.log("orgId: " + item.orgId);
                  axios({
                    url,
                    method: 'GET',
                    headers: { Authorization: props.idToken },
                  })
                    .then(res => {
                      const resData = res.data;
                      //if(item.report && item.report.indicators.length > 0)
                      if(resData && resData.indicators.length > 0)
                      {
                        exportCSVFile(resData.indicators, `${item.name} - ${isUK ? 'Group' : 'Organization'} Indicator Report`);
                        const { satisfactory, optimal, at_risk, moderate, no_tfs, totalStudents } = resData;
                        exportCSVFile({ satisfactory, optimal, at_risk, moderate, no_tfs, totalParticipants: totalStudents }, `${item.name} - ${isUK ? 'Group' : 'Organization'} Overall Report`);
                        exportCSVFile(resData.surveyIndicatorResults, `${item.name} - Survey Indicator Results`);
                        exportCSVFile(resData.surveyOverallResults, `${item.name} - Survey Overall Results`);
                      }
                      else
                      {
                        console.log("No Data for " + item.orgId);
                      }
                      //console.log('Res startDistrictSync: ', res.data);
                      //dispatch(startDistrictSyncSuccess(res.data));
                      //dispatch(openToaster(`District has begun syncing!`));
                    })
                    .catch(err => {
                      console.log("issue ");
                      console.log(err);
                      //dispatch(startDistrictSyncFail(err));
                    });


                }}
              >
                Data
              </button>
            //) : null
            ,responses: 
            <button className='button-text' 
            onClick={() => {
              const url = `${REACT_APP_API_URL}/org/surveys?orgId=${item.orgId}`;
              console.log(props);
              console.log("orgId: " + item.orgId);
              axios({
                url,
                method: 'GET',
                headers: { Authorization: props.idToken },
              })
                .then(res => {
                  const resData = res.data;
                  const erl = res.data.url;
                  axios({
                    url: erl,
                    method: 'GET',
                    crossDomain: true,
                    headers: { 'Access-Control-Allow-Origin': '*' },
                  }).then(resl => {
                    exportCSVFile(resl.data,item.orgId + "_download.csv");
                      //exportFile(resl.data,"Downloadeddata.csv");
                    }
                  );
                  // if (resData.surveys)
                  // {
                  //   exportFile(resData.surveys, `${item.name} - ${isUK ? 'Group' : 'Organization'} Surveys CSV Data`);
                  // }
                  // if(resData.surveyResponses){
                  //   resData.surveyResponses.forEach(resp => {
                  //     if(resp && resp.items && resp.items.length > 0)
                  //     {
                  //       exportFile(resp.items, `${resp.name} - ${isUK ? 'Group' : 'Organization'} Responses CSV Data`);
                  //     }
                  //   });
                  // }
                })
                .catch(err => {
                  console.log("issue ");
                  console.log(err);
                });
            }}
            >
              Download
            </button>
            ,
          phone: formatPhoneNumber(item.phoneNumber),
          //cleverOrg: item.cleverData ? <CheckIcon className="blue-fill-icon" /> : '',
          cleverOrg: item.cleverData ? <SyncButton orgId={item.orgId} /> : null,
          icon: (
            <>
              <Edit className="blue-fill-icon" onClick={() => props.openCreateOrgModal(item, 'CREATE_DISTRICT_MODAL')} />
              <Delete className="blue-fill-icon" disabled={!!item.cleverItem} onClick={() => props.openDeleteOrgConfirmationModal(item)} />
            </>
          ),
        };
      });

      return result;
    }

    return [];
  };

  const getDistrictTableConfig = () => {
    const config = {
      headers: ['Country', `${isUK ? 'County' : 'State'}`, `${isUK ? 'Group' : 'Organization'} Name`, 'Champion Name', 'Email', 'Employer/Provider', 'Results', 'Responses', 'Phone', 'Clever Organization', ''],
      contentRows: generateDistrictTableData(),
    };
    return config;
  };
  return (
    <>
      {props.isLoadingOrganizations ? (
        <Backdrop>
          <Spinner />
        </Backdrop>
      ) : props.organizations && props.organizations.length === 0 && !props.hasSearched ? (
        <>
          <div className="text-center py-5">
            <h1>The {isUK ? 'Group' : 'Organization'} List is currently empty.</h1>
            <p>
              It looks like no {isUK ? 'groups' : 'organizations'} have been created. Click the button to create a new {isUK ? 'group' : 'organization'}.
            </p>
            <button onClick={() => props.openCreateOrgModal(null, 'CREATE_DISTRICT_MODAL')} className="button green mt-0">
              {isUK ? 'Create Group' : 'Create Organization'}
            </button>
          </div>
        </>
      ) : (
        <>
          <p>{`${props.organizations ? props.organizations.length : '0'} ${isUK ? 'Group' : 'Organization'} Results`}</p>
          {props.organizations && props.organizations.length > 0 ? <TableGenerator config={getDistrictTableConfig()} /> : null}
        </>
      )}
    </>
  );
};

export default ListDistricts;
