/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';
import classnames from 'classnames';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import * as QuizButton from '../QuizButton/QuizButton';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';
import { color, emoticon } from './config';
const TYPES = {
  FAT: 'fat',
  SKINNY: 'skinny',
};

const QuizButtonGroup = ({ answers, questionType, answerSelected, children, quizType, isSelected, elementary, indicatorName, responseType, questionId }) => {
  const tempOptions = [];
  answers.map(answer => tempOptions.push(answer.text));

  const [activeAnswer, setActiveAnswer] = useState({
    selectedIndex: null,
    selectedValue: null,
    options: tempOptions,
  });

  const toggleSelected = (index, value) => {
    setActiveAnswer(prevState => {
      answerSelected(true, value);
      return {
        selectedIndex: index,
        selectedValue: activeAnswer.options[index],
        options: activeAnswer.options,
      };
    });
  };
  const getAnswers = () => {
    const answer = answers.sort((a, b) => (a.value > b.value ? 1 : -1));
    return responseType === "strongly-disagree-1-to-strongly-agree-5" ? answer : answer.reverse() ;
  };
  const questionsWithEmoticons = [
    'd151318a-b333-5547-aed0-75b487060a1e',
    'c5f114cf-56e7-5d8e-918a-6dee97b07c53',
    '8485aeba-d8ac-548a-9e56-8282aa959236',
    'a0469b11-bba0-5503-b783-0787f822bb91',
    'dffd88c9-42e1-559f-aa9e-a368d0ffdc2a',
    '3b545b3d-b762-5265-966f-744b9da34e18',
    'f71879e6-4e0c-5953-957c-3fe0af4326cd',
    '486298a2-cd15-5817-a578-b8a15c671d3c',
    'e8b6e104-3523-534e-b2d1-ee907fe77531',
    'e85d1fa3-02e6-5c18-81f6-43117971f1b3',
    '0406e0e0-9b69-544e-b61c-2c01f90c8a6a',
    'cd708736-052d-54ab-a6d3-ce098e87d794',
    'b6a16839-cc4e-5d02-bfd2-31e56485cabb',
    'e24d047d-82fb-5887-9931-fb8fb4eb5bad',
    '03a166bd-2358-5fd5-a748-e559bf3754d9',
    'b2d813b3-8749-5da5-8513-1a7482646283',
  ];
  const shouldDisplayEmoticons = elementary && questionId && questionsWithEmoticons.includes(questionId);
  const keys = [];
  answers.map((answer, index) => {
    const newIndex = index + 1;
    return keys.push(`${newIndex}`);
  });
  return (
    <div className={classnames('quizButtonGroup', `${quizType}`)}>
      <KeyboardEventHandler
        handleKeys={keys}
        onKeyEvent={(key, e) => {
          const origIndex = parseInt(key, 10) - 1;
          toggleSelected(origIndex, answers[origIndex].value);
          console.log(key, e);
        }}
      />
      {getAnswers().map((answer, index) => {
        const isActive = activeAnswer.selectedIndex === index && isSelected;
        return React.cloneElement(children, {
          text: getContent(answer.field),
          key: index,
          active: isActive,
          index,
          icon: shouldDisplayEmoticons ? emoticon[answers.length][index] : null,
          color: questionType && answers.length ? color[answers.length][index] : null,
          onClick: toggleSelected,
          value: answer.value,
          audio: getAudio(answer.field),
          elementary: elementary,
        });
      })}
    </div>
  );
};

export const Fat = props => (
  <QuizButtonGroup {...props} quizType={TYPES.FAT}>
    <QuizButton.Fat />
  </QuizButtonGroup>
);
export const Skinny = props => (
  <QuizButtonGroup {...props} quizType={TYPES.SKINNY}>
    <QuizButton.Skinny />
  </QuizButtonGroup>
);
