import * as actionTypes from '../actionTypes';

export const openToaster = content => {
  return {
    type: actionTypes.OPEN_TOASTER,
    content,
  };
};

export const closeToaster = () => {
  return {
    type: actionTypes.CLOSE_TOASTER,
  };
};
