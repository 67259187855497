import React, { Component } from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';


class NotConcerningQuestionsPage extends Component {

  getSurveyType = () => {
    const surveyName = this.props.payload.surveyName || null;
    if (surveyName.includes('Adult')) {
      return 'adult';
    } else if (surveyName.includes('Uppergrades')) {
      return 'upper_grades';
    } else if (surveyName.includes('Elementary')) {
      return 'elementary';
    }
  }

  render() {
    const payload = this.props.payload;
    const indicatorsForNotConcerningResponses = payload.surveyResponses[0].indicators;
    // const indicatorsForAllResponses = payload.surveyResponses[0].indicators;
    // const questionsOfConcernResponsesIndicators = payload.indicators.filter(item => item.responses && item.responses.length > 0);
    // const questionsOfConcernSmIds = questionsOfConcernResponsesIndicators
    //   .map(indicator =>
    //     indicator.responses.map(response => response.questionSmId)
    //   )
    //   .reduce((acc, val) => [...acc, ...val], []);

    // const indicatorsForNotConcerningResponses = [];

    // for (const indicator of indicatorsForAllResponses) {
    //   const newIndicator = { ...indicator };
    //   newIndicator.responses = [];

    //   for (const response of indicator.responses)
    //     // if (questionsOfConcernSmIds.findIndex(smId => smId === response.questionSmId) === -1)
    //       newIndicator.responses.push(response);

    //   indicatorsForNotConcerningResponses.push(newIndicator);
    // }

    const surveyType = this.getSurveyType();

    if (payload.surveyResultIsValid && payload.validityQuestions) {
      return (
        <div className="page questions-of-concern">
          <main>
            <h1 className="mb-2 mt-2">{getContent('report_not-questions-of-concern')}</h1>
            <p className="mb-5">
              {getContent('report_not-questions-of-concern-intro').replace('{payload.participant.firstName}', payload.participant.firstName).replace('{payload.participant.lastName}', payload.participant.lastName)}
            </p>
            {/* Validity Questions */}

            {!payload.surveyResultIsValid && payload.validityQuestions ? (
              <div className="concerns-container">
                <h2 className="mb-4">{getContent('report_validity-questions')}</h2>
                <table className="table table-sm table-bordered results mb-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{getContent('report_question')}</th>
                      <th className="text-center">{getContent('report_answer')}</th>
                    </tr>
                    {payload.validityQuestions.map((item, index) => {
                      return (
                        <tr key={getContent(item.questionText, null, surveyType)}>
                          <td width="30" className="text-center">
                            {index + 1}.
                          </td>
                          <td>{getContent(item.questionText)}</td>
                          <td className="text-center">{getContent(item.responseOptionField, null, surveyType)}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            ) : null}

            {indicatorsForNotConcerningResponses && indicatorsForNotConcerningResponses.map((concern, ci) => {
              return (
                <div className="concerns-container" key={ci}>
                  <h2 className="mb-4">{getContent(concern.contentId || concern.indicator)}</h2>
                  <table className="table table-sm table-bordered results mb-4">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{getContent('report_question')}</th>
                        <th className="text-center">{getContent('report_answer')}</th>
                      </tr>
                      {concern.responses.map((qa, ni) => {
                        return (
                          <tr key={ni}>
                            <td width="30" className="text-center">
                              {ni + 1}.
                            </td>
                            <td>{getContent(qa.questionText, null, surveyType)}</td>
                            <td className="text-center">{getContent(qa.responseOptionField, null, surveyType)}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>

                  {/* end answers */}
                </div>
                // end concerns-container
              );
            })}
            {/* end at risk answers */}
          </main>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default NotConcerningQuestionsPage;
