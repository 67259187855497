import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreateDistrictForm from './CreateDistrictForm';
import Modal from 'react-bootstrap/Modal';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconSchool } from '../../../../../assets/imgs/icon-school.svg';
import ModalSplash from '../ModalSplash';
import CreateDistrictFormWrapper from './CreateDistrictFormWrapper';
import * as actions from '../../../../../store/actions';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import CreateDistrictFormClever from './CreateDistrictFormClever';
import activateItem from '../../../../../utilities/activateItem';
const { stateData } = require('../../../../../utilities/locale/').getLocale();


class CreateDistrictModal extends Component {
  state = {
    currentPage: 0,
  }

  componentDidMount() {
    console.log('this.props.item: ', this.props.item);
    if (this.props.item && this.props.item.cleverItem) {
      this.setState({ currentPage: 2 });
      return;
    }

    if (this.props.item) {
      console.log('item exists');
      this.setState({ currentPage: 1 });
      return;
    }
  }

  isUK = () => (process.env.REACT_APP_STAGE === 'uk');
  updateOrg = async (org) => {
    return await this.props.updateOrganization(org);
  }

  handleSubmit = async values => {
    console.log('CREATE ORG SUBMIT VALUES: ', values, ' item: ', this.props.item);
    const newItem = {
      country: values.country,
      name: values.name,
      classification: 'school-district',
      employerProvider: values.employerProvider,
      primary: values.primary,
      secondary: values.secondary,
      adult: values.adult,
    };
    newItem.state = (values.state !== null) ? values.state.abbreviation : "na";
    if (values.employerProvider === 'Provider' && this.isUK()) {
      newItem.isTMAllowed = false;
    } else {
      newItem.isTMAllowed = true;
    }

    // Updating Item
    if (this.props.item) {
      newItem.orgId = this.props.item.orgId;
      this.props.updateOrganization(newItem);
    }

    // Clever Item
    if (values.search) {
      const user = {
        id: values.searchUser[0].sub,
        isActivated: true,
        type: 'user'
      };
      const org = {
        ...values.search[0],
        orgId: values.search[0].orgId,
        isActivated: true,
        employerProvider: values.employerProvider,
        primary: values.primary,
        secondary: values.secondary,
        adult: values.adult,
      };
      await this.props.updateOrganization(org);
      await activateItem(user, this.props.idToken);
    }

    // Brand new item
    if (!this.props.item && !values.search) {
      newItem.firstName = values.firstName;
      newItem.lastName = values.lastName;
      newItem.email = values.email;
      newItem.phoneNumber = sanitizePhoneNumber(values.phoneNumber);
      this.props.createOrganization(newItem);
    }
  };
  getInitialValues = () => {
    const initialValues = {};
    if (this.props.item) {
      console.log('item in create org modal: ', this.props.item);
      const phoneNumber = this.props.item.phoneNumber ? this.props.item.phoneNumber.substring(2) : '';
      console.log(phoneNumber);
      // Initial Values Added here
      initialValues.country = this.props.item.country;
      // Find the State in StateData
      initialValues.state = stateData.find(state => this.props.item.state === state.abbreviation);
      initialValues.name = this.props.item.name;
      initialValues.primary = typeof this.props.item.primary === 'boolean' ? this.props.item.primary : true;
      initialValues.secondary = typeof this.props.item.secondary === 'boolean' ? this.props.item.secondary : true;
      initialValues.adult = typeof this.props.item.adult === 'boolean' ? this.props.item.adult : true;
      initialValues.employerProvider = this.props.item.employerProvider || null;
      // Not used in Edit for now
      // initialValues.firstName = this.props.item.firstName;
      // initialValues.lastName = this.props.item.lastName;
      // initialValues.email = this.props.item.email;
      // initialValues.phoneNumber = `${phoneNumber}`;
    }

    return initialValues;
  }


  render() {
    const orgType = this.isUK() ? 'Group' : 'Organization';
    const initialValues = this.getInitialValues();
    console.log('this.props in createDistrictModal', this.props);

    return (
      <ModalWrapper show={this.props.show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <IconSchool style={{ marginRight: '1rem' }} />
            <span>{this.props.item ? `Edit ${orgType}` : `Create new ${orgType}`}</span>
          </Modal.Title>
        </Modal.Header>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <Modal.Body bsPrefix="modalContent">
          {/* {this.state.currentPage === 0 && <ModalSplash
            title="Create a new district or add one from Clever."
            textOne="Create New"
            textTwo="Add from Clever"
            handleClickOne={() => this.setState({ currentPage: 1 })}
            handleClickTwo={() => this.setState({ currentPage: 2 })}
          />} */}
          {<CreateDistrictFormWrapper item={this.props.item}>
            <CreateDistrictForm
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              saveButtonText={this.props.item ? 'Save Edits' : `${this.isUK() ? 'Add Group' : 'Add Organization'}`}
              closeModal={this.props.closeModal}
              item={this.props.item}
              error={this.props.error}
            />
          </CreateDistrictFormWrapper>}
          {/* {this.state.currentPage === 2 && <CreateDistrictFormWrapper item={this.props.item}>
            <CreateDistrictFormClever
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              saveButtonText={this.props.item ? 'Save Edits' : `${this.isUK() ? 'Add Group' : 'Add District'}`}
              closeModal={this.props.closeModal}
              item={this.props.item}
              error={this.props.error}
              role={this.props.role}
              organization={this.props.organization}
              idToken={this.props.idToken}
            />
          </CreateDistrictFormWrapper>} */}
          {/* <CreateDistrictForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            saveButtonText={this.props.item ? 'Save Edits' : `${this.isUK() ? 'Add Group' : 'Add District'}`}
            closeModal={this.props.closeModal}
            item={this.props.item}
            error={this.props.error}
          /> */}
        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.organizations.error,
    role: state.auth.role,
    organization: state.organizations.organization,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(actions.createOrganization(values)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDistrictModal);
