import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import BootStrapButton from 'react-bootstrap/Button';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../store/actions';

class RetakeConfirmationModal extends Component {
	render() {
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Retake Survey</Modal.Title>
				</Modal.Header>
				<Modal.Body bsPrefix="modalContent survey-status-modal">
					<p>Please confirm that you would like to re-take your assessment, It is not recommended to retake an assessment more than once each month unless directed to do so by a member of our team or following completion of recommended content/activities</p>
					<div className="btnContainer-edit-participant">
						<BootStrapButton
							onClick={() => {
								this.props.confirm();
							}}
							type="submit"
							className="modalBtn"
							size="lg"
							variant="success"
						>
							Retake Assessment
						</BootStrapButton>
						<BootStrapButton
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							Cancel
						</BootStrapButton>
					</div>
				</Modal.Body>
			</ModalWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(actions.closeModal())
	};
};

export default connect(
	null,
	mapDispatchToProps
)(RetakeConfirmationModal);
