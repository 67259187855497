import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import axios from 'axios';
import BootStrapButton from 'react-bootstrap/Button';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import SchoolChampionFields from './SchoolChampionFields';
import { ReactComponent as IconPlusCircle } from '../../../../../assets/imgs/icon-plus-circle.svg';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import Typeahead from '../../../../../components/Typeahead/Typeahead';

const SearchField = (props) => {
  return (<div className="label-field-container">
    <label className="label" htmlFor={`searchUser-${props.index}`}>Search Group Users</label>
    <Field
      name={`searchUser-${props.index}`}
      component={props.component}
      handleSearch={props.handleSearch}
      orgSearch={props.orgSearch}
      disabled={!props.orgSearch || props.disabled}
      onChange={(value) => props.onChange(value, props.index)}
    />
  </div>)
};

class CreateSchoolFormClever extends Component {
  handleOrgSearch = (query, setOptions, setIsLoading) => {
    console.log('query', query);
    console.log('setOptions: ', setOptions);

    setIsLoading(true);

    let classification = 'school-district';
    if (this.props.role === 'district_champion') {
      classification = 'school';
    }
    console.log('organization: ', this.props.organization);
    const { REACT_APP_API_URL } = process.env;
    const orgId = this.props.organization ? this.props.organization.orgId : '';
    const url = `${REACT_APP_API_URL}/org/search?classification=${classification}&parentOrgId=${orgId}&name=${query || ''}&elastic=true`;
    let options = [];

    axios({
      url,
      method: 'GET',
      headers: { Authorization: this.props.idToken },
    })
      .then(res => {
        //res.data.hasSearched = Object.entries(values).length > 0 && values.constructor === Object;
        // const data = res.data.map(org => {
        //   if (org.responderIds) {
        //     org.responderIds = org.responderIds.map(responderId => responderId.S);
        //   }
        //   return org;
        // });

        options = res.data;
        setOptions(options);
        setIsLoading(false);
        console.log('res Organizations success: ', res.data);
      })
      .catch(err => {
        console.log('Error searching orgs: ', err);
        setOptions(options);
        setIsLoading(false);
      });
  };

  handleUserSearch = (query, setOptions, setIsLoading) => {
    console.log('query', query);
    console.log('setOptions: ', setOptions);

    setIsLoading(true);

    const { REACT_APP_API_URL } = process.env;
    const orgId = this.props.orgSearch ? this.props.orgSearch[0].orgId : '';
    console.log('this.props in createDistrictFormClever : ', this.props);
    console.log('orgId in use search: ', orgId);
    const url = `${REACT_APP_API_URL}/users/search?orgId=${orgId}&name=${query || ''}`;
    let options = [];

    axios({
      url,
      method: 'GET',
      headers: { Authorization: this.props.idToken },
    })
      .then(res => {
        //res.data.hasSearched = Object.entries(values).length > 0 && values.constructor === Object;
        // const data = res.data.map(org => {
        //   if (org.responderIds) {
        //     org.responderIds = org.responderIds.map(responderId => responderId.S);
        //   }
        //   return org;
        // });

        console.log('response from user search: ', res);

        options = res.data;
        setOptions(options);
        setIsLoading(false);
        console.log('res Organizations success: ', res.data);
      })
      .catch(err => {
        console.log('Error searching orgs: ', err);
        setOptions(options);
        setIsLoading(false);
      });
  };

  renderDropdown = ({ input, data, valueField, textField, className, disabled }) => {
    return (
      <Dropdown
        {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        onChange={input.onChange}
        className={className}
        disabled={disabled}
      />
    );
  };

  orgChangeHandler = (value) => {
    console.log('orgChangeHandler: ', value);
    const formName = 'create-school-form-clever';
    const item = value[0];
    if (!item) return;
    this.props.dispatch(change(formName), 'search', value);
    this.props.dispatch(change(formName, 'name', item.name));
    this.props.dispatch(change(formName, 'country', item.country));
    this.props.dispatch(change(formName, 'state', { abbreviation: item.state }));
  }

  userChangeHandler = (value, index) => {
    console.log('userChangeHandler: ', value);
    const formName = 'create-school-form-clever';
    const item = value[0];
    if (!item) return;
    this.props.dispatch(change(formName), `searchUser-${index}`, value);
    this.props.dispatch(change(formName, `firstName-${index}`, item.firstName || item.given_name || ''));
    this.props.dispatch(change(formName, `lastName-${index}`, item.lastName || item.family_name || ''));
    this.props.dispatch(change(formName, `phoneNumber-${index}`, item.phoneNumber || ''));
    this.props.dispatch(change(formName, `email-${index}`, item.email || ''));
  }

  renderOrgsTypeahead = (props) => {
    console.log('typeahead props: ', props);
    const { input } = props;
    return (
      <Typeahead
        {...input}
        handleSearch={props.handleSearch}
        onChange={input.onChange}
        disabled={props.disabled}
      />
    );
  };

  renderUsersTypeahead = (props) => {
    console.log('typeahead props: ', props);
    const { input } = props;
    return (
      <Typeahead
        {...input}
        handleSearch={props.handleSearch}
        onChange={input.onChange}
        labelKey="email"
        disabled={!props.orgSearch || props.disabled}
        renderMenuItemChildren={(option, props) => {
          return (
            <>
              <span>{option.given_name} {option.family_name} ({option.email})</span>
            </>
          );
        }}
      />
    );
  };

  isUK = () => { return process.env.REACT_APP_STAGE === 'uk'; };

  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="label-field-container">
          <label className="label" htmlFor="search">Search Groups</label>
          <Field name="search" component={this.renderOrgsTypeahead} handleSearch={this.handleOrgSearch} onChange={this.orgChangeHandler} />
        </div>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="name">
              {this.isUK() ? 'Organisation Name' : 'Group Name'}
            </label>
            <Field
              name="name"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
              disabled={true}
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="address">
              {this.isUK() ? 'Organisation Address' : 'Group Address'}
            </label>
            <Field
              name="address"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
              disabled={true}
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="city">
              {this.isUK() ? 'Organisation City' : 'Group City'}
            </label>
            <Field
              name="city"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
              disabled={true}
            />
          </div>
        </div>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container col-4 pl-0 pr-2">
            <label className="label" htmlFor="phoneNumber">
              {this.isUK() ? 'Organisation Phone' : 'Group Phone'}
            </label>
            <Field
              name="phoneNumber"
              type="tel"
              component={RenderPhoneField}
              className='participant-entry-field'
              disabled={true}
            />
          </div>
        </div>
        <div>
          {this.props.champions.map((item, index) => {
            return <SchoolChampionFields
              key={index}
              index={index}
              removeChampion={this.props.removeChampion}
              searchField={<SearchField
                index={index}
                component={this.renderUsersTypeahead}
                handleSearch={this.handleUserSearch}
                orgSearch={this.props.orgSearch}
                onChange={this.userChangeHandler}
              />}
            />;
          })}
          {this.props.addChampion ?
            <button onClick={this.props.addChampion} className="btn px-0">
              <IconPlusCircle style={{ marginRight: '0.5rem' }} />
              {this.isUK() ? 'Add Organisation Champion' : 'Add Group Champion'}
            </button>
            : null}
        </div>
        <div className="btnContainer justify-content-end">
          <BootStrapButton
            type="submit"
            className="small-button green"
            size="lg"
            variant="success"
            disabled={invalid || pristine || submitting}
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton className="small-blue-button-outlined" size="lg" variant="outline-primary" onClick={e => this.props.closeModal(e)}>
            Cancel
          </BootStrapButton>
        </div>
      </form>
    );
  }
}

CreateSchoolFormClever = reduxForm({
  form: 'create-school-form-clever'
})(CreateSchoolFormClever);

const selector = formValueSelector('create-school-form-clever') // <-- same as form name
CreateSchoolFormClever = connect(state => {
  // can select values individually
  const orgSearch = selector(state, 'search');
  return {
    orgSearch,
  }
})(CreateSchoolFormClever);

export default CreateSchoolFormClever;