import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Spinner from '../../../../../components/Spinner/Spinner';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import formatPhoneNumber from '../../../../../utilities/formatPhoneNumber';

const ListSchools = props => {
  const isUK = process.env.REACT_APP_STAGE === 'uk';
  const generateSchoolTableData = () => {
    // Waits for districts
    if (props.organizations && props.organizations.length > 0) {
      const result = props.organizations.map(item => {
        console.log('item school: ', item);
        return {
          name:
            props.realRole === 'tm_admin' || props.role === 'tm_admin' || props.role === 'district_champion' ? (
              <button className="button-text" id={`locator_${item.name.replace(/\s/g, '-').toLowerCase()}`} onClick={() => props.changeRoleAndOrg(item, false)}>
                {item.name}
              </button>
            ) : (
              item.name
            ),
          address: item.address,
          city: item.city,
          phone: formatPhoneNumber(item.phoneNumber),
          icon: (
            <>
              <Edit className="blue-fill-icon" onClick={() => props.openCreateOrgModal(item, 'CREATE_SCHOOL_MODAL')} />
              <Delete className="blue-fill-icon" disabled={!!item.cleverItem} onClick={() => props.openDeleteOrgConfirmationModal(item)} />
            </>
          ),
        };
      });

      return result;
    }

    return [];
  };

  const getSchoolTableConfig = () => {
    const config = {
      headers: ['Name', 'Address', 'City', 'Phone', ''],
      contentRows: generateSchoolTableData(),
    };

    return config;
  };
  return (
    <>
      {props.isLoadingOrganizations ? (
        <Backdrop>
          <Spinner />
        </Backdrop>
      ) : props.organizations && props.organizations.length === 0 && !props.hasSearched ? (
        <>
          <div className="text-center py-5">
            <h1>The {isUK ? 'Organisation' : 'Group'} list is currently empty.</h1>
            <p>
              It looks like no {isUK ? 'organisation' : 'group'} have been created. Click the button to create a new {isUK ? 'organisation' : 'group'}.
            </p>
            <button onClick={() => props.openCreateOrgModal(null, 'CREATE_SCHOOL_MODAL')} className="button green mt-0">
              Create {isUK ? 'Organisation' : 'Group'}
            </button>
          </div>
        </>
      ) : (
        <>
          <p>{`${props.organizations ? props.organizations.length : '0'} ${isUK ? 'Organisation' : 'Group'} Results`}</p>
          {props.organizations && props.organizations.length > 0 ? <TableGenerator config={getSchoolTableConfig()} /> : null}
        </>
      )}
    </>
  );
};

export default ListSchools;
