import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { SubmissionError } from 'redux-form';

import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import AddParticipantForm from './AddParticipantForm';

import { constructBirthday } from '../../../../../utilities/dates';

import * as actions from '../../../../../store/actions';

class AddParticipantModal extends Component {
  handleParticipantSubmission = values => {
    const { surveyId, orgId } = this.props;
    const index = 0;
    const birthday = constructBirthday(values[`year-${index}`], values[`month-${index}`].value, values[`day-${index}`]);
    if (!moment(birthday).isValid()) {
      throw new SubmissionError({ _error: 'Invalid Birthday' });
    }
    const participant = {
      surveyId,
      orgId,
      birthDate: birthday,
      firstName: values[`firstName-${index}`],
      middleName: values[`middleName-${index}`],
      lastName: values[`lastName-${index}`],
      grade: !Object.keys(values).includes(`grade-${index}`) ? null : values[`grade-${index}`].toString(),
      groupName: values[`group-name-${index}`],
      studentId: values[`studentId-${index}`],
      gender: values[`custom-gender-${index}`] ? values[`custom-gender-${index}`] : values[`gender-${index}`].value,
      parentEmail: values[`parentEmail-${index}`],
      parentMobile: values[`parentMobile-${index}`],
      participantEmail: values[`participantEmail-${index}`],
    };

    this.props.addParticipantToSurvey(participant);
  };

  render() {
    return (
      <ModalWrapper className="add-participant-modal-wrapper" show={this.props.show} onExited={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix="modalContent add-participant-modal">
          <AddParticipantForm manualRoster={this.props.manualRoster} surveyName={this.props.surveyName} onSubmit={this.handleParticipantSubmission} closeModal={this.props.closeModal} />
        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    manualRoster: state.surveyCrud.manualRoster,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addParticipantToSurvey: (participant, surveyId) => dispatch(actions.addParticipantToSurvey(participant, surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddParticipantModal);
