import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import * as actions from '../../store/actions';

class ModalWrapper extends Component {
  state = {
    show: this.props.show,
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
    });
  }

  closeModal = () => {
    this.setState({ show: false });
    this.props.closeModal();
    if (this.props.resetModalErrorsOnClose) {
      this.props.clearUserErrors();
      this.props.clearOrganizationErrors();
    }
  }

  render() {
    return (
      <Modal
        centered={true}
        className={this.props.className}
        onExited={this.props.onExited || this.closeModal}
        onHide={this.props.onHide || this.closeModal}
        show={this.state.show}
        size={this.props.size}
      >
        {this.props.children}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
    clearUserErrors: () => dispatch(actions.clearUserErrors()),
    clearOrganizationErrors: () => dispatch(actions.clearOrganizationErrors()),
  }
};

export default connect(null, mapDispatchToProps)(ModalWrapper);
