import React, { Component } from 'react';
import RadioButton from '../RadioButton/RadioButton';
import { getContent, getAudio } from '../../utilities/getContent/getContent'

class RadioGroup extends Component {

  render() {
    return (
      <div className='radioGroup' onKeyDown={this.props.onKeyDown}>
        {

          this.props.options.map((option, i) => {
            return (
              <RadioButton
                key={i}
                isChecked={this.props.selectedIndex === i}
                text={getContent(option.field)}
                field={option.field}
                value={option.value}
                index={i}
                handler={ () => this.props.radioGroupHandler(i, option.value) }
                tabIndex={0}
                audio={this.props.showAudioButton ? getAudio(option.field) : null}
              />
            );
          })
        }
      </div>
    )
  }
}

export default RadioGroup;
