import React, { useState } from 'react';
import { connect } from 'react-redux';
// import * as Button from '../../../components/Button/Button';
import * as actions from '../../../store/actions';
import * as QuizButtonGroup from '../QuizButtonGroup/QuizButtonGroup';
// import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';
import TMButton from '../../../components/TMButton/TMButton';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';

const ParticipantInfoConfirmation = props => {
  const [answerSelected, setAnswerSelected] = useState(false);
  const [answerValue, setAnswerValue] = useState(null);
  const toggleAnswer = (isAnswerSelected, value) => {
    setAnswerSelected(isAnswerSelected);
    setAnswerValue(value);
  };

  const openInvalidUserModal = () => {
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      closeModal: props.closeModal,
      headline: 'Contact your proctor',
      content: 'If this information listed was not correct, please contact the proctor of your test to let them know your information is not correct.',
    });
  };

  const isAdult = props.surveyName.includes('Adult');
  console.log('Is Adult? ', isAdult);

  return (
    <div className="container-participant-confirmation">
      <div className="heading">
        <p className="subhead">{getContent('survey_subhead-participant-confirmation')}</p>
        <p className="head">{getContent('survey_heading-participant-confirmation')}</p>
        <AudioButton
          className="mt-2"
          label={getContent('survey_button-read-question')}
          playlist={[
            getAudio('survey_heading-participant-confirmation'),
          ]}
        />
      </div>
      <div className="studentInformation">
        <div>
          <span>
            <strong>{getContent('survey_participant-name-label')}</strong>{' '}
          </span>
          <span>{props.userName}</span>
        </div>
        {isAdult 
          ? ''
          : <div className="gradeLine">
              <span>
                <strong>{getContent('survey_participant-grade-label')}</strong>{' '}
              </span>
              <span>{props.userGrade}</span>
            </div> }
        {isAdult
          ? ''
          : <div>
              <span>
                <strong>{getContent('survey_participant-school-label')}</strong>{' '}
            </span>
            <span>{props.userSchoolName}</span>
          </div>}
      </div>
      <QuizButtonGroup.Fat
        answers={[
          { field: 'response_yes', value: 1 },
          { field: 'response_no', value: 2 },
        ]}
        isSelected={answerSelected}
        answerSelected={toggleAnswer}
      />
      <div className={'row justify-content-center'}>
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={(key, e) => {
            if (answerSelected) {
              if (answerValue === 1) {
                if (isAdult){
                  props.history.push('/survey/consent');
                } else{
                  props.history.push('/survey/demographics');
                } // should probably dispatch an action here for surveyStartedAt
              } else if (answerValue === 2) {
                openInvalidUserModal();
                //props.history.push('/survey');
              }
            }
          }}
        />
        <TMButton
          className={`btn-round btn-green btn-lg w-300`}
          onClick={() => {
            if (answerValue === 1) {
              if (isAdult){
                props.history.push('/survey/consent');
              } else{
                props.history.push('/survey/demographics');
              } // should probably dispatch an action here for surveyStartedAt
            } else if (answerValue === 2) {
              openInvalidUserModal();
              //props.history.push('/survey');
            }
          }}
          locatorId='locator_next-button'
          disabled={!answerSelected}
          iconRight={ArrowRight}
        >
          {getContent('survey_button-next-question')}
        </TMButton>
      </div>
    </div>
  );
};

const mapStatetoProps = state => {
  console.log('STATE: ', state);
  return {
    userName: state.studentAuth.userName,
    userGrade: state.studentAuth.userGrade,
    userSchoolName: state.studentAuth.userSchoolName,
    surveyName: state.survey.receivedSurveyData.surveyName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    // saveSurveyParticipantResponseData: (surveyDataToSend) => dispatch(saveSurveyParticipantResponseData(surveyDataToSend))
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  )(ParticipantInfoConfirmation),
);
