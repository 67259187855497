import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';
import getSupportedLanguages from '../../utilities/getContent/supportedLanguages';

const supportedLanguages = getSupportedLanguages();

const initialState = {
  lang: supportedLanguages[0]['value'] || 'en-US',
};

const setLang = (state, action) => {
  return update(state, {
    lang: { $set: action.lang },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANG:
      return setLang(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
