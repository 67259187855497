import React, { Component } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import BootStrapButton from 'react-bootstrap/Button';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import { getContent } from '../../../../../utilities/getContent/getContent';
import { isValidEmail, isValidPhone } from '../../../../../utilities/validation';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import { dateFormat, adjustDate } from '../../../../../utilities/dates';

// eslint-disable-next-line no-unused-vars
import * as actions from '../../../../../store/actions';

class EditParticipantForm extends Component {
	state = {
		status: this.props.item.participantStatus,
		contacted: this.props.item.contacted,
		parentEmail: this.props.item.parentEmail ? this.props.item.parentEmail : '',
		parentMobile: this.props.item.parentMobile ? this.props.item.parentMobile : '',
		groupName: this.props.item.groupName ? this.props.item.groupName : '',
		participantEmail: this.props.item.participantEmail ? this.props.item.participantEmail : '',
		notes: this.props.item.notes ? this.props.item.notes : '',
		gender: this.props.item.gender ? this.props.item.gender : ''
	};

	renderDropdown = ({ input, ...rest }) => <DropdownList {...input} {...rest} onChange={input.onChange} />;

	handleStatusChange = value => {
		console.log(value);
		this.setState({ status: value });
	};

	handleNotifyFieldChange = (field, event) => {
		this.setState({ [field]: event.target.value });
	};

	isUK = () => process.env.REACT_APP_STAGE === 'uk';

	changeGroupName = e => {
		this.setState({ groupName: e.target.value });
	};

	toggleContacted = () => {
		this.setState({ contacted: !this.state.contacted });
	};

	changeNote = e => {
		this.setState({ notes: e.target.value });
	};

	changeParticipantEmail = e => {
		this.setState({ participantEmail: e.target.value });
	};

	getGenderLabelFromOption = () => {
		if (this.state.gender) {
			if (this.state.gender === 'm') {
				return 'Male';
			} else if (this.state.gender === 'f') {
				return 'Female';
			} else if (this.state.gender === 'no_response') {
				return this.isUK() ? 'Preferred not to answer' : 'N/A';
			} else {
				return this.state.gender;
			}
		}
	};

	render() {
		return (
			<div>
				<div className="modal-header-edit-participant">
					<div>
						<label className="label" htmlFor="username">
							Participant Name
						</label>
						<p>{`${this.props.item.firstName} ${this.props.item.lastName}`}</p>
					</div>
					{!this.props.surveyName.includes('Adult') ? (
						<div>
							<label className="label" htmlFor="username">
								{getContent('admin_participant-entry-grade')}
							</label>
							<p>{this.props.item.grade}</p>
						</div>
					) : null}
					<div>
						<label className="label" htmlFor="username">
							Date of Birth
						</label>
						<p>{adjustDate(this.props.item.birthDate).format(dateFormat)}</p>
					</div>
					<div>
						<label className="label" htmlFor="username">
							{this.props.surveyName.includes('Adult') ? 'Participant ID' : 'Student ID'}
						</label>
						<p>{this.props.item.studentId ? this.props.item.studentId : '-'}</p>
					</div>
					{this.props.isEmployerAdmin ? null : (
						<>
							<div>
								<label className="label" htmlFor="username">
									Priority
								</label>
								<p>
									{this.props.item.priority || this.props.item.priority === 0
										? this.props.item.priority
										: '-'}
								</p>
							</div>
							<div>
								<label className="label" htmlFor="username">
									Wellness Code
								</label>
								<p>
									{this.props.item.parentSurveyAuthCode
										? this.props.item.parentSurveyAuthCode.length === 16
											? this.props.item.parentSurveyAuthCode.match(/.{1,4}/g).join('-')
											: this.props.item.parentSurveyAuthCode
										: '-'}
								</p>
							</div>
						</>
					)}
				</div>
				<div className="flex-row">
					{!this.props.surveyName.includes('Adult') || this.state.parentEmail ? (
						<div className="label-field-container">
							<label className="label" htmlFor="parentEmail">
								Notification Email
							</label>
							<input
								name="parentEmail"
								value={this.state.parentEmail}
								onChange={event => this.handleNotifyFieldChange('parentEmail', event)}
								placeholder="Notification Email (optional)"
								type="text"
								component="input"
								validate={[isValidEmail]}
								className="participant-entry-field"
							/>
						</div>
					) : null}

					<div className="label-field-container">
						<label className="label" htmlFor="parentMobile">
							Notification Mobile
						</label>
						<PhoneInput
							name="parentMobile"
							value={this.state.parentMobile}
							defaultCountry={process.env.REACT_APP_STAGE === 'uk' ? 'gb' : 'us'}
							placeholder="(Optional) Include Country Code"
							onChange={fieldValue =>
								this.handleNotifyFieldChange('parentMobile', {
									target: { value: sanitizePhoneNumber(fieldValue) }
								})
							}
							type="tel"
							component="input"
							validate={[isValidPhone]}
							className="participant-entry-field"
						/>
					</div>
				</div>
				<div className="flex-row">
					<div>
						<label className="label" htmlFor="status">
							Status
						</label>
						<DropdownList
							data={['Not Started', 'Not Completed']}
							className="tm-dropdown"
							onChange={this.handleStatusChange}
							value={this.state.status}
							disabled={
								this.props.initialValues.status === 'Completed' ||
								this.props.surveyStatus !== 'In Progress'
							}
						/>
					</div>

					{this.props.isEmployerAdmin ? null : (
						<div>
							<p className="label">Results</p>
							<label className="student-results-label-edit-participant">
								<img
									className="checkbox border border-dark rounded"
									alt="Checkbox"
									src={this.state.contacted ? CheckedImage : UncheckedImage}
								/>
								<input
									name="contacted"
									type="checkbox"
									onChange={this.toggleContacted}
									value={this.state.contacted}
									ischecked={this.state.contacted ? 'checked' : undefined}
									disabled={this.props.initialValues.status !== 'Completed'}
								/>
								Participant has been contacted and result validated
							</label>
						</div>
					)}

					<div className="participant-row mb-5">
						<div className="label-field-container">
							<label className="label">{this.isUK() ? 'Grouping' : 'Group'} Name</label>
							<input
								className="participant-entry-field"
								onChange={this.changeGroupName}
								value={this.state.groupName}
							></input>
						</div>
					</div>
				</div>
				<div className="flex-row">
					<div className="label-field-container">
						<label className="label" htmlFor="participantEmail">
							Participant Email
						</label>
						<input
							name="participantEmail"
							value={this.state.participantEmail}
							onChange={event => this.handleNotifyFieldChange('participantEmail', event)}
							placeholder="Participant Email"
							type="text"
							component="input"
							validate={[isValidEmail]}
							className="participant-entry-field"
						/>
					</div>
					<div className="label-field-container">
						<label className="label" htmlFor="gender">
							Gender
						</label>
						<p>{this.state.gender ? this.getGenderLabelFromOption() : '-'}</p>
					</div>
				</div>
				{this.props.isEmployerAdmin ? null : (
					<>
						<div className="flex-row">
							<div className="label-field-container">
								<label className="label">Notes</label>
								<textarea
									value={this.state.notes}
									onChange={this.changeNote}
									placeholder="Leave notes for other users about this survey participant..."
								></textarea>
							</div>
						</div>
						<p className="footnote-text-edit-participant">
							<strong>Note:</strong> Typically a participant’s status should only be manually updated in
							case of illness, inability to complete survey, etc.{' '}
						</p>
						<p className="footnote-text-edit-participant">
							"Participant has been contacted and result validated” is typically used to denote a member
							of the response team has contacted a participant flagged with a possible priority concern.
							This will only unlock after a survey is complete.
						</p>
					</>
				)}

				<div className="btnContainer-edit-participant">
					<BootStrapButton
						onClick={() =>
							this.props.onSubmit(
								this.state.contacted,
								this.state.status,
								this.state.parentEmail,
								this.state.parentMobile,
								this.props.item.surveyParticipantId,
								this.state.groupName,
								this.state.participantEmail,
								this.state.notes
							)
						}
						type="submit"
						className="modalBtn"
						size="lg"
						variant="success"
					>
						Update User
					</BootStrapButton>
					<BootStrapButton
						onClick={this.props.closeModal}
						className="modalBtn"
						size="lg"
						variant="outline-primary"
					>
						Cancel
					</BootStrapButton>
				</div>
			</div>
		);
	}
}

export default EditParticipantForm;
