import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// import HOCS
import withLayout from '../../hoc/Layout/Layout';

// Import route components
import AdminHome from './AdminHome/AdminHome';
import Users from './Users/ListUsers/ListUsers';
import ListUserSurveys from './Users/ListUserSurveys/ListUserSurveys';
import Responders from './Responders/ListResponders/ListResponders';
import CreateSurvey from './SurveyCRUD/CreateSurvey/CreateSurvey';
import ListSurvey from './SurveyCRUD/ListSurvey/ListSurvey';
import SurveyDetails from './SurveyCRUD/SurveyDetails/SurveyDetails'
import isAuthenticated from '../../hoc/isAuthenticated/isAuthenticated';

import ListOrganizations from './Organizations/ListOrganizations/ListOrganizations';
import ParentReport from './Reports/Parent/Report';
import StudentReport from './Reports/Student/Report';
import OverallSurveyReport from './Reports/OverallSurvey/OverallSurveyReport';
import SearchReports from './Reports/Search/SearchReports'

import FAQ from './Support/Faq'
import Contact from './Support/Contact'
import Changelog from './Support/Changelog'

// Errors
import Error404 from '../Errors/Error404';

class Admin extends Component {
  render() {
    return (
      <Switch>
        {/*
          USAGE EXAMPLE FOR isAuthorized:
          <Route path='/studentsection' component={isAuthorized(isAuthenticated(withLayout(StudentSection)), 'USER')} />
          <Route path="/admin" component={isAuthenticated(withLayout(Admin, true), { userType: 'user' })} />
        */}
        <Route path="/admin" exact component={isAuthenticated(withLayout(AdminHome, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/users" component={isAuthenticated(withLayout(Users, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/responders" component={isAuthenticated(withLayout(Responders, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/organizations" component={isAuthenticated(withLayout(ListOrganizations, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/user/surveys" component={isAuthenticated(withLayout(ListUserSurveys, { showLogo: true, showContact: true, showUser: true }))} />
        
        {/* Survey Crud Routes */}
        <Route path="/admin/survey/list" component={isAuthenticated(withLayout(ListSurvey, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/survey/create" component={isAuthenticated(withLayout(CreateSurvey, { showLogo: true, showContact: true, showUser: true }))} />
        <Route path="/admin/survey/details" component={isAuthenticated(withLayout(SurveyDetails, { showLogo: true, showContact: true, showUser: true }))} />

        {/* Reports Routes */}
        <Route path="/admin/reports/parent" exact component={isAuthenticated(ParentReport)} />
        <Route path="/admin/reports/parentPdf" component={(ParentReport)} />
        <Route path="/admin/reports/student" component={isAuthenticated(StudentReport)} />
        <Route path="/admin/reports/survey" component={isAuthenticated(OverallSurveyReport)} />
        <Route path="/admin/reports/search" component={isAuthenticated(withLayout(SearchReports, { showLogo: true, showContact: true, showUser: true }))} />

        {/* Support Links */}
        <Route path="/admin/faq" component={withLayout(FAQ, { showLogo: true, showContact: true, showUser: true })} />
        <Route path="/admin/contact" component={withLayout(Contact, { showLogo: true, showContact: true, showUser: true })} />
        <Route path="/admin/changelog" component={withLayout(Changelog, { showLogo: true, showContact: true, showUser: true })} />
        <Route component={withLayout(Error404, { showLogo: true, showContact: true, showUser: true })} />
      </Switch>
    );
  }
}

export default Admin;
