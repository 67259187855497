import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../../../store/actions';
class DesignatedEmailRecipient extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      value: "",
      error: null
    };
  }

  handleKeyDown = e => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: ""
        });
      }
    }
  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
      error: null
    });
  };

  handleBlur = e => {
          e.preventDefault();
          var value = this.state.value.trim();
          if (value && this.isValid(value)) {
            this.setState({
          items: [...this.state.items, this.state.value],
          value: ""
        });
      }
  };

  handleDelete = item => {
    this.setState({
      items: this.state.items.filter(i => i !== item)
    });
  };

  handlePaste = e => {
    e.preventDefault();
    var paste = e.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));
      this.setState({
        items: [...this.state.items, ...toBeAdded]
      });
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    } 

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }  

	render() {
    this.props.setDesignatedEmailRecipient(this.state.items)
		return (
			<>
				<div className="file-input-create-survey-step-group">				
          <div className="email-recipient-step">
            <input
              type="text"
              style={{width: '400px'}}
              className={"participant-entry-field input " + (this.state.error && " has-error")}
              value={this.state.value}
              placeholder="Enter designated email recipients (Optional)"
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onPaste={this.handlePaste}
              onBlur={this.handleBlur}
            />

            {this.state.error && <p className="error">{this.state.error}</p>}
            <div className="tag">
            {this.state.items.map(item => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => this.handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}
            </div>
          </div>
        </div>
			</>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return {
    setDesignatedEmailRecipient: (value) => dispatch(actions.setDesignatedEmailRecipient(value))
  };
};

export default withRouter(
	connect(
    null,
		mapDispatchToProps
	)(DesignatedEmailRecipient)
);
