import React,{useState} from 'react';
const NumberInput = ({ answerSelected }) => {

  const [years, setYears] = useState(0);
  if (years) {
    answerSelected(true, years);
  }
	return (
		<div className="answerContainer mb-4">
      <div className="d-flex align-items-center mb-4">
        <div className="label-field-container">
          <input type="number" className="participant-entry-field" onChange={(e) => { setYears(e.target.value)}} value={years}/>
        </div>
			</div>
		</div>
	);
};

export default NumberInput;
