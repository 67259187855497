import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { getContent } from '../../../utilities/getContent/getContent';
import TMCard from '../../../components/TMCard/TMCard';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import { getLogo } from './../../../assets/helper';
import WellnessCenterAccessForm from './WellnessCenterAccessForm';
import Backdrop from '../../../components/Backdrop/Backdrop';
import Spinner from '../../../components/Spinner/Spinner';
import queryString from 'query-string';
// import { createStringDate } from '../../../utilities/dates';

class WellnessCenterAccess extends Component {
  state = {
    accessCodeVersion: 2, // v1 codes look like x_XxX0x || v2 codes look like XXXX-XXXX-XXXX-XXXX
  };
  componentWillMount() {
    if (this.props.location.search) {
      const parsed = queryString.parse(this.props.location.search);
      if (parsed && parsed.pId && parsed.firstName && parsed.lastName) {
        console.log(parsed);
        const inviteCode = parsed.pId;
        const firstName = parsed.firstName;
        const lastName = parsed.lastName;
        const submission = {
          inviteCode: inviteCode.toUpperCase().replace(/[^A-Z]/g, ''),
          firstName,
          lastName,
        };
        this.props.loginWellnessCenter(submission);
      }
    }
  }

  toggleAccessCodeVersion = () => {
    this.setState({ accessCodeVersion: this.state.accessCodeVersion === 2 ? 1 : 2 });
  };

  handleSubmit = values => {
    let submission = {};
    if (values.hasOwnProperty('email')) {
      submission = { ...values };
    } else {
      submission = {
        inviteCode: this.state.accessCodeVersion === 2 ? values.inviteCode.toUpperCase().replace(/[^A-Z]/g, '') : values.inviteCode,
        firstName: values.firstName,
        lastName: values.lastName,
        // birthDate: birthDate,
      };
    }

    this.props.loginWellnessCenter(submission);
  };

  render() {
    if (this.props.loggedIn) {
      return <Redirect to={{ pathname: `/wellness-center/home`, state: { participantId: this.props.participantId } }} />;
    }

    return (
      <>
        {this.props.loading || this.props.loggedIn || this.props.location.search ? (
          <Backdrop>
            <Spinner />
          </Backdrop>
        ) : (
          <div className="page-container-survey-access wellness-center">
            <TMCard
              className="tm-card-stepper"
              header={[
                <div className="text-center mb-4">
                  <img className="w-75 " src={getLogo()} alt="Logo" />
                </div>,
                <div className="mb-3">
                  <LanguagePicker />
                </div>,
                <p>{getContent('wellnesscenter_content-access-1')}</p>,
                <p>{getContent('wellnesscenter_content-access-2')}</p>,
              ]}
            >
              {this.props.authError ? (
                <div>
                  <p className="error-text">{this.props.authError}</p>
                </div>
              ) : null}
              <WellnessCenterAccessForm onSubmit={this.handleSubmit} accessCodeVersion={this.state.accessCodeVersion} toggleAccessCodeVersion={this.toggleAccessCodeVersion} />
            </TMCard>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = state => ({
  authError: state.wellnessCenterAuth.error,
  loggedIn: state.wellnessCenterAuth.loggedIn,
  participantId: state.wellnessCenterAuth.participantId,
  showErrorMsg: state.wellnessCenterAuth.showErrorMsg,
});

const mapDispatchToProps = dispatch => ({
  loginWellnessCenter: values => dispatch(actions.loginWellnessCenter(values)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(WellnessCenterAccess);
