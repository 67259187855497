import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/actions/';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';
import PieChart from 'react-minimal-pie-chart';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

class OverallResiliency extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && (payload.appliedTo === 'adult');
    const isUK = process.env.REACT_APP_STAGE === 'uk';
    const links = this.props.links;

    const getChartData = varType => {
      const rows = [];
      payload.reports.overallResiliency.forEach(row => {
        if (row[varType] !== 0) {
          rows.push({
            title: row.title,
            value: row[varType],
            color: row.color
          });
        }
      });

      return rows;
    };

    const getSelectedRelatedReportChartData = () =>
      this.props.selectedRelatedReport.chartData
        .filter(entry => entry.value > 0);

    const currentChartData = getChartData('value');

    return (
      <div className="page group-report" style={{ marginTop: '100px' }}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h1 className="mb-3 text-uppercase">
              <a href={isUK ? links.tmEducatorsLinkUK : links.tmEducatorsLink}>
                {getContent("report_overall_page03_heading1")}
              </a>
            </h1>

            <div className="row align-items-center">
              <div className="col-7">
                <h5 className=" mt-3 text-center">
                  {payload.semester} {payload.year}
                </h5>
                {isAdult ?
                  <div className="chart-container">
                    <PieChart
                      data={currentChartData}
                      label={row => {
                        return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                      }}
                      labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: '#fff',
                      }}
                      radius={42}
                      labelPosition={60}
                    />
                  </div> :
                  <div className="chart-container">
                    <a href={isUK ? links.tmEducatorsLinkUK : links.tmEducatorsLink}>
                      <PieChart
                        data={currentChartData}
                        label={row => {
                          return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                        }}
                        labelStyle={{
                          fontSize: '5px',
                          fontFamily: 'sans-serif',
                          fill: '#fff',
                        }}
                        radius={42}
                        labelPosition={60}
                      />
                    </a>
                  </div>}
              </div>
              {payload.relatedReports && payload.relatedReports.length > 0 && (
                <div className="col-5 pt-5 ">
                  <div>
                    <p>Select a report to compare with:</p>
                    <DropdownList
                      data={payload.relatedReports}
                      valueField='id'
                      textField='name'
                      busy={this.props.selectedRelatedReportIsLoading}
                      onChange={value => { this.props.getSelectedRelatedReport(value.id) }}
                    />
                  </div>
                  {this.props.selectedRelatedReport && (<div>
                    <h5 className="mt-3 text-center">
                      {isUK
                        ? this.props.selectedRelatedReport.surveyName
                          .replace('Uppergrades', 'Secondary')
                          .replace('Elementary', 'Primary')
                        : this.props.selectedRelatedReport.surveyName}
                      <br />
                      <em>
                        <small>Historical data</small>
                      </em>
                    </h5>
                    <div className="chart-container">
                      <PieChart
                        data={getSelectedRelatedReportChartData()}
                        label={row => {
                          return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                        }}
                        labelStyle={{
                          fontSize: '5px',
                          fontFamily: 'sans-serif',
                          fill: '#FFF'
                        }}
                        radius={42}
                        labelPosition={60}
                      />
                    </div>
                  </div>)}
                </div>
              )}
            </div>
            {/* end .row for chart */}

            {/* Legend */}
            <div className="legend text-center mb-5">
              {payload.reports.overallResiliency.map((row, index) => {
                return (
                  <div
                    key={index}
                    className="d-inline-block p-4 font-weight-bold"
                    style={{ color: row.color }}
                  >
                    <h4>&bull; {row.title}</h4>
                  </div>
                );
              })}
            </div>

            <div className="box-comment">
              <h3>What is this chart telling me? </h3>
              <p>
                {isAdult ? getContent("report_overall_page03_risk_chart_1_adult") : getContent("report_overall_page03_risk_chart_1_student")} {isAdult ? '' : ' ' + payload.school}.
              </p>
              <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
              <p>{isAdult ? getContent("report_overall_page03_risk_chart_2_adult") : getContent("report_overall_page03_risk_chart_2_student")}</p>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRelatedReport: state.overallSurveyReport.selectedRelatedReport,
    selectedRelatedReportIsLoading: state.overallSurveyReport.selectedRelatedReportIsLoading,
    error: state.overallSurveyReport.selectedRelatedReportError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSelectedRelatedReport: (surveyId) => dispatch(actions.getOverallSurveyRelatedReport(surveyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OverallResiliency));

