import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import classnames from 'classnames';
import * as actions from '../../store/actions';

const Breadcrumbs = props => {
	console.log('breadcrumbs: ', props.breadcrumbs);
  console.log('breadcrumbs props: ', props);
  console.log('BC_LEVEL:', props.bc_level);
  console.log('bc_orgName:', props.bc_orgName);
  console.log('bc_orgId:', props.bc_orgId);
  console.log('bc_higherOrgName:', props.bc_higherOrgName);
  console.log('bc_higherOrgId:', props.bc_higherOrgId);

  const isUK = process.env.REACT_APP_STAGE === 'uk';

	const NavItem = ({ breadcrumb }) => {
		return <NavLink to={breadcrumb.url}>{breadcrumb.label}</NavLink>;
  };
  const resetRoleAndOrg = () => {
		props.resetOrganization();
		props.resetRole();
	  props.history.push('/admin');
	};

	const ActiveItem = ({ breadcrumb }) => {
		return <span>{breadcrumb.label}</span>;
	};

	let sectionTitle = isUK ? 'MyMynd Admin' : 'Terrace Admin';

	if (props.role === 'district_champion') {
		sectionTitle = props.organization && props.organization.name ? props.organization.name : '';
	}

	if (props.role === 'school_champion') {
		sectionTitle = `${
			props.organization && props.organization.parentOrg ? props.organization.parentOrg.name : ''
		} : ${props.organization && props.organization.name ? props.organization.name : ''}`;
	}

	if (props.role === 'responder') {
		sectionTitle = 'Responder';
	}
  console.log("Props MANK:", props);
	return (
		<div >
			<div className="breadcrumbs col">
				<div className="auth">{sectionTitle}</div>
				<ol className="breadcrumb">
					{props.breadcrumbs.map((breadcrumb, index) => (
						<li
							key={breadcrumb.key}
							className={classnames('breadcrumb-item', {
								active: props.breadcrumbs.length - 1 === index
							})}
						>
							{props.breadcrumbs.length - 1 === index && !props.linkAllCrumbs ? (
								<ActiveItem breadcrumb={breadcrumb} onClick={resetRoleAndOrg} />
							) : (
								<NavItem breadcrumb={breadcrumb} />
							)}
						</li>
					))}
				</ol>
			</div>
      {props.role !== 'tm_admin' && props.realRole && props.realOrganization ? (
					<div className="row ml-2">
						<button className="button-text" onClick={resetRoleAndOrg}>
							Return to{' '}
							{props.realRole === 'tm_admin'
								? `${isUK ? 'Admin' : 'Terrace Admin'}`
								: `${isUK ? 'Group' : 'Organization Champion'}`}{' '}
							Home
						</button>
					</div>
				) : null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		role: state.auth.role,
		realRole: state.auth.realRole,
    bc_orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgId:state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    bc_higherOrgName:state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.name,
    bc_higherOrgId:state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.orgId,
    //bc_level: (state.auth.role === "tm_admin") ? "ADMIN": (state.auth.role === "district_champion") ? "ORGANIZATION": (state.auth.role === "school_champion") ? "GROUP": "NA",
    bc_level: (state.auth.role === "tm_admin") ? "ADMIN": (state.auth.role === "school_champion" || (state.auth.role === "district_champion" && state.organizations.organization.parentOrg)) ? "GROUP": (state.auth.role === "district_champion") ? "ORGANIZATION": "NA",
    realOrganization: state.organizations.realOrganization,
		organization: state.organizations.organization
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetRole: () => dispatch(actions.resetRole()),
		resetOrganization: () => dispatch(actions.resetOrganization()),
	};
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Breadcrumbs));
