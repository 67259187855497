import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['organizations']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const configureStore = (preloadedState) => {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod') {
    return createStore(
      persistedReducer,
      preloadedState,
      compose(
        applyMiddleware(thunk),
      )
    );
  }

  return createStore(
    persistedReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk),
      devToolsEnhancer(),
    )
  );
};

export const store = configureStore();

export const persistor = persistStore(store);


