import Amplify, { Auth } from 'aws-amplify';
import * as actionTypes from '../actionTypes';
import getAuthenticatedUser from '../../../utilities/getAuthenticatedUser';
import { getOrganization, getOrganizationSuccess } from '../organizations/organizations';
import { getUser, applyCleverUser } from '../users/users';

Amplify.configure({
  Auth: {
    //identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'us-east-1:ca41d833-0bc4-4b5a-bae1-b1c2ff0309c9',
    region: process.env.REACT_APP_REGION || 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_Ios05vo7O',
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '7ac8pk0etqbaqfp0qjth9jmlup',
  },
});

export const checkAuthStateStart = () => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_START,
  };
};

export const checkAuthStateSuccess = (isAuthenticated, idToken, accessToken, refreshToken, isCleverLogin, orgs = null) => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_SUCCESS,
    isAuthenticated,
    idToken,
    accessToken,
    refreshToken,
    isCleverLogin,
    orgs,
  };
};

export const checkAuthState = () => {
  return async (dispatch, getState) => {
    // HACK: To stop checkAuthState when viewing the site as another role when you are admin
    if (getState().auth.realRole) {
      return;
    }

    dispatch(checkAuthStateStart());
    let user, role, idToken;
    const cleverUserRaw = localStorage.getItem('cleverUserData');
    const isCleverUser = cleverUserRaw ? true : false;
    if (isCleverUser) {
      user = JSON.parse(cleverUserRaw);
      console.log('CHECK AUTH STATE FOR CLEVER USER: ', user);
      const accessToken = user.access_token;
      idToken = accessToken;
      role = user['custom:role'];
      const orgs = user.otherOrgs;
      dispatch(checkAuthStateSuccess(user, idToken, accessToken, null, true, orgs));
      applyCleverUser(user);
      console.log('ROLE IN CHECK STATE: ', role);
    } else {
      user = await getAuthenticatedUser();
      if (!user) {
        return;
      }
      console.log('CHECK AUTH STATE USER: ', user);
      idToken = user.signInUserSession.idToken.jwtToken;
      const accessToken = user.signInUserSession.accessToken.jwtToken;
      const refreshToken = user.signInUserSession.refreshToken.jwtToken;
      role = user.attributes['custom:role'] || getState().user.auth.role;
      console.log('ROLE IN CHECK STATE: ', role);
      dispatch(checkAuthStateSuccess(user, idToken, accessToken, refreshToken, false));
      dispatch(getUser(user.username));
    }
    if (isCleverUser || (user.attributes && user.attributes['custom:orgId'])) {
      let orgId;
      if (isCleverUser) {
        if (!getState().organizations.organization) {
          // prevent org changing when you have 2+ orgs in your account
          orgId = Array.isArray(user['custom:orgId']) ? user['custom:orgId'][0] : user['custom:orgId'];
        }
      } else {
        orgId = user.attributes['custom:orgId'];
      }
      if (!getState().organizations.organization && role !== 'tm_admin') {
        dispatch(getOrganization(orgId, idToken));
      } else {
        // HACK: To set isLoaded to true so layout HOC can render wrapped component
        const org = getState().organizations.realOrganization && role !== 'tm_admin' ? getState().organizations.realOrganization : getState().organizations.organization;

        dispatch(getOrganizationSuccess(org));
      }
    } else {
      // HACK: To set isLoaded to true so layout HOC can render wrapped component
      let org = null;
      console.log('role: ', role);
      if (role !== 'tm_admin') {
        org = getState().organizations.realOrganization ? getState().organizations.realOrganization : getState().organizations.organization;
      } else if (role === 'tm_admin' && getState().organizations.organization) {
        org = getState().organizations.organization;
      }

      console.log('getState real org: ', getState().organizations.realOrganization);
      console.log('orgs in auth: ', org);
      dispatch(getOrganizationSuccess(org));
    }
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const loginSuccess = (user, idToken, accessToken, refreshToken, isCleverLogin) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user,
    idToken,
    accessToken,
    refreshToken,
    isCleverLogin,
  };
};

export const loginFail = err => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: err,
  };
};

export const setTempUser = user => {
  return {
    type: actionTypes.SET_TEMP_USER,
    tempUser: user,
  };
};

export const loginWithClever = user => {
  return (dispatch, getState) => {
    dispatch(loginStart());
    console.log('user is: ', user);
    const idToken = user.access_token;
    dispatch(loginSuccess(user, idToken, idToken, null, true));
    dispatch(applyCleverUser(user));
    localStorage.setItem('cleverUserData', JSON.stringify(user));
    const orgId = Array.isArray(user['custom:orgId']) ? user['custom:orgId'][0] : user['custom:orgId'];
    console.log('ORG ID: ', user.orgId);
    if (orgId) {
      dispatch(getOrganization(orgId, idToken));
    } else {
      throw new Error('org id is required');
    }
  };
};

export const login = (username, password, redirectToChangePassword) => {
  return (dispatch, getState) => {
    dispatch(loginStart());

    Auth.signIn({
      username,
      password,
    })
      .then(user => {
        console.log('user is: :', user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(setTempUser(user));
          redirectToChangePassword();
          return;
        }
        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;
        dispatch(loginSuccess(user, idToken, accessToken, refreshToken, false));
        dispatch(getUser(user.username));
        const orgId = user.attributes['custom:orgId'];
        console.log('ORG ID: ', orgId);
        if (orgId) {
          dispatch(getOrganization(orgId, idToken));
        } else {
          dispatch(getOrganizationSuccess(null));
        }
      })
      .catch(err => {
        console.log('Error Logging In: ', err);
        dispatch(loginFail(err));
      });
  };
};

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const logoutSuccess = response => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    signOutResponse: response,
  };
};

export const logoutFail = err => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: err,
  };
};

export const logout = cb => {
  return dispatch => {
    dispatch(logoutStart());
    try {
      localStorage.clear();
      Auth.signOut()
        .then(res => {
          console.log('signOutResponse: ', res);
          dispatch(logoutSuccess(res));
          dispatch(resetApp());
          if (cb) {
            cb();
          }
        })
        .catch(err => {
          console.log('Error signing out: ', err);
          dispatch(logoutFail(err));
        });
    } catch (err) {
      dispatch(logoutFail(err));
    }
  };
};

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFail = err => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL,
    error: err,
  };
};

export const changePassword = (newPassword, cb) => {
  return (dispatch, getState) => {
    dispatch(changePasswordStart());
    Auth.completeNewPassword(getState().auth.tempUser, newPassword)
      .then(user => {
        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;
        dispatch(loginSuccess(user, idToken, accessToken, refreshToken, false));
        if (cb) {
          cb();
        }
      })
      .catch(err => console.log(err));
  };
};

export const changeRole = role => {
  return {
    type: actionTypes.CHANGE_ROLE,
    role,
  };
};

export const resetRole = () => {
  return {
    type: actionTypes.RESET_ROLE,
  };
};

export const resetApp = () => {
  return {
    type: actionTypes.RESET_APP,
  };
};
