import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const Error404 = (props) => {

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
    ];

    return breadcrumbs;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="sub-header">
          <Breadcrumbs breadcrumbs={getBreadcrumbs()} linkAllCrumbs={true} />
        </div>
      </div>
      <div className="row">
        <div>
          <h1 className="mb-4">404 - Page not found</h1>
          <p>The page you are looking for doesn’t exist, or an error occurred.</p>
        </div>
      </div>
    </div>
  );
};

export default Error404;
