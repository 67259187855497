import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const DEFAULT_REPORT_TYPE = 'schoolReport';

const initialState = {
  report: null,
  isLoading: false,
  error: null,
  reportSearchCountryRegion: '',
  reportSearchStateProvince: '',
  reportSearchDistrict: { name: '', orgId: '' },
  reportSearchSchool: { name: '', id: '' },
  reportSearchYear: '',
  reportSearchName: '',
  reportSearchReportType: DEFAULT_REPORT_TYPE,
  radioValue: DEFAULT_REPORT_TYPE,
  searchCountryRegionOptions: [],
  searchStateProvinceOptions: [],
  searchDistrictOptions: [],
  searchSchoolOptions: [],
  searchReportsResults: [],
  lastEvaluatedKey: {},
  isLastKey: false,
  currentExclusiveStartKey: {},
  showReportsSearch: false,
  pages: [],
  pageNumber: '1',
  currentReportType: DEFAULT_REPORT_TYPE,
  isShowingNotConcerningResponses: false
};

const getReportStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null },
  });
};

const getReportSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    report: { $set: action.report },
  });
};

const getReportFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    err: { $set: action.err },
  });
};

const updateSearchReportsObj = (state, action) => {
  var valueToBeUpdated = action.payload.value;

  if (action.payload.name === 'reportSearchDistrict') {
    valueToBeUpdated = state.rawDistrictsData.find(district => {
      return action.payload.value === district.name;
    });
    console.log(valueToBeUpdated);
  }

  if (action.payload.name === 'reportSearchSchool') {
    valueToBeUpdated = state.rawSchoolsData.find(school => {
      return action.payload.value === school.name;
    });
  }

  if (action.payload.name === 'reportSearchReportType') {
    valueToBeUpdated = action.payload.value;
  }

  return update(state, {
    [action.payload.name]: { $set: valueToBeUpdated },
  });
};

const searchReportsStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
  });
};

const searchReportsSuccess = (state, action) => {
  const pages = [...state.pages];
  console.log('PAGES: ', pages, 'action: ', action);
  if (!pages[action.pageNumber] && !action.isLastKey && (action.lastEvaluatedKey || action.currentExclusiveStartKey)) {
    pages.push({ lastEvaluatedKey: action.lastEvaluatedKey, currentExclusiveStartKey: action.currentExclusiveStartKey });
  }
  const pageNumber = action.pageNumber || 0;

  return update(state, {
    isLoading: { $set: false },
    currentReportType: { $set: action.reportType },
    searchReportsResults: { $set: action.reports },
    lastEvaluatedKey: { $set: action.lastEvaluatedKey },
    currentExclusiveStartKey: { $set: action.currentExclusiveStartKey },
    isLastKey: { $set: action.isLastKey },
    pages: { $set: pages },
    pageNumber: { $set: pageNumber },
  });
};

const searchReportsFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    err: { $set: action.err },
  });
};

const toggleShowReportSearch = (state, action) => {
  return update(state, {
    showReportsSearch: { $set: !state.showReportsSearch },
    reportSearchCountryRegion: { $set: initialState.reportSearchCountryRegion },
    reportSearchStateProvince: { $set: initialState.reportSearchStateProvince },
    reportSearchDistrict: { $set: initialState.reportSearchDistrict },
    reportSearchSchool: { $set: initialState.reportSearchSchool },
    reportSearchYear: { $set: initialState.reportSearchYear },
    reportSearchName: { $set: initialState.reportSearchName },
    reportSearchReportType: { $set: initialState.reportSearchReportType },
  });
};

const setSearchValuesStart = (state, action) => {
  return update(state, {
    // isLoading: { $set: true },
    error: { $set: null },
  });
};

const setSearchValuesFail = (state, action) => {
  return update(state, {
    // isLoading: { $set: false },
    err: { $set: action.err },
  });
};

const setSearchValues = (state, action) => {
  let newState = {};

  if (action.values.districts) {
    var districtsMap = action.values.districts.map(district => {
      return district.name;
    });
    newState = {
      ...newState,
      searchDistrictOptions: { $set: districtsMap },
      rawDistrictsData: { $set: action.values.districts },
    };
  }

  if (action.values.schools) {
    var schoolsMap = action.values.schools.map(school => {
      return school.name;
    });
    newState = {
      ...newState,
      searchSchoolOptions: { $set: schoolsMap },
      rawSchoolsData: { $set: action.values.schools },
    };
  }

  if (action.values.countries) {
    newState = {
      ...newState,
      searchCountryRegionOptions: { $set: action.values.countries },
    };
  }

  if (action.values.states) {
    newState = {
      ...newState,
      searchStateProvinceOptions: { $set: action.values.states },
    };
  }

  return update(state, newState);
};

const clearReportStateOptions = (state, action) => {
  return update(state, {
    searchStateProvinceOptions: { $set: [] },
    reportSearchCountryRegion: { $set: '' },
  });
};

const clearReportDistrictOptions = (state, action) => {
  return update(state, {
    searchDistrictOptions: { $set: [] },
    reportSearchDistrict: { $set: { name: '', orgId: '' } },
  });
};

const clearReportSchoolOptions = (state, action) => {
  return update(state, {
    searchSchoolOptions: { $set: [] },
    reportSearchSchool: { $set: { name: '', id: '' } },
  });
};

const clearSearchReportsPaginationValues = (state, action) => {
  return update(state, {
    currentExclusiveStartKey: { $set: null },
    lastEvaluatedKey: { $set: null },
    isLastKey: { $set: false },
    pages: { $set: [] },
    pageNumber: { $set: 0 },
    showReportsSearch: { $set: false },
    reportSearchCountryRegion: { $set: initialState.reportSearchCountryRegion },
    reportSearchStateProvince: { $set: initialState.reportSearchStateProvince },
    reportSearchDistrict: { $set: initialState.reportSearchDistrict },
    reportSearchSchool: { $set: initialState.reportSearchSchool },
    reportSearchYear: { $set: initialState.reportSearchYear },
    reportSearchName: { $set: initialState.reportSearchName },
    reportSearchReportType: { $set: initialState.reportSearchReportType },
  });
};

const toggleShowNotConcerningResponses = (state, action) => {
  return update(state, {
    isShowingNotConcerningResponses: { $set: !state.isShowingNotConcerningResponses }
  });
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_START:
      return getReportStart(state, action);
    case actionTypes.GET_REPORT_SUCCESS:
      return getReportSuccess(state, action);
    case actionTypes.GET_REPORT_FAIL:
      return getReportFail(state, action);
    case actionTypes.UPDATE_SEARCH_REPORT:
      return updateSearchReportsObj(state, action);
    case actionTypes.SEARCH_REPORT_START:
      return searchReportsStart(state, action);
    case actionTypes.SEARCH_REPORT_SUCCESS:
      return searchReportsSuccess(state, action);
    case actionTypes.SEARCH_REPORT_FAIL:
      return searchReportsFail(state, action);
    case actionTypes.TOGGLE_SHOW_REPORT_SEARCH:
      return toggleShowReportSearch(state, action);
    case actionTypes.SET_SEARCH_VALUES_REPORTS:
      return setSearchValues(state, action);
    case actionTypes.SET_SEARCH_VALUES_REPORTS_START:
      return setSearchValuesStart(state, action);
    case actionTypes.SET_SEARCH_VALUES_REPORTS_FAIL:
      return setSearchValuesFail(state, action);
    case actionTypes.CLEAR_REPORT_STATE_OPTION:
      return clearReportStateOptions(state, action);
    case actionTypes.CLEAR_REPORT_DISTRICT_OPTION:
      return clearReportDistrictOptions(state, action);
    case actionTypes.CLEAR_REPORT_SCHOOL_OPTION:
      return clearReportSchoolOptions(state, action);
    case actionTypes.CLEAR_SEARCH_REPORTS_PAGINATION_VALUES:
      return clearSearchReportsPaginationValues(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    case actionTypes.TOGGLE_SHOW_NOT_CONCERNING_RESPONSES:
      return toggleShowNotConcerningResponses(state, action);
    default:
      return state;
  }
};

export default reportReducer;
