import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Redirect, Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../../store/actions';
import { getLogo } from './../../../../assets/helper';
import cleverLogo from './../../../../assets/imgs/LogInWithClever.png';
import LoginForm from './LoginForm';

class Login extends Component {
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    console.log('parsed', parsed);
    if (!parsed || !parsed.pw || !parsed.un) return null;
    const password = decodeURIComponent(parsed.pw);
    const username = decodeURIComponent(parsed.un);
    if (!password || !username) return null;
    this.props.login(username, password, this.redirectToChangePassword);
  }

  loginHandler = values => {
    const { login } = this.props;
    const { username, password } = values;
    login(username, password, this.redirectToChangePassword);
  };

  redirectToChangePassword = () => {
    this.props.history.push('/change-password');
  };

  redirectToResetPassword = () => {
    this.props.history.push('/reset-password');
  };

  openForgotPasswordModal = () => {
    this.props.openModal({
      modalType: 'FORGOT_PASSWORD_MODAL',
      show: true,
      closeModal: this.props.closeModal,
      redirectToResetPassword: this.redirectToResetPassword
    });
  };

  getRedirectURI = () => {
    const env = process.env;
    const redirect = process.env.REACT_APP_CLEVER_REDIRECT_URI;
    const clientId = process.env.REACT_APP_CLEVER_CLIENT_ID;
    return redirect;
  }

  render() {
    const { error } = this.props;
    const clientId = process.env.REACT_APP_CLEVER_CLIENT_ID;
    if (this.props.redirectFromLogIn) {
      return <Redirect to="/admin" />;
    }

    return (
      <div className="pageContainer">
        <img className="logo-login" alt="TM Logo" src={getLogo()} />
        <p className="error">{error && Object.keys(error).includes('message') ? error.message : null}</p>
        <LoginForm onSubmit={this.loginHandler} />
        <button className="forgotPassword" onClick={() => this.openForgotPasswordModal()}>
          Forgot Password?
				</button>
        <a href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${this.getRedirectURI()}&client_id=${clientId}`}>
          <img className="clever-logo-login" alt="Sign in with Clever" src={cleverLogo} />
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    redirectFromLogIn: state.auth.redirectFromLogIn,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password, redirect) => dispatch(actions.login(username, password, redirect)),
    changePassword: (newPassword, cb) => dispatch(actions.changePassword(newPassword, cb)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
