import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';

import * as actions from '../../../../../store/actions';

class SurveyStatusModal extends Component {
  render() {
    return (
      <ModalWrapper className='survey-status-modal-wrapper' show={this.props.show} onExited={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Survey Status</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent survey-status-modal'>
          The survey participants are still being added to the survey.
        </Modal.Body>
      </ModalWrapper>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
  };
};


export default connect(null,mapDispatchToProps)(SurveyStatusModal);
