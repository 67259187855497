const isUK = process.env.REACT_APP_STAGE === 'uk';

export default {
  trauma:    {
    label: 'indicator_trauma',
    intro: 'report_suggestions-adult-trauma-intro',
    suicidalContext: true,
    tips: [
      // 'report_suggestions-adult-trauma-1',
      isUK ? 'report_suggestions-adult-uk-trauma-2' : 'report_suggestions-adult-trauma-2',
      // 'report_suggestions-adult-get-help-1',
      // 'report_suggestions-adult-get-help-2',
    ],
  },
  'drug / alcohol': {
    label: 'indicator_drug-alcohol',
    intro: 'report_suggestions-adult-drug-alcohol-intro',
    suicidalContext: true,
    tips: isUK 
    ? [
      'report_suggestions-adult-uk-drug-alcohol-1',
      'report_suggestions-adult-uk-drug-alcohol-2',
      'report_suggestions-adult-uk-drug-alcohol-3',
    ]
    :[
      'report_suggestions-adult-drug-alcohol-1',
      'report_suggestions-adult-drug-alcohol-2',
      'report_suggestions-adult-drug-alcohol-3',
      'report_suggestions-adult-drug-alcohol-4',
    ]
  },
  'personal standards': {
    label: 'indicator_personal-standards',
    intro: 'report_suggestions-adult-personal-standards-intro',
    suicidalContext: false,
    tips: [
      'report_suggestions-adult-personal-standards-1',
      'report_suggestions-adult-personal-standards-2',
      'report_suggestions-adult-personal-standards-3',
      'report_suggestions-adult-personal-standards-4',
    ],
  },
  leadership: {
    label: 'indicator_leadership',
    intro: 'report_suggestions-adult-leadership-intro',
    suicidalContext: false,
    tips: [
      'report_suggestions-adult-leadership-1',
      'report_suggestions-adult-leadership-2',
      'report_suggestions-adult-leadership-3',

    ],
  },
  depression: {
    label: 'indicator_depression',
    intro: 'report_suggestions-adult-depression-intro',
    suicidalContext: true,
    tips: isUK 
    ? [
      'report_suggestions-adult-depression-1',
      'report_suggestions-adult-depression-2',
      'report_suggestions-adult-uk-depression-3',
      'report_suggestions-adult-uk-get-help-1',
      'report_suggestions-adult-uk-get-help-2',
      'report_suggestions-adult-uk-get-help-3',
    ]
    : [
      'report_suggestions-adult-depression-1',
      'report_suggestions-adult-depression-2',
      'report_suggestions-adult-depression-3',
      'report_suggestions-adult-get-help-1',
      'report_suggestions-adult-get-help-2',
    ],
  },
  hope: {
    label: 'indicator_hope',
    intro: 'report_suggestions-adult-hope-intro',
    suicidalContext: false,
    tips: [
      'report_suggestions-adult-hope-1',
    ],
  },
  anxiety: {
    label: 'indicator_anxiety',
    intro: 'report_suggestions-adult-anxiety-intro',
    suicidalContext: false,
    tips: isUK 
    ? [
      'report_suggestions-adult-anxiety-1',
      'report_suggestions-adult-anxiety-2',
      'report_suggestions-adult-uk-anxiety-3',
      'report_suggestions-adult-uk-get-help-5',
      'report_suggestions-adult-uk-get-help-2',
      'report_suggestions-adult-uk-get-help-3',
    ]
    : [
      'report_suggestions-adult-anxiety-1',
      'report_suggestions-adult-anxiety-2',
      'report_suggestions-adult-anxiety-3',
    ],
  },
  'adult global satisfaction': {
    label: 'indicator_adult-global-satisfaction',
    intro: 'report_suggestions-adult-global-satisfaction-intro',
    suicidalContext: false,
    tips: [
      'report_suggestions-adult-global-satisfaction-1',
      'report_suggestions-adult-global-satisfaction-2',
      'report_suggestions-adult-global-satisfaction-3',
    ],
  },
  'intimate partner violence':   {
    label: 'indicator_intimate-partner-violence',
    intro: 'report_suggestions-adult-intimate-partner-violence-intro',
    suicidalContext: true,
    tips: [
      'report_suggestions-adult-intimate-partner-violence-1',
      isUK ? 'report_suggestions-adult-uk-intimate-partner-violence-2' : 'report_suggestions-adult-intimate-partner-violence-2',
    ],
  },
   ostracism: {
     label: 'indicator_ostracism',
     intro: 'report_suggestions-adult-ostracism-intro',
     suicidalContext: false,
     tips: isUK 
      ? [
       'report_suggestions-adult-ostracism-1',
       'report_suggestions-adult-uk-ostracism-2',
       'report_suggestions-adult-uk-get-help-1',
       'report_suggestions-adult-uk-get-help-2',
       'report_suggestions-adult-uk-get-help-3',
     ]
     : [
      'report_suggestions-adult-ostracism-1',
      'report_suggestions-adult-ostracism-2',
      // 'report_suggestions-adult-get-help-1',
      // 'report_suggestions-adult-get-help-3',
    ],
   },
   'adult self criticism': {
    label: 'indicator_adult-self-criticism',
    intro: 'report_suggestions-adult-self-criticism-intro',
    tips: [
     'report_suggestions-adult-self-criticism-1',
     'report_suggestions-adult-self-criticism-2',
     'report_suggestions-adult-self-criticism-3',
    ]
  },
};
