import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import ParticipantEntryFields from './ParticipantEntryFields';

class ParticipantEntryForm extends Component {
  render() {
    console.log('this.props for form: ', this.props);
    return (
      <form onSubmit={this.props.handleSubmit}>
        {this.props.error && <span className="error">{this.props.error}</span>}
        {this.props.manualRoster.map((item, index) => {
          return <ParticipantEntryFields key={index} index={index} appliedTo={this.props.appliedTo}/>;
        })}
      </form>
    );
  }
}

export default reduxForm({
  form: 'participant-entry-form',
})(ParticipantEntryForm);
