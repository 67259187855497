import React, { Component } from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';
import MOCK_CATEGORIES from './mock-resilience-categories';
import parse from 'html-react-parser';
import GroupHeader from './Header';

class DistrictIntroPage extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && (payload.appliedTo === 'adult');
    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5">
            <div className="row">
              <h1 className="text-uppercase">Introduction</h1>
              <p>
                {isAdult ? getContent("report_overall_page02_introduction_1_adult") : getContent("report_overall_page02_introduction_1_student")}
                {payload.totalStudents}
                {isAdult ? getContent("report_overall_page02_introduction_2_adult") : getContent("report_overall_page02_introduction_2_student")}
              </p>
              <p>
                {isAdult ? getContent("report_overall_page02_introduction_3_adult") : getContent("report_overall_page02_introduction_3_student")}
              </p>
              <h2 className="text-uppercase">Why the need for this assessment?</h2>
              <p>
                {isAdult ? getContent("report_overall_page02_resiliency_1_adult") : getContent("report_overall_page02_resiliency_1_student")}
                {'  '}
                {isAdult ? getContent("report_overall_page02_resiliency_2_adult") : getContent("report_overall_page02_resiliency_2_student")}
              </p>
              <h2 className="text-uppercase">Resiliency along a Continuum</h2>
              {isAdult ? '' : <h3 style={{ fontSize: '16px', margin: '0.7rem 0 0.5rem' }}>Every step to the right of the continuum is associated with more favorable academic and interpersonal outcomes.</h3>}
              <p>
                {isAdult ? getContent("report_overall_page02_resiliency_3_adult") : getContent("report_overall_page02_resiliency_3_student")}
              </p>
            </div>
            <div className="d-flex flex-row mt-5">
              <div className="block p-3 m-1 block-red">
                <h3>{getContent("report_heading-at-risk")}</h3>
                <p>{getContent("report_content-at-risk-description-1")}</p>
                <p>{getContent("report_content-at-risk-description-2")}</p>
              </div>
              <div className="block p-3 m-1 block-yellow">
                <h3>{getContent("report_heading-moderate-concern")}</h3>
                <p>{getContent("report_content-moderate-concern-description-1")}</p>
                <p>{isAdult ? getContent("report_content-moderate-concern-description-2-adult") : getContent("report_content-moderate-concern-description-2")}</p>
              </div>
              <div className="block p-3 m-1 block-light-green">
                <h3>{getContent("report_heading-satisfactory")}</h3>
                <p>{getContent("report_content-satisfactory-description-1")}</p>
                <p>{getContent("report_content-satisfactory-description-2")}</p>
              </div>
              <div className="block p-3 m-1 block-green">
                <h3>{getContent("report_heading-optimal")}</h3>
                <p>{getContent("report_content-optimal-description-1")}</p>
                <p>{isAdult ? getContent("report_content-optimal-description-2-adult") : getContent("report_content-optimal-description-2")}</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default DistrictIntroPage;
