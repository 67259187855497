import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import { ReactComponent as EmailSuccessIcon } from '../../../../assets/imgs/icon_notification_email_success.svg';
import { ReactComponent as InviteSuccessIcon } from '../../../../assets/imgs/icon_invitation_email_success.svg';
import { ReactComponent as EmailFailIcon } from '../../../../assets/imgs/icon_notification_email_error.svg';
import { ReactComponent as TextSuccessIcon } from '../../../../assets/imgs/icon_notification_sms_success.svg';
import { ReactComponent as TextFailIcon } from '../../../../assets/imgs/icon_notification_sms_error.svg';
import { ReactComponent as EmailUnsent } from '../../../../assets/imgs/icon_notification_email_standard.svg';
import { ReactComponent as TextUnsent } from '../../../../assets/imgs/icon_notification_sms_standard.svg';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Button from 'react-bootstrap/Button';
import SearchUserSurveysForm from './SearchUserSurveysForm/SearchUserSurveysForm';
import * as actions from '../../../../store/actions';
import { adjustDate, dateFormat } from '../../../../utilities/dates';
import { getContent } from '../../../../utilities/getContent/getContent';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DescriptionIcon from '@material-ui/icons/Description';

class ListUserSurveys extends Component {
  componentDidMount() {
    if (this.props.bc_level && this.props.bc_level === 'ADMIN') {
      this.props.history.push({
        pathname: '/admin',
      });
    }
  }
  componentWillUnmount() {
    this.props.clearUserSurveysState();
  }

  state = {
    loading: this.props.userSurveys ? true : false,
  };

  searchSubmissionHandler = () => {
    const values = {
      firstName: this.props.userSurveysSearchFirstName,
      lastName: this.props.userSurveysSearchLastName,
    };
    console.log('search firing');
    this.props.listUserSurveys(values);
  };
  redirectToSurveyDetails = id => {
    this.props.history.push({
      pathname: '/admin/survey/details',
      state: {
        surveyId: id,
      },
    });
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  // Participant Delete handler
  deleteItemHandler = item => {
    console.log('Delete Item handler - item ID: ', item);
    this.openDeleteParticipantConfirmationModal(item.surveyParticipantId, item.surveyId);
  };

  openDeleteParticipantConfirmationModal = (surveyParticipantId, surveyId) => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button type="button" className="small-button green" size="lg" onClick={() => this.props.deleteParticipantFromSurvey(surveyParticipantId, surveyId)}>
            Delete Participant
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };

  editParticipantHandler = item => {
    console.log('Edit Participant Handler: ', item);
    this.props.openModal({
      modalType: 'EDIT_PARTICIPANT_MODAL',
      show: true,
      item: item,
      isEmployerAdmin: this.isEmployerAdmin(),
      surveyId: item.surveyId,
      surveyStatus: item.surveyStatus,
      surveyName: this.isUK() ? item.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName,
      closeModal: this.props.closeModal,
    });
  };

  notifyParentReportHandler = item => {
    console.log('Notify Parent Report Handler: ', item);
    this.props.openModal({
      modalType: 'NOTIFY_PARENT_MODAL',
      show: true,
      item: item,
      surveyId: item.surveyId,
      surveyStatus: item.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };

  notifyHandler = item => {
    console.log('Notify  Handler: ', item);
    this.props.openModal({
      modalType: 'NOTIFY_MODAL',
      show: true,
      item: item,
      surveyId: item.surveyId,
      surveyStatus: item.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };

  notificationLogHandler = async item => {
    await this.props.getNotificationLog(item.surveyParticipantId, item.surveyId);
    this.props.openModal({
      modalType: 'NOTIFY_HISTORY_MODAL',
      show: true,
      item: item,
      surveyId: item.surveyId,
      surveyStatus: item.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };
  getReportIcon = (num, item) => {
    return (
      <Link
        className="button-text report-link"
        to={{
          pathname: `/admin/reports/student?pId=${item.surveyParticipantId}`,
        }}
        target="_blank"
      >
        <span className="report-icon" data-num={num > 0 ? num : null} data-contacted={item.contacted}>
          <ReportIcon />
        </span>
      </Link>
    );
  };

  getContextMenu = item => {
    return (
      <div className="dropdown">
        <div className="small-gray-button-outlined context-menu">...</div>
        <div className="dropdown-content">
          <div>
            <span onClick={() => this.editParticipantHandler(item)}>
              <Edit className="blue-fill-icon" /> Edit
            </span>
            {item.surveyStatus === 'Created' ? (
              <span onClick={() => this.deleteItemHandler(item)}>
                <Delete className="blue-fill-icon" />
                Delete
              </span>
            ) : null}
            {item.participantStatus === 'Completed' ? (
              item.parentEmail || item.participantEmail || item.parentMobile ? (
                item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
                  <span onClick={() => this.notifyParentReportHandler(item)}>
                    <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                    Resend Notification
                  </span>
                ) : (
                  <span onClick={() => this.notifyParentReportHandler(item)}>
                    <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                    Send Notification
                  </span>
                )
              ) : null
            ) : null}
            {item.participantStatus !== 'Completed' ? (
              item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
                <span onClick={() => this.notifyHandler(item)}>
                  <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                  Resend Invite
                </span>
              ) : (
                <span onClick={() => this.notifyHandler(item)}>
                  <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                  Send Invite
                </span>
              )
            ) : null}

            {item.emailStatus ? (
              <span onClick={() => this.notificationLogHandler(item)}>
                <DescriptionIcon className="blue-fill-icon" onClick={() => {}} />
                Notification History
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  generateTableData = () => {
    // Waits for survey details to be populated
    if (this.props.userSurveys && this.props.userSurveys.length) {
      return this.props.userSurveys.map(item => {
        const isAdult = item.surveyName ? item.surveyName.match(/Adult/i) === null : false;
        const gradeText = isAdult ? getContent('admin_participant-entry-grade') : getContent('admin_participant-entry-year');
        console.log(item);
        return {
          name: (
            <button onClick={() => this.redirectToSurveyDetails(item.surveyId)} className="button-text">
              {this.isUK() ? item.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName}
            </button>
          ),
          participant:
            item.participantStatus !== 'Completed' || this.isEmployerAdmin() ? (
              <span>
                {`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
                <div className="grade-label">
                  {gradeText}: {item.grade}
                </div>
                <div className="grade-label">School: {item.school}</div>
              </span>
            ) : (
              <span>
                
                  {`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
                <div className="grade-label">
                  {gradeText}: {item.grade}
                </div>
                <div className="grade-label">School: {item.school}</div>
              </span>
            ),
          // grade: item.grade,
          DOB: adjustDate(item.birthDate).format(dateFormat),
          login: this.isEmployerAdmin() ? null : item.participantSurveyAuthCode.length === 16 ? (
            <span id="locator_survey-participant-code">{item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')}</span>
          ) : (
            <span id="locator_survey-participant-code">{item.participantSurveyAuthCode}</span>
          ),
          status: item.participantStatus,
          result: item.participantStatus !== 'Completed' || this.isEmployerAdmin() ? <span>-</span> : this.getReportIcon(item.priority, item),
          invite:
            item.parentEmail || item.participantEmail ? (
              item.parentEmail || item.participantEmail ? (
                item.inviteStatus && item.inviteStatus === 'Delivery' ? (
                  <InviteSuccessIcon />
                ) : item.inviteStatus === 'Bounce' ? (
                  <EmailFailIcon />
                ) : (
                  <EmailUnsent />
                )
              ) : (
                '-'
              )
            ) : (
              <span>{'-'}</span>
            ),
          notification:
            item.parentEmail || item.parentMobile || item.participantEmail ? (
              item.participantStatus === 'Completed' ? (
                <span className="report-icon">
                  {item.parentEmail || item.participantEmail ? (
                    item.emailStatus && item.emailStatus === 'Delivery' ? (
                      <EmailSuccessIcon />
                    ) : item.emailStatus === 'Bounce' ? (
                      <EmailFailIcon />
                    ) : (
                      <EmailUnsent />
                    )
                  ) : (
                    ''
                  )}
                  {item.parentMobile ? (
                    item.textStatus && item.textStatus === 'Delivery' ? (
                      <TextSuccessIcon />
                    ) : item.parentMobile && (!item.textStatus || item.textStatus === 'None') ? (
                      <TextUnsent />
                    ) : (
                      <TextFailIcon />
                    )
                  ) : (
                    ''
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            ) : (
              <span>-</span>
            ),
          menu: this.getContextMenu(item),
        };
      });
    } else if (this.props.isLoading) {
      return <Spinner />;
    } else {
      return [];
    }
  };

  getBreadcrumbs = () => {
    return [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
      {
        key: 2,
        url: '/admin/user/surveys',
        label: 'Search participant',
      },
    ];
  };

  isEmployerAdmin = () => this.props.parentOrg && this.props.parentOrg.employerProvider && this.props.parentOrg.employerProvider === 'Employer' && this.props.realRole === 'district_champion';

  render() {
    console.log('parentOrg', this.props.parentOrg);
    const showParticipantInformation =
      this.props.realRole === 'school_champion' || this.props.realRole === 'district_champion' || (this.props.parentOrg && this.props.parentOrg.isTMAllowed && this.props.realRole === 'tm_admin');

    console.log('participantInformation', showParticipantInformation);

    return (
      <div className="create-survey-container">
        <div className="sub-header d-print-none">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
        </div>
        <SearchUserSurveysForm onSubmit={this.searchSubmissionHandler} />
        <div className="sub-header d-print-none">
          <p className="mb-0">{`Assessments List Size - ${this.props.userSurveys ? this.props.userSurveys.length : 0} Assessments`}</p>
        </div>
        <div className="mb-0 statusCountText d-print-none">
          {this.props.userSurveys && this.props.userSurveys.length
            ? `Not Started: ${
                this.props.userSurveys.filter(userSurvey => {
                  return userSurvey.participantStatus === 'Not Started';
                }).length
              } \u00A0 Not Completed: ${
                this.props.userSurveys.filter(userSurvey => {
                  return userSurvey.participantStatus === 'Not Completed';
                }).length
              } \u00A0 Completed: ${
                this.props.userSurveys.filter(userSurvey => {
                  return userSurvey.participantStatus === 'Completed';
                }).length
              }`
            : null}
        </div>
        {/* {
            this.isClever ? <TimeGate grades={this.constructGrades} save={this.setTimegateForSelectedSurveyParticipants} /> : null
          } */}
        {showParticipantInformation ? (
          this.props.isLoading ? (
            <Backdrop>
              <Spinner />
            </Backdrop>
          ) : (
            <TableGenerator
              config={{
                headers: ['ASSESSMENT', 'PARTICIPANT', 'DATE OF BIRTH', 'LOGIN', 'STATUS', 'RESULT', 'INVITATION', 'NOTIFICATIONS', ''],
                contentRows: this.generateTableData(),
              }}
            />
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('ListUserSurveys State: ', state);
  return {
    isLoading: state.users.isLoading,
    userSurveys: state.users.userSurveys,
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg ? state.organizations.organization.parentOrg : state.organizations.organization,
    role: state.auth.role,
    bc_level:
      state.auth.role === 'tm_admin'
        ? 'ADMIN'
        : state.auth.role === 'school_champion' || (state.auth.role === 'district_champion' && state.organizations.organization.parentOrg)
        ? 'GROUP'
        : state.auth.role === 'district_champion'
        ? 'ORGANIZATION'
        : 'NA',
    realRole: state.auth.realRole,
    idToken: state.auth.idToken,
    userSurveysSearchFirstName: state.users.userSurveysSearchFirstName,
    userSurveysSearchLastName: state.users.userSurveysSearchLastName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    clearUserSurveysState: () => dispatch(actions.clearUserSurveysState()),
    submitSurveySearch: () => dispatch(actions.submitSurveySearchStart()),
    listUserSurveys: values => dispatch(actions.listUserSurveys(values)),
    deleteParticipantFromSurvey: (participantId, surveyId) => dispatch(actions.deleteParticipantFromSurvey(participantId, surveyId)),
    getNotificationLog: (participantId, surveyId) => dispatch(actions.getNotificationLog(participantId, surveyId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListUserSurveys));
