import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import EditParticipantForm from './EditParticipantForm';

import * as actions from '../../../../../store/actions';

class EditParticipantModal extends Component {
  handleSubmit = (contacted, status, parentEmail, parentMobile, id, groupName,participantEmail,notes) => {
    //values.contacted = contacted;
    const values = {
      id,
      contacted,
      participantStatus: status,
      parentEmail: parentEmail,
      parentMobile: parentMobile,
      groupName,
      participantEmail,
      notes
    };
    console.log('Values in Edit Participant modal: ', values);
    this.props.updateParticipant(values, this.props.surveyId);
  }

  render() {
    console.log('Edit Participant Modal Props: ', this.props);

    return (
      <ModalWrapper className='edit-participant-modal-wrapper' show={this.props.show} onExited={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent edit-participant-modal'>
          <EditParticipantForm
            onSubmit={this.handleSubmit}
            initialValues={{
              status: this.props.item.participantStatus
            }}
            item={this.props.item}
            isEmployerAdmin={this.props.isEmployerAdmin}
            status={this.props.editParticipantStatus}
            contacted={this.props.editParticipantContacted}
            toggleContacted={(value) => this.props.toggleParticipantContacted(value)}
            setParticipantValues={(values) => this.props.setParticipantValues(values)}
            updateParticipantStarted={(value) => this.props.updateParticipantStarted(value)}
            closeModal={this.props.closeModal}
            surveyStatus={this.props.surveyStatus}
            surveyName={this.props.surveyName}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
};

const mapStateToProps = state => {
  return {
    editParticipantStatus: state.surveyCrud.editParticipantStatus,
    editParticipantContacted: state.surveyCrud.editParticipantContacted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateParticipant: (participantData, surveyId) => dispatch(actions.updateParticipant(participantData, surveyId)),
    updateParticipantStarted: (value) => dispatch(actions.updateParticipantStarted(value)),
    setParticipantValues: (participantData) => dispatch(actions.setParticipantValues(participantData)),
    toggleParticipantContacted: (value) => dispatch(actions.toggleParticipantContacted(value)),
    closeModal: () => dispatch(actions.closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditParticipantModal);
