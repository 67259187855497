import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import BootStrapButton from 'react-bootstrap/Button';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';

class ResponderNotesModal extends Component {
  state ={
    notes: this.props.participant.notes || ''
  }

  changeNote=(e)=>{
    this.setState({notes:e.target.value});
  }

	render() {
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Notes to Participant</Modal.Title>
				</Modal.Header>
				<Modal.Body bsPrefix="modalContent survey-status-modal">
        <div className="label-field-container">
						<label className="label">Notes</label>
						<textarea
							value={this.state.notes}
							onChange={this.changeNote}
							placeholder="Leave notes for other users about this survey participant..."
						></textarea>
					</div>
					<div className="btnContainer-edit-participant">
						<BootStrapButton
							onClick={() => {
                const participant = this.props.participant;
                participant.notes = this.state.notes;
                participant.id = participant.surveyParticipantId;
								this.props.confirm(participant,participant.surveyId,true);
							}}
							type="submit"
							className="modalBtn"
							size="lg"
							variant="success"
						>
						  Save Notes
						</BootStrapButton>
						<BootStrapButton
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							Cancel
						</BootStrapButton>
					</div>
				</Modal.Body>
			</ModalWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(actions.closeModal())
	};
};

export default connect(
	null,
	mapDispatchToProps
)(ResponderNotesModal);
