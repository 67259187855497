import React, { Component } from 'react';
import MOCK_SUGGESTIONS from '../mock-suggestions';
import MOCK_ADULT_SUGGESTIONS from '../mock-suggestions-adult';
import { getContent } from '../../../../utilities/getContent/getContent';

class ParentSuggestionPage extends Component {
  getSuggestions = payload => (this.isAdult(payload) ? Object.assign({}, MOCK_ADULT_SUGGESTIONS) : Object.assign({}, MOCK_SUGGESTIONS));
  isAdult = payload => payload.appliedTo && payload.appliedTo === 'adult';
  getFilteredIndicators = indicators => {
    return indicators.filter(indicator => indicator.riskLevel === 'at_risk' || indicator.riskLevel === 'moderate');
  };
  render() {
    const { payload } = this.props;
    let isAdult = this.isAdult(payload),
      suggestions = this.getSuggestions(payload),
      indicators = this.getFilteredIndicators(payload.indicators);

    // const isAdultTip = (tip) => isAdult && (tip.includes('leadership') || tip.includes('hope')) ? true : false;
    if (Array.isArray(indicators) && indicators.length < 0) {
      return <div className="page page-parent-report parent-report-intro" />;
    }
    return (
      <div className="page page-parent-report">
        <h1 className="mb-4 text-uppercase">{getContent('report_heading-suggestions-for-improving')}</h1>
        {/* eslint-disable-next-line array-callback-return */}
        {indicators.map((indicator, i) => {
          const suggestion = suggestions[indicator.indicator.toLowerCase()];
          return (
            <>
              <div className="indicator-suggestions print-block" key={i}>
                <h3 className="mb-4 font-weight-bold text-uppercase">{getContent(suggestion.label)}</h3>
                {suggestion.intro && isAdult === true ? <div style={{ marginBottom: '16px' }}>{getContent(suggestion.intro)}</div> : null}
                {isAdult === true ? (
                  <div>
                    {suggestion.tips.map((tip, ti) => {
                      return <div key={ti}>{getContent(tip)}</div>;
                    })}
                  </div>
                ) : (
                  <ul>
                    {suggestion.tips.map((tip, ti) => {
                      return <li key={ti}>{getContent(tip)}</li>;
                    })}
                  </ul>
                )}
              </div>
              <div style={{ marginBottom: '32px' }} className="font-weight-bold">{isAdult === true && suggestion.suicidalContext ? getContent('report_suggestions-adult-suicidal-context') : null}</div>
          </>
        )})}
      </div>
    );
  }
}

export default ParentSuggestionPage;
