const displayPhoneNumber = (phoneNumber) => { //cleanup country code for display from cognito
  let result = false;
  let startsWithCountryCode = phoneNumber.substring(0,1) === "+";
  if (startsWithCountryCode) {
    result = phoneNumber.substring(phoneNumber.length - 10)
  }
 
  return result;
};

export default displayPhoneNumber
