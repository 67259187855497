import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import Backdrop from '../../../components/Backdrop/Backdrop';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router-dom';
import ParticipantHeader from '../../Admin/Reports/Shared/ParticipantHeader';
import TFSReport from '../../Admin/Reports/Shared/TFSReport';
import { Accordion, Card, Button, AccordionCollapse } from 'react-bootstrap';

import MOCK_SUGGESTIONS from '../../Admin/Reports/mock-suggestions';
import MOCK_ADULT_SUGGESTIONS from '../../Admin/Reports/mock-suggestions-adult';
import { getContent } from '../../../utilities/getContent/getContent';

class WellnessCenterHome extends Component {
	componentDidMount() {
		this.props.getReport(this.props.location.state.participantId);
  }
  
  

	render() {
		const report = this.props.report;
    let isAdult = report && report.appliedTo && (report.appliedTo === 'adult'),
//		let isAdult = report && report.surveyName && report.surveyName.includes('Adult'),
			suggestions = isAdult ? Object.assign({}, MOCK_ADULT_SUGGESTIONS) : Object.assign({}, MOCK_SUGGESTIONS),
			higherRiskIndicators =
				report &&
				report.indicators &&
				report.indicators.filter(
					indicator => indicator.riskLevel === 'at_risk' || indicator.riskLevel === 'moderate'
				),
			lowerRiskIndicators =
				report &&
				report.indicators &&
				report.indicators.filter(
					indicator => indicator.riskLevel !== 'at_risk' && indicator.riskLevel !== 'moderate'
				);
		return (
			<>
				<div className="tm-report tm-report-student tm-report-parent">
					{this.props.isLoading === false && report ? (
						<>
							<ParticipantHeader payload={this.props.report} />
							<div className="wellness-center-home-header">
								<div className="summary">
									<h1 className="mb-3">Resiliency Assessment</h1>
									<div className="d-flex flex-row justify-content space-between">
										<h5>Summary of Results</h5>
										<span
											className="button-text ml-3"
											onClick={() => {
												this.props.history.push(
													`parent?pId=${this.props.location.state.participantId}`
												);
											}}
										>
											View full details and printable version
										</span>
									</div>
								</div>
							</div>

							<TFSReport
								payload={this.props.report}
								isWellnessCenterHome={true}
								showTimeBox={false}
								isParentReport={true}
							/>
							{higherRiskIndicators && higherRiskIndicators.length > 0 ? (
								<div className="page page-parent-report">
									<h1 className="mb-4 text-uppercase">
										FURTHER INFORMATION FOR INDICATORS REQUIRING ATTENTION:
									</h1>

									{/* eslint-disable-next-line array-callback-return */}
									{/* High Risk Indicators */}
									{higherRiskIndicators &&
										higherRiskIndicators.map((indicator, i) => {
											const suggestion = suggestions[indicator.indicator.toLowerCase()];
											return (
												<Accordion>
													<Card key={i}>
														<Card.Header>
															<Accordion.Toggle
																as={Button}
																variant="link"
																eventKey={i + 1}
															>
																{getContent(suggestion.label)}
															</Accordion.Toggle>
														</Card.Header>
														<Accordion.Collapse eventKey={i + 1}>
															<Card.Body>
																<div className="row">
																	<div className="col flex-column align-content-stretch report-font-size">
																		{suggestion.intro && isAdult === true ? (
																			<div
																				className="mb-3"
																			>
																				{getContent(suggestion.intro)}
																			</div>
																		) : null}
																		{isAdult === true ? (
																			<div className="mb-3">
																				{suggestion.tips.map((tip, ti) => {
																					return (
																						<div className="mb-3" key={ti}>
																							{getContent(tip)}
																						</div>
																					);
																				})}
																			</div>
																		) : (
																			<ul className="mb-3">
																				{suggestion.tips.map((tip, ti) => {
																					return (
																						<li className="mb-3" key={ti}>
																							{getContent(tip)}
																						</li>
																					);
																				})}
																			</ul>
																		)}
																		<div
																			style={{ marginBottom: '32px' }}
																			className="font-weight-bold report-font-size"
																		>
																			{isAdult === true &&
																			suggestion.suicidalContext
																				? getContent(
																						'report_suggestions-adult-suicidal-context'
																				  )
																				: null}
																		</div>
																	</div>
																	{/* <div className="col">
																		<img
																			src="https://dummyimage.com/600x400/000/fff"
																			alt="placeholder"
																		/>
																	</div> */}
																</div>
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</Accordion>
											);
										})}
								</div>
							) : null}
							{/* Lower Risk Indicators */}
              {lowerRiskIndicators && lowerRiskIndicators.length > 0 ? (
								<div className="page page-parent-report">
									<h1 className="mb-4 text-uppercase">
										FURTHER INFORMATION FOR OTHER INDICATORS:
									</h1>

									{/* eslint-disable-next-line array-callback-return */}
									{/* High Risk Indicators */}
									{lowerRiskIndicators &&
										lowerRiskIndicators.map((indicator, i) => {
											const suggestion = suggestions[indicator.indicator.toLowerCase()];
                      if(!suggestion)
                      {
                        console.log("No match for indicator:")
                        console.log(indicator);
                        return (<></>);
                      }
                      console.log(suggestion);
											return (
												<Accordion>
													<Card key={i}>
														<Card.Header>
															<Accordion.Toggle
																as={Button}
																variant="link"
																eventKey={i + 1}
															>
																{getContent(suggestion.label)}
															</Accordion.Toggle>
														</Card.Header>
														<Accordion.Collapse eventKey={i + 1}>
															<Card.Body>
																<div className="row">
																	<div className="col flex-column align-content-stretch">
																		{suggestion.intro && isAdult === true ? (
																			<div className="mb-3"
																			>
																				{getContent(suggestion.intro)}
																			</div>
																		) : null}
																		{isAdult === true ? (
																			<div className="mb-3">
																				{suggestion.tips.map((tip, ti) => {
																					return (
																						<div className="mb-3" key={ti}>
																							{getContent(tip)}
																						</div>
																					);
																				})}
																			</div>
																		) : (
																			<ul className="mb-3">
																				{suggestion.tips.map((tip, ti) => {
																					return (
																						<li className="mb-3" key={ti}>
																							{getContent(tip)}
																						</li>
																					);
																				})}
																			</ul>
																		)}
																		<div
																			style={{ marginBottom: '32px' }}
																			className="font-weight-bold"
																		>
																			{isAdult === true &&
																			suggestion.suicidalContext
																				? getContent(
																						'report_suggestions-adult-suicidal-context'
																				  )
																				: null}
																		</div>
																	</div>
																	{/* <div className="col">
																		<img
																			src="https://dummyimage.com/600x400/000/fff"
																			alt="placeholder"
																		/>
																	</div> */}
																</div>
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</Accordion>
											);
										})}
								</div>
							) : null}
						</>
					) : (
						<Backdrop>
							<Spinner />
						</Backdrop>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		report: state.reports.report,
		isLoading: state.reports.isLoading,
		lang: state.meta.lang
	};
};

const mapDispatchToProps = dispatch => {
	return {
    getReport: participantId => dispatch(actions.getReport(participantId)),
    
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(WellnessCenterHome));
