import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from 'react-bootstrap/Button';

import * as actions from '../../../../../store/actions';

class SearchUserSurveysForm extends Component {
  componentDidMount() {
    this.props.updateSearchParticipantSurveysObj({ name: 'userSearchSurveys', value: { firstName: '', lastName: '' } });
  }

  render() {
    console.log('this.props.userSearchSurveys', this.props.userSearchSurveys);
    return (
      <div className={classnames('container-search-survey', this.props.className)}>
        <p className="search-survey-header">Search Participant Assessments</p>
        <form className="search-survey-form" onSubmit={this.props.onSubmit}>
          <div className="input-container-search-survey">
            <div>
              <label className="label survey-name-label">First Name</label>
              <input
                type="text"
                placeholder="Participant First Name"
                className="search-survey-input"
                style={{ marginRight: "15px"}}
                onChange={event => this.props.updateSearchParticipantSurveysObj({ name: 'userSurveysSearchFirstName', value: event.target.value })}
              />
            </div>
            <div>
              <label className="label survey-name-label">Last Name*</label>
              <input
                type="text"
                placeholder="Participant Last Name"
                className="search-survey-input"
                onChange={event => this.props.updateSearchParticipantSurveysObj({ name: 'userSurveysSearchLastName', value: event.target.value })}
              />
            </div>
          </div>
          <div className="search-survey-submit-container">
            <Button onClick={this.props.onSubmit} className="small-blue-button" disabled={this.props.isLoading || !this.props.userSurveysSearchLastName}>
              Search Participant
            </Button>
            <Button onClick={this.props.hideSurveySearch} className="small-blue-button-outlined">
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userSurveysSearchLastName: state.users.userSurveysSearchLastName,
    userSurveysSearchFirstName: state.users.userSurveysSearchFirstName,
    surveySearchCountryRegion: state.surveyCrud.surveySearchCountryRegion,
    surveySearchStateProvince: state.surveyCrud.surveySearchStateProvince,
    userSearchSurveys: state.users.userSearchSurveys,
    surveySearchSchool: state.surveyCrud.surveySearchSchool,
    surveySearchYear: state.surveyCrud.surveySearchYear,
    searchCountryRegionOptions: state.surveyCrud.searchCountryRegionOptions,
    searchStateProvinceOptions: state.surveyCrud.searchStateProvinceOptions,
    searchDistrictOptions: state.surveyCrud.searchDistrictOptions,
    searchSchoolOptions: state.surveyCrud.searchSchoolOptions,
    isLoading: state.surveyCrud.isLoading,
    role: state.auth.role,
    orgInfo: state.organizations.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideSurveySearch: () => dispatch(actions.hideSurveySearch()),
    updateSearchParticipantSurveysObj: payload => dispatch(actions.updateSearchParticipantSurveysObj(payload)),
    setSearchValues: () => dispatch(actions.setSearchValues()),
    clearStateOptions: () => dispatch(actions.clearStateOptions()),
    clearDistrictOptions: () => dispatch(actions.clearDistrictOptions()),
    clearSchoolOptions: () => dispatch(actions.clearSchoolOptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUserSurveysForm);
