import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreateSurveyStep from '../CreateSurveyStep/CreateSurveyStep';
import IndicatorSelectForm from '../IndicatorSelectForm/IndicatorSelectForm';
import SurveyDateSelect from '../SurveyDateSelect/SurveyDateSelect';
import SurveyNameSelect from '../SurveyNameSelect/SurveyNameSelect';
import StudentRosterUpload from '../StudentRosterUpload/StudentRosterUpload';
import RemoteSubmitButton from '../RemoteSubmitButton/RemoteSubmitButton';
import DesignatedEmailRecipient from '../DesignatedEmailRecipient/DesignatedEmailRecipient';
import WellnessCenterCheck from '../WellnessCenterCheck/WellnessCenterCheck';

class CreateSurveyStepGroup extends Component {
  state = {
    isWaiting: false,
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  setIsWaiting = value => {
    this.setState({ isWaiting: value });
  };

  render() {
    let indicatorsChosen = (() => {
      const surveyObj = this.props.surveyCrud.createSurveyObj;
      let result = false;
      if (surveyObj.appliedTo === 'adult' && this.isUK() === false) {
        result = surveyObj.chosenIndicators.length > 0 && surveyObj.profession ? true : false;
      } else {
        result = surveyObj.chosenIndicators.length > 0 ? true : false;
      }
      return result;
    })();
    let manualRosterHasLength = this.props.surveyCrud.manualRoster.length > 0;
    let manualRosterPopulated = manualRosterHasLength && this.props.formErrors && !this.props.formErrors.syncErrors;
    let fileUploaded = this.props.surveyCrud.createSurveyObj.s3Tags.fileKey;
    let rosterPopulated = !!fileUploaded;

    // If there's a manual roster entry, verify there are no form errors
    if (manualRosterHasLength) {
      rosterPopulated = manualRosterPopulated;
    }

    if (this.props.grades && this.props.grades.length > 0) {
      rosterPopulated = true;
    }

    return (
      <>
        <CreateSurveyStep
          number="1"
          header="Select grade level or adult (staff)"
          subhead="The assessment question will be defined by this choice automatically."
        >
          <IndicatorSelectForm
            primary={this.props.parentOrg ? this.props.parentOrg.primary : null}
            secondary={this.props.parentOrg ? this.props.parentOrg.secondary : null}
            adult={this.props.parentOrg ? this.props.parentOrg.adult : null}
          />
        </CreateSurveyStep>
        <CreateSurveyStep
          number="2"
          header="Set the assessment date"
          subhead="Set the date for the assessment. This, along with the categories above, will define the assessment name."
          active={indicatorsChosen}
        >
          <SurveyDateSelect appliedTo={this.props.surveyCrud.createSurveyObj.appliedTo} />
        </CreateSurveyStep>
        <CreateSurveyStep
          number="3"
          header="Set the assessment name"
          subhead="Set the name for the assessment. This, along with the categories above, will define the assessment name."
          active={indicatorsChosen}
        >
          <SurveyNameSelect
            quarter={this.props.surveyCrud.createSurveyObj.quarter}
            year={this.props.surveyCrud.createSurveyObj.year}
            orgName={this.props.orgName}
            appliedTo={this.props.surveyCrud.createSurveyObj.appliedTo}
            indicatorIdsByCategory={this.props.surveyCrud.indicatorIdsByCategory}
            chosenIndicators={this.props.surveyCrud.createSurveyObj.chosenIndicators}
          />
        </CreateSurveyStep>
        <CreateSurveyStep
          number="4"
          header="Upload your participant list"
          appliedTo={this.props.surveyCrud.createSurveyObj.appliedTo}
          subhead={`The participant list must follow the <a href="ROSTERNAME">example participant list template</a> format. Save participant list as .csv file.${
            !this.isUK()
              ? '<p>For more information on how to create a assessment using a participant list, watch this <a target="_blank" href="https://www.terracemetrics.org/wp-content/uploads/2020/02/TMRosterTrainingVideo1080p.mp4">video</a></p>'
              : ''
          }`}
          active={indicatorsChosen}
        >
          <StudentRosterUpload setIsWaiting={this.setIsWaiting} appliedTo={this.props.surveyCrud.createSurveyObj.appliedTo} />
        </CreateSurveyStep>
        <CreateSurveyStep
          number="5"
          header="Enter designated email recipients for priority reports"
          subhead="The email recipients will be notified whenever there is an assessment participant that is considered high priority or at risk."
          active={indicatorsChosen && rosterPopulated}
        >
          <DesignatedEmailRecipient />
        </CreateSurveyStep>
        {this.props.surveyCrud.createSurveyObj.appliedTo === 'adult' ? (
          <CreateSurveyStep
            number="6"
            header={`Send participants to the Wellness ${this.isUK() ? 'Centre' : 'Center'} after their assessment is completed. The Wellness ${
              this.isUK() ? 'Centre' : 'Center'
            } is where participants will view their results and access support content`}
            active={indicatorsChosen && rosterPopulated}
          >
            <WellnessCenterCheck />
          </CreateSurveyStep>
        ) : null}
        <CreateSurveyStep
          number={this.props.surveyCrud.createSurveyObj.appliedTo === 'adult' ? '7' : '6'}
          header="Save your assessment"
          subhead="Once you are satisfied with your assessment, save your results. You will be taken to a page which will allow you to review and edit your assessment values as needed."
          active={indicatorsChosen && rosterPopulated}
        >
          <RemoteSubmitButton isWaiting={this.state.isWaiting} />
        </CreateSurveyStep>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    surveyCrud: state.surveyCrud,
    formErrors: state.form['participant-entry-form'],
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg,
    createSurveyObj: state.surveyCrud.createSurveyObj,
    manualRoster: state.surveyCrud.manualRoster,
    grades: state.surveyCrud.grades,
    studentsByGrade: state.surveyCrud.studentsByGrade,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
  };
};

export default connect(mapStateToProps)(CreateSurveyStepGroup);
