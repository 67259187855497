import React from 'react';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';

const SELF_HARM_INDICATOR_ID = '44b68c8d-b49d-460e-9668-a3a4185bcb23';

const IndicatorSelectFormTypeGroup = props => {
  const isUK = process.env.REACT_APP_STAGE === 'uk';
  const { typeName, categoryName, indicatorsByCategoryAndType, chosenIndicators } = props;
  return (
    <div className={`subgroup-create-survey ${typeName}`}>
      <p className="subgroup-type-header">{typeName}</p>

      {indicatorsByCategoryAndType[categoryName][typeName].map(option => {
        if (option.indicatorId === SELF_HARM_INDICATOR_ID) {
          return (
            <CheckBox
              key={option.indicatorId}
              locatorId={`locator_${option.contentId}`}
              label={
                isUK
                  ? option.indicator
                      .replace('Global Satisfaction', 'Happiness')
                      .replace('Ostracism', 'Social Exclusion')
                      .replace('Grit', 'Tenacity')
                      .replace('Intimate Partner Violence', 'Domestic Violence')
                  : option.indicator
              }
              name={option.indicator}
              value={option.id}
              isChecked={chosenIndicators.find(id => id === option.indicatorId)}
              disabled={props.isSelfHarmDisabled}
              onClick={() => props.toggleCheckedIndicatorInForm(option.indicatorId)}
            />
          );
        } else {
          return (
            <CheckBox
              key={option.indicatorId}
              locatorId={`locator_${option.contentId}`}
              label={
                isUK
                  ? option.indicator
                      .replace('Global Satisfaction', 'Happiness')
                      .replace('Ostracism', 'Social Exclusion')
                      .replace('Grit', 'Tenacity')
                      .replace('Intimate Partner Violence', 'Domestic Violence')
                  : option.indicator
              }
              name={option.indicator}
              value={option.id}
              isChecked={chosenIndicators.find(id => id === option.indicatorId)}
              onClick={() => props.toggleCheckedIndicatorInForm(option.indicatorId)}
            />
          );
        }
      })}
    </div>
  );
};

export default IndicatorSelectFormTypeGroup;
