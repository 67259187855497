import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import BootStrapButton from 'react-bootstrap/Button';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import Typeahead from '../../../../../components/Typeahead/Typeahead';
const { country, stateData } = require('../../../../../utilities/locale/').getLocale();

class CreateDistrictForm extends Component {
  state = {
    primary: this.props.initialValues.primary || false,
    secondary: this.props.initialValues.secondary || false,
    adult: this.props.initialValues.adult || false
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  toggleSurveyTypeCheckedPrimary = type => {
    if (type === 'primary') {
      this.setState({ primary: !this.state.primary });
    } else if (type === 'secondary') {
      this.setState({ secondary: !this.state.secondary });
    } else if (type === 'adult') {
      this.setState({ adult: !this.state.adult });
    }
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';
  renderDropdown = ({ input, data, valueField, textField, className }) => {
    return (
      <Dropdown
        {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        onChange={input.onChange}
        className={className}
      />
    );
  };

  renderTypeahead = (props) => {
    console.log('typeahead props: ', props);
    const { input } = props;
    return (
      <Typeahead
        {...input}
        onChange={input.onChange}
      />
    )
  }

  isCleverItem = () => {
    return this.props.item && this.props.item.cleverData;
  }

  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container">
            <label className="label" htmlFor="country">
              Country/Region
						</label>
            <Field name="country" component={this.renderDropdown} data={[country]} className="short" disabled={this.isCleverItem()} />
          </div>
          <div className="label-field-container">
            <label className="label" htmlFor="state">
              {this.isUK() ? 'County' : 'State/Province'}
            </label>
            <Field
              name="state"
              component={this.renderDropdown}
              data={stateData}
              textField="abbreviation"
              valueField="abbreviation"
              className="short"
              disabled={this.isCleverItem()}
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="name">
              {this.isUK() ? 'Group' : 'Organization'} Name
						</label>
            <Field
              name="name"
              placeholder={`Full ${this.isUK() ? 'Group' : 'Organization'} Name`}
              type="text"
              component="input"
              className="participant-entry-field"
              disabled={this.isCleverItem()}
            />
          </div>
        </div>
        <div className="d-flex justify-content-space-between mb-2">
          {this.props.initialValues.employerProvider ? (
            ''
          ) : (
              <div className="label-field-container">
                <label className="label" htmlFor="employerProvider">
                  {`Provider${this.isUK() ? '' : '(School)'}/Employer`}
                </label>
                <Field
                  name="employerProvider"
                  component={this.renderDropdown}
                  data={['Provider', 'Employer']}
                  className="tm-dropdown"
                />
              </div>
            )}

          <div className="label-field-container label-background-color">
            <label className="label">
              {this.isUK() ? 'Primary' : 'Elementary'}
              <img
                className="checkbox  border border-dark rounded"
                alt="Checkbox"
                src={this.state.primary ? CheckedImage : UncheckedImage}
              />
              <Field
                name="primary"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('primary');
                }}
              />
            </label>
          </div>
          <div className="label-field-container label-background-color">
            <label className="label">
              {this.isUK() ? 'Secondary' : 'Upper Grades'}
              <img
                className="checkbox border border-dark rounded"
                alt="Checkbox"
                src={this.state.secondary ? CheckedImage : UncheckedImage}
              />
              <Field
                name="secondary"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('secondary');
                }}
              />
            </label>
          </div>
          <div className="label-field-container label-background-color">
            <label className="label">
              Adult
							<img
                className="checkbox border border-dark rounded"
                alt="Checkbox"
                src={this.state.adult ? CheckedImage : UncheckedImage}
              />
              <Field
                name="adult"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('adult');
                }}
              />
            </label>
          </div>
        </div>
        {!this.props.item ? (
          <>
            <div className="d-flex align-items-end mb-2">
              <div className="label-field-container">
                <label className="label" htmlFor="firstName">
                  Champion Name
								</label>
                <Field
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={this.isCleverItem()}
                />
              </div>
              <div className="label-field-container">
                <Field
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={this.isCleverItem()}
                />
              </div>
              <div className="label-field-container">
                <label className="label" htmlFor="email">
                  Champion Email
								</label>
                <Field
                  name="email"
                  placeholder="Email Address"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={this.isCleverItem()}
                />
              </div>
            </div>
            <div className="d-flex col-4 mb-4 p-0">
              <div className="label-field-container mr-2">
                <label className="label" htmlFor="phoneNumber">
                  Champion Phone
								</label>
                <Field
                  name="phoneNumber"
                  type="tel"
                  component={RenderPhoneField}
                  className="participant-entry-field"
                  disabled={this.isCleverItem()}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="btnContainer justify-content-end">
          <BootStrapButton
            type="submit"
            className="small-button green"
            size="lg"
            variant="success"
            disabled={invalid || pristine || submitting}
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton
            onClick={this.props.closeModal}
            className="small-blue-button-outlined"
            size="lg"
            variant="outline-primary"
          >
            Cancel
					</BootStrapButton>
        </div>
        <div className="d-flex align-items-center">
          {typeof this.props.error === 'string' ? <span className="error">{this.props.error}</span> : null}
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'create-district-form'
})(CreateDistrictForm);
