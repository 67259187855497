import React from 'react';
import CheckedImage from '../../assets/imgs/checked.png';
import UncheckedImage from '../../assets/imgs/unchecked.png';
import './CheckBox.css';

import classnames from 'classnames';

const CheckBox = props => {
  let labelClasses = [];
  if (props.labelClasses) {
    labelClasses = [...props.labelClasses];
  }

  return (
    <div className={classnames(props.className, { disabled: props.disabled })}>
      <label className={labelClasses.join(' ')} id={props.locatorId ? props.locatorId : null} onClick={props.onClick} htmlFor={props.name}>
        <img className="checkbox border border-dark rounded" alt="Checkbox" src={props.isChecked ? CheckedImage : UncheckedImage} />
        <input type="checkbox" value={props.value} name={props.name} disabled={props.disabled} />
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
