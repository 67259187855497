import React, { Component } from 'react';
import { connect } from 'react-redux';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';

import CreateSurveyStepGroup from './CreateSurveyStepGroup/CreateSurveyStepGroup';

import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';
import * as actions from '../../../../store/actions';

class CreateSurvey extends Component {
  componentDidMount() {
    this.props.clearCreateSurveyState();
    if (this.props.parentOrg) {
      const parentOrg = this.props.parentOrg;
      const numberOfGroup = parentOrg.secondary + parentOrg.primary + parentOrg.adult;
      console.log('NUMBER OF GROUP : ', numberOfGroup);
      if (numberOfGroup === 1) {
        if (parentOrg.secondary) {
          this.props.setAppliedTo('uppergrades');
        } else if (parentOrg.primary) {
          this.props.setAppliedTo('elementary');
        } else if (parentOrg.adult) {
          this.props.setAppliedTo('adult');
        }
      }
    }
    this.props.listIndicators();
  }
  isUK = () => process.env.REACT_APP_STAGE === 'uk';
  getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: `${this.isUK() ? 'Organisation Home' : 'Group Home'}`,
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: 'Assessments',
      },
      {
        key: 3,
        url: '/admin/survey/create',
        label: 'Create Assessment',
      },
    ];

    return breadcrumbs;
  };

  render() {
    console.log('createSurveyObj: ', this.props.createSurveyObj);
    console.log('manualRoster: ', this.props.manualRoster);
    return (
      <div className="create-survey-container">
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
        </div>
        <p>
          To create an assessment, fill out each section below. Sections are broken into steps, and each step will unlock upon your completion of the previous step. You will not be able to save your
          assessment until you have completed all the steps.
        </p>
        <CreateSurveyStepGroup createSurvey={this.props.createSurvey} manualRoster={this.props.manualRoster} />
        {this.props.isRosterUploading || this.props.isLoading ? (
          <>
            <Backdrop>
              <Spinner />
            </Backdrop>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.surveyCrud.isLoading,
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg,
    createSurveyObj: state.surveyCrud.createSurveyObj,
    manualRoster: state.surveyCrud.manualRoster,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkRosterUploadStatus: () => dispatch(actions.checkRosterUploadStatus()),
    createSurvey: () => dispatch(actions.createSurvey()),
    clearCreateSurveyState: () => dispatch(actions.clearCreateSurveyState()),
    setAppliedTo: value => dispatch(actions.setAppliedTo(value)),
    listIndicators: () => dispatch(actions.listIndicators()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
