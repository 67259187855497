import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const AsyncExample = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const renderMenuItemChildren = (option, props) => (
    <>
      <span>{option.name}</span>
    </>
  );

  return (
    <AsyncTypeahead
      id={props.name}
      disabled={props.disabled}
      name={props.name}
      isLoading={isLoading}
      labelKey={props.labelKey || 'name'}
      minLength={3}
      onSearch={(query) => props.handleSearch(query, setOptions, setIsLoading)}
      onChange={props.onChange}
      options={options}
      placeholder="Search by start of name"
      value={props.value}
      filterBy={() => true}
      renderMenuItemChildren={props.renderMenuItemChildren || renderMenuItemChildren}
    />
  );
};

export default AsyncExample;