import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { dateFormat } from '../../../../../utilities/dates';

import * as actions from '../../../../../store/actions';

class  NotifyHistoryModal extends Component {

  generateTableData = () => {
    if (this.props.notificationAttempts && this.props.notificationAttempts.length > 0) {
      const result = this.props.notificationAttempts.map((logEntry, index) => {
        return {
          date: moment(logEntry.timestamp).format(`${dateFormat} hh:mm`),
          recipient: logEntry.destinationEmail,
          status: logEntry.messageStatus
        }
      })
      return result;
    }
    return [];
  }

  render() {
    console.log('Notify Log History: ', this.props.item);

    return (
      <ModalWrapper className='edit-participant-modal-wrapper' show={this.props.show} onExited={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notification History - {this.props.item.firstName} {this.props.item.lastName}</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent edit-participant-modal'>
          <TableGenerator
            config={{
              headers: ['DATE', 'RECIPIENT', 'STATUS'],
              contentRows: this.generateTableData(),
            }}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
};

const mapStateToProps = state => {
  console.log('Modal State: ', state);
  return {
    notificationAttempts: state.surveyCrud.notificationAttempts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyHistoryModal);
