import React from 'react';

import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import IndicatorSelectFormTypeGroup from './IndicatorSelectFormTypeGroup';

const IndicatorSelectFormCategoryGroup = props => {
  const { categoryName, indicatorsByCategoryAndType, indicatorIdsByCategory, chosenIndicators } = props;
  return (
    <div className="indicator-group-create-survey">
      <CheckBox
        className="indicator-group-title"
        labelClasses={['indicator-headers']}
        label={`${categoryName} Indicators`}
        locatorId={`locator_${categoryName}-indicators-button`}
        name={categoryName}
        value={categoryName}
        isChecked={indicatorIdsByCategory[categoryName].every(elem => chosenIndicators.indexOf(elem) > -1) ? 'checked' : undefined}
        onClick={() => props.toggleCategoryIndicators(categoryName)}
      />
      <div className="indicator-subgroups-create-survey">
        {Object.keys(indicatorsByCategoryAndType[categoryName]).map(typeName => {
          return (
            <IndicatorSelectFormTypeGroup
              key={typeName}
              categoryName={categoryName}
              typeName={typeName}
              indicatorsByCategoryAndType={indicatorsByCategoryAndType}
              chosenIndicators={chosenIndicators}
              toggleCheckedIndicatorInForm={props.toggleCheckedIndicatorInForm}
              isSelfHarmDisabled={props.isSelfHarmDisabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default IndicatorSelectFormCategoryGroup;
