import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';

import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import Button from 'react-bootstrap/Button';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import SearchReportsForm from './SearchReportsForm';
import * as actions from '../../../../store/actions';
import { dateFormat } from '../../../../utilities/dates';

class SearchReports extends Component {
  componentDidMount() {
    this.searchSubmissionHandler();
  }

  componentWillUnmount() {
    this.props.clearSearchReportsPaginationValues();
  }

  isUK =()=> (process.env.REACT_APP_STAGE === 'uk');

  searchSubmissionHandler = (lastKey = null, sortKey = null, pageNumber = 0) => {
    console.log('SEARCH SUBMISSION HANDLER: last Key: ', lastKey, ' SortKey: ', sortKey);
    console.log('SEARCH SUBMISSION HANDLER PAGE NUMBER: ', pageNumber);
    const values = {
      country: this.props.reportSearchCountryRegion,
      state: this.props.reportSearchStateProvince,
      district: this.props.reportSearchDistrict,
      school: this.props.reportSearchSchool,
      year: this.props.reportSearchYear,
      name: this.props.reportSearchName,
      reportType: this.props.reportSearchReportType,
      lastKey: lastKey,
      sortKey: sortKey,
      pageNumber,
    };
    this.props.searchReports(values);
  };

  changeRoleAndOrg = (item, isParentOrg) => {
    console.log('CHANGE ROLE AND ORG ITEM: ', item);
    let orgId = item.orgId;
    let role = 'school_champion';
    if (isParentOrg) {
      orgId = item.parentOrgId;
      role = 'district_champion';
    }

    // CHANGE ROLE
    this.props.changeRole(role);
    // CHANGE ORGANIZATION
    this.props.changeOrganization(orgId);
    // REDIRECT
    this.props.history.push('/admin');
  };

  generateTableDataForStudents = () => {
    const createReportLink = (reportType, item, copy) => {
      console.log(reportType);
      if (reportType === 'student') {
        return (
          <Link
            className="button-text"
            to={{
              pathname: `/admin/reports/${reportType}?pId=${item.surveyParticipantId}`,
            }}
            target="_blank"
          >
            {copy}
          </Link>
        );
      }
      return (
        <Link
          className="button-text report-link"
          to={{
            pathname: `/admin/reports/${reportType}?pId=${item.surveyParticipantId}`,
          }}
          target="_blank"
        >
          <span className="report-icon">
            <ReportIcon />
          </span>
        </Link>
      );
    };

    const createAdminLink = copy => {
      return (
        <Link
          className="button-text"
          to={{
            pathname: `/admin`,
          }}
        >
          {copy}
        </Link>
      );
    };

    if (this.props.searchReportsResults && this.props.searchReportsResults.length > 0) {
      const result = this.props.searchReportsResults.map(item => {
        console.log(item);
        const obj = {};
        //obj.state = item.state;
        obj.district = this.props.role === 'tm_admin' ? createAdminLink(item.district) : item.district;
        obj.school = this.props.role === 'tm_admin' ? createAdminLink(item.school) : item.school;
        obj.student = createReportLink('student', item, `${item.firstName} ${item.lastName}`);
        obj.result = createReportLink('parent', item);
        obj.survey = this.isUK() ? item.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName;
        obj.status = item.participantStatus;
        return obj;
      });
      return result;
    }

    return [];
  };

  generateTableDataForSchools = () => {
    if (this.props.searchReportsResults && this.props.searchReportsResults.length > 0) {
      const result = this.props.searchReportsResults.map(item => {
        console.log(item);
        const obj = {};
        if (this.props.role && this.props.role === 'tm_admin') {
          obj.district =
            (
              <button className="button-text" onClick={() => this.changeRoleAndOrg(item, true)}>
                {item.district}
              </button>
            ) || '';
        } else {
          obj.district = item.district;
        }
        if (this.props.role && this.props.role === 'tm_admin') {
          obj.school =
            (
              <button className="button-text" onClick={() => this.changeRoleAndOrg(item)}>
                {item.school}
              </button>
            ) || '';
        } else {
          obj.school = item.school;
        }
        // if and only if you are district champion you will see a school name with plain text instead of the context switch link
        if (this.props.role && this.props.role === 'district_champion') {
          obj.school = item.school;
        }
        obj.surveyName = this.isUK() ? item.surveyName.replace('Upper Grades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName;
        obj.date = moment(item.createdAt).format(`${dateFormat} LTS`);
        obj.result = (
          <Link
            className="button-text"
            to={{
              pathname: `/admin/reports/survey?id=${item.surveyId}`,
            }}
            target="_blank"
          >
            <ReportIcon />
          </Link>
        );

        return obj;
      });
      return result;
    }

    return [];
  };

  getTableConfig = () => {
    const config = {};
    if (this.props.currentReportType === 'schoolReport') {
      config.headers = [`${this.isUK ? 'Group' :'Organization'}`, `${this.isUK ? 'Organisation':'Group'}`, 'Assessment Name', 'Date', 'Result'];
      config.contentRows = this.generateTableDataForSchools();
    }

    if (this.props.currentReportType === 'studentReport') {
      config.headers = [`${this.isUK ? 'Group' :'Organization'}`, `${this.isUK ? 'Organisation':'Group'}`, 'Participant', 'Result', 'Assessment', 'Status'];
      config.contentRows = this.generateTableDataForStudents();
    }
    return config;
  };

  getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
      {
        key: 2,
        url: '/admin/reports/search',
        label: 'Reports',
      },
    ];

    return breadcrumbs;
  };

  getPrevious = () => {
    const { currentExclusiveStartKey } = this.props;
    let lastKey;
    let sortKey;
    let pageNumber = this.props.pageNumber - 1;
    console.log('pageNumber: ', pageNumber);

    if (currentExclusiveStartKey && currentExclusiveStartKey.surveyParticipantId && currentExclusiveStartKey['surveyParticipant-sortKey']) {
      //this.props.updateSearchReportsObj({ name: 'lastKey', value: currentExclusiveStartKey.surveyParticipantId });
      //this.props.updateSearchReportsObj({ name: 'sortKey', value: currentExclusiveStartKey['surveyParticipant-sortKey'] });
      //lastKey = currentExclusiveStartKey.surveyParticipantId;
      //sortKey = currentExclusiveStartKey['surveyParticipant-sortKey'];
      console.log('this.props.pages: ', this.props.pages);
      console.log('this.props.pageNumber: ', this.props.pageNumber);
      if (!this.props.pages[this.props.pageNumber - 1].currentExclusiveStartKey) {
        lastKey = null;
        sortKey = null;
      } else {
        lastKey = this.props.pages[this.props.pageNumber - 1].currentExclusiveStartKey.surveyParticipantId;
        sortKey = this.props.pages[this.props.pageNumber - 1].currentExclusiveStartKey['surveyParticipant-sortKey'];
      }
    }
    this.searchSubmissionHandler(lastKey, sortKey, pageNumber);
  };

  getNext = () => {
    const { lastEvaluatedKey } = this.props;
    let lastKey;
    let sortKey;
    if (lastEvaluatedKey && lastEvaluatedKey.surveyParticipantId && lastEvaluatedKey['surveyParticipant-sortKey']) {
      //this.props.updateSearchReportsObj({ name: 'lastKey', value: lastEvaluatedKey.surveyParticipantId });
      //this.props.updateSearchReportsObj({ name: 'sortKey', value: lastEvaluatedKey['surveyParticipant-sortKey'] });
      lastKey = lastEvaluatedKey.surveyParticipantId;
      sortKey = lastEvaluatedKey['surveyParticipant-sortKey'];
    }
    this.searchSubmissionHandler(lastKey, sortKey, this.props.pageNumber + 1);
  };

  render() {
    console.log('EXCLUSIVE START KEY: ', this.props.currentExclusiveStartKey);
    console.log('LASTKEY: ', this.props.isLastKey);
    return (
      <div className="survey-list-container">
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
          <div className={this.props.showReportsSearch ? 'hidden' : 'survey-list-button-header-container'}>
            <Button onClick={this.props.toggleShowReportSearch} className={this.props.searchReportsResults === null ? 'hidden' : 'small-blue-button'}>
              Search Reports
            </Button>
          </div>
        </div>
        <SearchReportsForm onSubmit={() => this.searchSubmissionHandler(null, null)} className={this.props.showReportsSearch ? null : 'hidden'} />
        {/* <div className={ this.props.searchReportsResults !== null ? null : 'hidden'}> */}
        {this.props.isLoading ? (
          <Backdrop>
            <Spinner />
          </Backdrop>
        ) : (
          <>
            <p>{`${this.props.searchReportsResults.length || 0} Assessment Results`}</p>
            <div className={this.props.searchReportsResults.length > 0 ? null : 'hidden'}>
              <TableGenerator
                pagination={
                  this.props.currentReportType === 'studentReport'
                    ? {
                        //next: !this.props.isLastKey && this.props.lastEvaluatedKey,
                        next: this.props.lastEvaluatedKey,
                        previous: this.props.pageNumber - 1 >= 0 && this.props.currentExclusiveStartKey && this.props.currentExclusiveStartKey.surveyParticipantId,
                      }
                    : false
                }
                getPrevious={this.getPrevious}
                getNext={this.getNext}
                config={this.getTableConfig()}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportSearchCountryRegion: state.reports.reportSearchCountryRegion,
    reportSearchStateProvince: state.reports.reportSearchStateProvince,
    reportSearchDistrict: state.reports.reportSearchDistrict,
    reportSearchSchool: state.reports.reportSearchSchool,
    reportSearchYear: state.reports.reportSearchYear,
    reportSearchName: state.reports.reportSearchName,
    reportSearchReportType: state.reports.reportSearchReportType,
    showReportsSearch: state.reports.showReportsSearch,
    searchReportsResults: state.reports.searchReportsResults,
    isLoading: state.reports.isLoading,
    role: state.auth.role,
    realRole: state.auth.realRole,
    currentReportType: state.reports.currentReportType,
    currentExclusiveStartKey: state.reports.currentExclusiveStartKey,
    lastEvaluatedKey: state.reports.lastEvaluatedKey,
    isLastKey: state.reports.isLastKey,
    pageNumber: state.reports.pageNumber,
    pages: state.reports.pages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchReports: values => dispatch(actions.searchReports(values)),
    toggleShowReportSearch: () => dispatch(actions.toggleShowReportSearch()),
    changeRole: role => dispatch(actions.changeRole(role)),
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    updateSearchReportsObj: obj => dispatch(actions.updateSearchReportsObj(obj)),
    clearSearchReportsPaginationValues: () => dispatch(actions.clearSearchReportsPaginationValues()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchReports),
);
