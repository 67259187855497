import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';

class MeanResilienceyScoresPage extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && (payload.appliedTo === 'adult');

    const getAggregatedAverageResiliencyScores = ({ self }) => {
      const currentScores = self.props.payload.reports.averageResiliencyScores || [];
      const relatedScores =
        (self.props.selectedRelatedReport && self.props.selectedRelatedReport.reports.averageResiliencyScores)
        || [];

      const result = [];

      for (const score of currentScores)
        result.push({
          current: score,
          related: { ...score, school: null }
        });

      for (const score of relatedScores) {
        let maybeExisting = result.find(({ current }) => current.indicator === score.indicator);

        if (maybeExisting)
          maybeExisting.related = score; else {
          maybeExisting = {
            related: score,
            current: { ...score, school: null }
          };

          result.push(maybeExisting);
        }
      }

      return result;
    };

    return (
      <div className="page group-report" style={{ marginTop: '100px' }}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5">
            <div className="row">
              <h2 className="mb-3 text-uppercase">Mean Resiliency Scores by Indicator</h2>
              <table className="table table-sm table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Indicator</th>
                    {this.props.selectedRelatedReport && (<th>TM Historical Average</th>)}
                    <th>{payload.semester} {payload.year} Mean</th>
                    {this.props.selectedRelatedReport && (
                      <th>
                        {this.props.selectedRelatedReport.semester}
                        {this.props.selectedRelatedReport.year} Mean
                      </th>
                    )}
                    <th>Range</th>
                    <th>Interpretation</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    getAggregatedAverageResiliencyScores({ self: this })
                      .map(({ current, related }, index) => {
                        return (
                          <tr key={index}>
                            <td className="font-weight-bold">{current.indicator}</td>
                            {
                              this.props.selectedRelatedReport
                              && <td>{related.school || 'No historical data'}</td>
                            }
                            <td>{current.school}</td>
                            {
                              this.props.selectedRelatedReport
                              && <td>
                                {
                                  related.school
                                    ? parseFloat(related.school, 10).toFixed(2)
                                    : 'No historical data'
                                }
                              </td>
                            }
                            <td>{current.range}</td>
                            <td>{current.interpretation}</td>
                          </tr>
                        );
                      })
                  }
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="box-comment">
                <h3>What is this table telling me?</h3>
                <p>
                  {isAdult ? getContent("report_overall_page04_historical_table_1_adult") : getContent("report_overall_page04_historical_table_1_student")}
                </p>
                <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                <p>
                  {isAdult ? getContent("report_overall_page04_historical_table_2_adult") : getContent("report_overall_page04_historical_table_2_student")}
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedRelatedReport: state.overallSurveyReport.selectedRelatedReport
  };
};

export default connect(
  mapStateToProps
)(withRouter(MeanResilienceyScoresPage));

