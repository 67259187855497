import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Changelog extends Component {
  render() {
    return (
      <div className="content">
        <div className="row">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a aria-current="page" className="active" href="/admin/">Home</a>
            </li>
            <li className="breadcrumb-item active">
              <span>Changelog</span>
            </li>
          </ol>
        </div>
        <h1>Changelog</h1>
        <div className="row">
          <div className="col-11">
            <p>The changelog exists for Terrace Administrators to track manual changes they have made to the survey questions, along with the reason those changes were made.</p>
          </div>
          <div className="col-11">
          <h2>08.17.2020</h2>
            <ul>
              <li className="mb-1">Updated UK text content</li>
              <li className="mb-1">Changed TM to MyMynd in the UK </li>
              <li className="mb-1">Added new responder role.</li>
              <li className="mb-1">Retake survey now available.</li>
              <li className="mb-1">New wellness center page.</li>
              <li className="mb-1">Provider and employer roles can be given to districts(groups in the UK)</li>
              <li className="mb-1">Limited access to participant data from Admins</li>
            </ul>
          <h2>06.22.2020</h2>
            <ul>
              <li className="mb-1">Fixed the question order, so that questions_b will always appear after the initial questions block.</li>
              <li className="mb-1">Added functionality to export content in content.js to csv files.</li>
              <li className="mb-1">Added new function to execute the export using 'npm run exportQuestions'.</li>
            </ul>
          <h2>02.12.2020</h2>
            <ul>
              <li className="mb-1">UK Text was updated to match requests.</li>
              <li className="mb-1">The Adult photos were created and replaced the photo of children.</li>
              <li className="mb-1">An inactivity timeout was added to logout users after 15 minutes of inactivity.</li>
              <li className="mb-1">A new data aggregation report was added below the parent permission templates.</li>
              <li className="mb-1">Emoticons were added to the questions to help survey participants understand how their responses relate to the questions.</li>
              <li className="mb-1">A notes text box was added to edit to allow counselors or advisors to leave notes.</li>
              <li className="mb-1">The counts for the people in survey statuses was added to the top of the table on the Survey Details page.</li>
              <li className="mb-1">A designated TM Admin and a designated email address associated with the survey on creation will now be notified when a high priority participant has been flagged. </li>
              <li className="mb-1">There is now a feature to add multiple email addresses to send the participants survey results to when completed if the participant is in an Adult Survey.</li>
              <li className="mb-1">Text changes were implemented based on requests for US english.</li>
              <li className="mb-1">Any indicator that has a conditional second part(i.e. Trauma) will now have validity questions placed after them instead of in the middle. </li>
              <li className="mb-1">A video link has been added to the survey create page.</li>
              <li className="mb-1">A bulk edit feature has been implemented to allow the status change of multiple participants.</li>
              <li className="mb-1">A bulk delete feature has been implemented to allow the deletion of multiple participants.</li>
              <li className="mb-1">A bulk delete feature has been implemented to allow the deletion of multiple participants.</li>
              <li className="mb-1">The wellness center email has been updated to reflect requests to change it.</li>
            </ul>
            <h2>01.22.2020</h2>
            <ul>
              <li className="mb-1">Updated TFS Calculation to include Supplemental Indicators.</li>
            </ul>
            <h2>01.17.2020</h2>
            <ul>
              <li className="mb-1">Updated TFS Calculation.  No longer dependent on all core indicators being present.</li>
              <li className="mb-1">Updated US Adult Recommendations on Wellness Repoort</li>
            </ul>
            <h2>12.16.2019</h2>
            <ul>
              <li className="mb-1">Created Adult Overall Survey Report Template for US and UK</li>
              <li className="mb-1">Added additional data to the Notification List Download Data</li>
              <li className="mb-1">Corrected Issues with Timezones and Wellness Center login</li>
              <li className="mb-1">Minor edits to Parent/Individual Adult Report Templates for US and UK</li>
            </ul>
            <h2>12.05.2019</h2>
            <ul>
              <li className="mb-1">Updates to Adult Survey Creation and Demographics</li>
            </ul>
            <h2>11.27.2019</h2>
            <ul>

              <li className="mb-1">Updated UK and US Adult report verbiage</li>
              <li className="mb-1">Fixed resource links</li>
            </ul>
            <h2>11.15.2019</h2>
            <ul>
              <li className="mb-1">Minor bug fixes and performance enhancements.</li>
            </ul>
            <h2>11.12.2019</h2>
            <ul>
              <li className="mb-1">Adult Parent Report: Added Adult Video links.</li>
              <li className="mb-1">Student results are now grouped into categories (by at risk).</li>
              <li className="mb-1">Schools are now able to get an excel file that lists the mean scores for all students across indicators.</li>
              <li className="mb-1">Added a way to retrieve non-indicator information in the system.</li>
              <li className="mb-1">AWS Upgrade: Upgraded to Node v10 across the codebase</li>
              <li className="mb-1">Survey: A message to retry submission is now displayed if the survey submission times out. The submission will automatically retry if the message is not acknowledged.</li>
              <li className="mb-1">Student Report: Added print button</li>
              <li className="mb-1">Adult Report: Suggestions for Improving At Risk and Moderate Concern Scores - changed Ostracism and Trauma verbiage.</li>
              <li className="mb-1">Student and Parent reports now display when the survey validity checks fail.</li>
              <li className="mb-1">Notification messages (blue message at top of window) now close automatically after 5 seconds.</li>
              <li className="mb-1">Fixed an issue with phone numbers when creating a school with at least one school champion.</li>
            </ul>
            <h2>11.04.2019</h2>
            <ul>
              <li className="mb-1">Added Student ID to Notification Download .csv</li>
              <li className="mb-1">Fixed Bardstown School report not displaying the correct data.</li>
            </ul>
            <h2>10.29.2019</h2>
            <ul>
              <li className="mb-1">Survey: Backwards Compatibility - The schools can use the old survey codes to take the Survey. These surveys will not have the Read to Me or Spanish functionality.</li>
              <li className="mb-1">Older School Reports will continue to calculate the Distribution on Risk Variables for Getting Bullied (at least once a week) the old way. The old way is if the student answers 1-2 times a month or almost every day then it will be in the % calculation. School Reports going forward will calculate the Distribution on Risk Variables for Getting Bullied (at least once a week) the new way. The new way is if the student is AT RISK in the Getting Bullied category then it will be included in % calculation.</li>
              <li className="mb-1">Download Notification List does not have notify email. Surveys created before the Wellness Center was implemented 9/30/19 will not have a parent code because the parent code did not exist.</li>
              <li className="mb-1">Fixed rare situation where School Reports were not created when 2 students completed and 2 students NOT Complete</li>
              <li className="mb-1">Fixed School Report with 1 student resulting in zero mean scores</li>
            </ul>
            <h2>10.28.2019</h2>
            <ul>
              <li className="mb-1">Participant and Wellness Center password format updated</li>
              <li className="mb-1">Profession demographic questions added to Adult Surveys</li>
              <li className="mb-1">Profession questions and responses added to student report</li>
              <li className="mb-1">Adults can now send survey results via email to a trusted address</li>
              <li className="mb-1">First time login process simplified</li>
              <li className="mb-1">Remove previously selected indicators when changing survey types</li>
              <li className="mb-1">Notification List Download - Retrieve a list of Wellness Codes and links for manual email sends</li>
              <li className="mb-1">Updated Date of Birth format for UK</li>
              <li className="mb-1">Updated various screens with UK terminology (Term and Year)</li>
            </ul>
            <h2>10.07.2019</h2>
            <ul>
              <li className="mb-1">UK Environment and Content launched</li>
              <li className="mb-1">Accommodations for international phone number format</li>
              <li className="mb-1">UK region Read to Me enabled</li>
              <li className="mb-1">UK roster format updates</li>
            </ul>
            <h2>10.04.2019</h2>
            <ul>
              <li className="mb-1">Hotfix for display of css styling in survey question text.</li>
            </ul>
            <h2>09.24.2019</h2>
            <ul>
              <li className="mb-1">Content matrix release; survey now supports multiple languages and locales</li>
              <li className="mb-1">Read-to-me functionality; survey questions and responses now incorporate functionality of being read aloud through your audio device</li>
              <li className="mb-1">Adult survey questions</li>
              <li className="mb-1">Allow CSV export of survey roster with an additional headers allowing distribution of survey easier</li>
              <li className="mb-1">Wellness-center/parent portal; survey roster now includes notifyMobile and notifyEmail headers so schools can distrubute participant results to parents more easily</li>
            </ul>
            <h2>09.09.2019</h2>
            <ul>
              <li className="mb-1">Hotfix roster filenames so they can include spaces</li>
            </ul>
            <h2>08.26.2019</h2>
            <ul>
              <li className="mb-1">District query report for tm_admins</li>
              <li className="mb-1">DoB invalid date fix</li>
            </ul>
            <h2>06.27.2019</h2>
            <ul>
              <li className="mb-1">Original launch of Terrace Metrics Survey</li>
              <li className="mb-1">Core and Supplementary questions launched</li>
            </ul>
          </div>
        </div>

      </div>
    );
  }
}

export default connect()(withRouter(Changelog));
