import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../../store/actions';

class CreateDistrictFormWrapper extends Component {
  render() {
    return (
      <>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.organizations.error,
  };
};

export default connect(
  mapStateToProps
)(CreateDistrictFormWrapper);
