import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../../store/actions';
import { getLogo } from '../../../../assets/helper';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

class CleverLogin extends Component {
  componentDidMount() {
    const code = queryString.parse(this.props.location.search).code;
    console.log('query string: ', this.props);
    let url = `${REACT_APP_API_URL}/user/clever/${code}`;
    const result = axios({
      url,
      method: 'get',
    })
      .then(response => {
        localStorage.setItem('cleverUserData', JSON.stringify(response.data.user));
        console.log('response.data.user', response.data);
        const user = response.data.user;
        // Check for student first
        if (user && user.isParticipant === true) {
          if (!user.surveyParticipants) {
            // No participant located
            this.props.history.push('/survey');
          }

          this.props.history.push(`/survey?accessCode=${user.surveyParticipants[0].participantSurveyAuthCode}`)
        }

        // Only for Admins
        this.props.loginWithClever(response.data.user);
      })
      .catch(error => {
        console.error(error);
      });
  }
  render() {
    const { error } = this.props;
    if (this.props.redirectFromLogIn) {
      return <Redirect to="/admin" />;
    }

    return (
      <div className="pageContainer">
        <img className="logo-login" alt="TM Logo" src={getLogo()} />
        <p className="error">{error && Object.keys(error).includes('message') ? error.message : null}</p>

        <p>Please wait we are logging in with your Clever credentials</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    redirectFromLogIn: state.auth.redirectFromLogIn,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginWithClever: cleverData => dispatch(actions.loginWithClever(cleverData)),
    changePassword: (newPassword, cb) => dispatch(actions.changePassword(newPassword, cb)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CleverLogin));
