import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  isLoading: true,
  report: null,
  selectedRelatedReport: null,
  selectedRelatedReportIsLoading: false,
  selectedRelatedReportError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OVERALL_SURVEY_REPORT_START:
      return update(state, {
        isLoading: { $set: true },
        report: { $set: null },
        error: { $set: null },
      });
    case actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_START:
      return update(state, {
        selectedRelatedReportIsLoading: { $set: true },
        selectedRelatedReport: { $set: null },
        selectedRelatedReportError: { $set: null },
      });
    case actionTypes.GET_OVERALL_SURVEY_REPORT_FAIL:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error },
        report: { $set: null },
      });
    case actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_FAIL:
      return update(state, {
        selectedRelatedReportIsLoading: { $set: false },
        selectedRelatedReportError: { $set: action.error },
        selectedRelatedReport: { $set: null },
      });
    case actionTypes.GET_OVERALL_SURVEY_REPORT_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: null },
        report: { $set: action.report },
      });
    case actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_SUCCESS:
      return update(state, {
        selectedRelatedReportIsLoading: { $set: false },
        selectedRelatedReport: { $set: action.selectedRelatedReport },
        selectedRelatedReportError: { $set: null },
      });
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
