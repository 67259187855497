import React from 'react';
import { Field } from 'redux-form';
import Delete from '@material-ui/icons/Delete';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import displayPhoneNumber from '../../../../../utilities/displayPhoneNumber';

const SchoolChampionFields = (props) => {
  return (
    <div className="mb-4 sibling-border-top">
      {
        props.searchField ? props.searchField : null
      }
      <div className="d-flex align-items-end mb-2">
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`firstName-${props.index}`}
          >
            Champion Name
          </label>
          <Field
            name={`firstName-${props.index}`}
            placeholder="First name"
            type="text"
            component="input"
            className="participant-entry-field"
            disabled={props.disabled}
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <Field
            name={`lastName-${props.index}`}
            placeholder="Last name"
            type="text"
            component="input"
            className="participant-entry-field"
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`email-${props.index}`}
          >
            Champion Email
          </label>
          <Field
            name={`email-${props.index}`}
            placeholder=""
            type="text"
            component="input"
            className="participant-entry-field"
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`phoneNumber-${props.index}`}
          >
            Champion Phone
          </label>
          <Field
            name={`phoneNumber-${props.index}`}
            type="tel"
            component={RenderPhoneField}
            className='participant-entry-field'
          />
        </div>
        <div className="label-field-container icon">
          <button
            onClick={(e) => props.removeChampion(e, props.index)}
            className="btn m-0 p-2"
          >
            <Delete className="blue-fill-icon pointer-events-none m-0" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchoolChampionFields;
