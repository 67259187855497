import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// import HOCS
import withLayout from '../../hoc/Layout/SurveyLayout';
import isSurveyReady from '../../hoc/isSurveyReady/isSurveyReady';

// Import route components
import SurveyAccess from './SurveyAccess/SurveyAccess';
import SurveyIntro from './SurveyIntro/SurveyIntro';
import ParticipantInfoConfirmation from './ParticipantInfoConfirmation/ParticipantInfoConfirmation';
import AdultConsentConfirmation from './AdultConsentConfirmation/AdultConsentConfirmation';
import DemographicQuestions from './DemographicQuestions/DemographicQuestions';
import SurveySubmitting from './SurveySubmitting/SurveySubmitting';
import SurveyComplete from './SurveyComplete/SurveyComplete';
import SurveyQuestions from './SurveyQuestions/SurveyQuestions';
import SurveyEmailResults from './SurveyEmailResults/SurveyEmailResults';

// Errors
import Error404 from '../Errors/Error404';

import GroupReport from '../Admin/Reports/OverallSurvey/OverallSurveyReport';

class Surveys extends Component {
  render() {
    return (
      <Switch>
        {/*
          USAGE EXAMPLE FOR isAuthorized:
          <Route path='/studentsection' component={isAuthorized(isAuthenticated(withLayout(StudentSection)), 'USER')} />
          <Route path="/admin" component={isAuthenticated(withLayout(Admin, true), { userType: 'user' })} />
        */}
        <Route path="/survey" exact component={withLayout(SurveyAccess, { showLogo: false })} />
        <Route path="/survey/intro" component={isSurveyReady(withLayout(SurveyIntro, { showLogo: false }))} />
        <Route path="/survey/verify" component={isSurveyReady(withLayout(ParticipantInfoConfirmation, { showLogo: true, showLang: true }))} />
        <Route path="/survey/consent" component={isSurveyReady(withLayout(AdultConsentConfirmation, { showLogo: true, showLang: true }))} />
        <Route path="/survey/demographics" component={isSurveyReady(withLayout(DemographicQuestions, { showLogo: true, showLang: true }))} /> {/* Demographic Questions */}
        <Route path="/survey/questions" component={isSurveyReady(withLayout(SurveyQuestions, { showLogo: true, showLang: true }))} />
        <Route path="/survey/submitting" component={isSurveyReady(withLayout(SurveySubmitting))} />
        <Route path="/survey/email-results" component={isSurveyReady(withLayout(SurveyEmailResults, { showLogo: true, showLang: true }))} />
        <Route path="/survey/complete" component={withLayout(SurveyComplete)} />
        {/* Hacked Route for Group Survey */}
        <Route path="/survey/reports/group" component={GroupReport} />
        <Route component={withLayout(Error404, { showLogo: true, showContact: true, showUser: true, showLang: false })} />
      </Switch>
    );
  }
}

export default Surveys;
