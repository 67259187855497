import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { json2csv } from 'json-2-csv';
import * as actions from '../../../../store/actions';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Button from 'react-bootstrap/Button';
import DropdownButton from '../../../../components/DropdownButton/DropdownButton';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DescriptionIcon from '@material-ui/icons/Description';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import { ReactComponent as EmailSuccessIcon } from '../../../../assets/imgs/icon_notification_email_success.svg';
import { ReactComponent as InviteSuccessIcon } from '../../../../assets/imgs/icon_invitation_email_success.svg';
import { ReactComponent as EmailFailIcon } from '../../../../assets/imgs/icon_notification_email_error.svg';
import { ReactComponent as TextSuccessIcon } from '../../../../assets/imgs/icon_notification_sms_success.svg';
import { ReactComponent as TextFailIcon } from '../../../../assets/imgs/icon_notification_sms_error.svg';
import { ReactComponent as EmailUnsent } from '../../../../assets/imgs/icon_notification_email_standard.svg';
import { ReactComponent as TextUnsent } from '../../../../assets/imgs/icon_notification_sms_standard.svg';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import { getContent } from '../../../../utilities/getContent/getContent';
import { adjustDate, dateFormat } from '../../../../utilities/dates';
import activateItem from '../../../../utilities/activateItem';
//await activateItem(surveyParticipant, this.props.idToken, 'surveyParticipant');
import Timegate from './Timegate/Timegate';

class SurveyDetails extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    //this.props.clearCreateSurveyState();

    if (this.props.location.state.surveyId && this.props.surveyDetails) {
      if (this.props.location.state.surveyId !== this.props.surveyDetails.surveyId) {
        this.props.getSurvey(this.props.location.state.surveyId);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCreateSurveyState();
  }

  isClever = () => {
    // account for tm_admin whose org would not be Clever
    //if (this.props.role === 'tm_admin') {
    //console.log('survey details is tmAdmin');
    if (this.props.surveyDetails && this.props.surveyDetails.participants && this.props.surveyDetails.participants.length > 0) {
      return this.props.surveyDetails.participants[0].id || this.props.surveyDetails.participants[0].cleverData;
    }
    //}

    if (this.props.organization) {
      console.log('isClever Survey details 2: ', this.props.organization);
      return !!this.props.organization.cleverData || !!this.props.organization.cleverItem;
    }

    return false;
  };

  setTimegateForSelectedSurveyParticipants = async () => {
    const { timestampsByGrade } = this.props;
    console.log('timestampsByGrade in setTimegateForSeleected: ', timestampsByGrade);
    let studentList = [];
    this.props.grades.map(grade => {
      const activationDate = timestampsByGrade[grade];
      const students = this.props.studentsByGrade[grade].map(item => {
        item.timegate = activationDate; // moment.utc(activationDate).unix();
        console.log('item.timegate: ', item.timegate);
        return item;
      });
      studentList = studentList.concat(students);
    });

    console.log('studentList: ', studentList);

    this.setState({ loading: true });
    await activateItem(studentList, this.props.idToken, 'surveyParticipant');

    this.setState({ loading: false });
  };

  onGradeTimegateChange = (grade, value) => {
    console.log('ONGRADETIMEGATE CHANGE', value);
    const newTimestampsByGrade = { ...this.props.timestampsByGrade };
    newTimestampsByGrade[grade] = moment.utc(value).unix();
    console.log('newTimestampsByGrade: ', newTimestampsByGrade);
    console.log(' this.props.setTimestampsByGrade', this.props.setTimestampsByGrade);
    this.props.setTimestampsByGrade(newTimestampsByGrade);
  };

  constructGrades = () => {
    const { timestampsByGrade } = this.props;
    console.log('CONSTRUCT Grades: ', timestampsByGrade);

    return this.props.grades.map(grade => {
      const milliseconds = timestampsByGrade[grade] ? timestampsByGrade[grade] * 1000 : null;
      console.log('CONSTRUCT GRADES STORED VALUE: ', new Date(milliseconds));
      return {
        label: grade,
        value: milliseconds ? new Date(milliseconds) : new Date(),
        //value: new Date(),
        onChange: this.onGradeTimegateChange,
      };
    });
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  // Participant Delete handler
  deleteItemHandler = item => {
    console.log('Delete Item handler - item ID: ', item);
    const { surveyParticipantId } = item;
    this.openDeleteParticipantConfirmationModal(surveyParticipantId, this.props.location.state.surveyId);
  };

  openDeleteParticipantConfirmationModal = (surveyParticipantId, surveyId) => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button type="button" className="small-button green" size="lg" onClick={() => this.props.deleteParticipantFromSurvey(surveyParticipantId, surveyId)}>
            Delete Participant
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };

  openDeleteSurveyConfirmationModal = () => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button
            type="button"
            className="small-button green"
            size="lg"
            id="locator_confirm-delete-survey-button"
            onClick={() => this.props.deleteSurvey(this.props.surveyDetails.surveyId, this.redirectToSurveyList)}
          >
            Delete Assessment
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };
  
  stayHere = (surveyId, callback) => {
    console.log("stayhere: ", surveyId);
    this.props.validateSurvey(surveyId, null);// () => window.location.reload(false));
  };

  openCloseSurveyConfirmationModal = () => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Continuing this action will validate the status of the current participants.</p>
          <p>If all participants have "completed" the assessment, the assessment will be closed.</p>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button
            type="button"
            className="small-button green"
            size="lg"
            id="locator_confirm-delete-survey-button"
            // () => {
            //   this.exportCSVFile(
            //     (this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName) + '-participants',
            //   );
            // }
            onClick={() => this.stayHere(this.props.surveyDetails.surveyId, this.props.closeModal)} //change this to match 'download notify'
          >
            Validate & Close
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };
  addParticipantHandler = () => {
    const { surveyId, orgId } = this.props.surveyDetails;
    this.props.openModal({
      // surveyId: this.props.location.state.surveyId,
      surveyId,
      orgId,
      modalType: 'ADD_PARTICIPANT_MODAL',
      show: true,
      surveyName: this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName,
      closeModal: this.props.closeModal,
    });
  };

  surveyStatusModal = () => {
    const { surveyStatus } = this.props.surveyDetails;
    console.log(surveyStatus);
    this.props.openModal({
      show: surveyStatus === 'Adding Students',
      modalType: 'SURVEY_STATUS_MODAL',
      surveyStatus: surveyStatus,
    });
  };

  editParticipantHandler = item => {
    console.log('Edit Participant Handler: ', item);
    this.props.openModal({
      modalType: 'EDIT_PARTICIPANT_MODAL',
      show: true,
      item: item,
      isEmployerAdmin: this.isEmployerAdmin(),
      surveyId: this.props.location.state.surveyId,
      surveyStatus: this.props.surveyDetails.surveyStatus,
      surveyName: this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName,
      closeModal: this.props.closeModal,
    });
  };

  notifyParentReportHandler = item => {
    console.log('Notify Parent Report Handler: ', item);
    this.props.openModal({
      modalType: 'NOTIFY_PARENT_MODAL',
      show: true,
      item: item,
      surveyId: this.props.location.state.surveyId,
      surveyStatus: this.props.surveyDetails.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };

  notifyHandler = item => {
    console.log('Notify  Handler: ', item);
    this.props.openModal({
      modalType: 'NOTIFY_MODAL',
      show: true,
      item: item,
      surveyId: this.props.location.state.surveyId,
      surveyStatus: this.props.surveyDetails.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };

  notificationLogHandler = async item => {
    await this.props.getNotificationLog(item.surveyParticipantId, this.props.location.state.surveyId);
    this.props.openModal({
      modalType: 'NOTIFY_HISTORY_MODAL',
      show: true,
      item: item,
      surveyId: this.props.location.state.surveyId,
      surveyStatus: this.props.surveyDetails.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };

  bulkEditParticipantHandler = () => {
    this.props.openModal({
      modalType: 'BULK_EDIT_PARTICIPANT_MODAL',
      participants: this.props.surveyDetails.participants,
      surveyId: this.props.location.state.surveyId,
      show: true,
      closeModal: this.props.closeModal,
    });
  };

  bulkDeleteParticipantHandler = () => {
    this.props.openModal({
      modalType: 'BULK_DELETE_PARTICIPANT_MODAL',
      participants: this.props.surveyDetails.participants,
      surveyId: this.props.location.state.surveyId,
      show: true,
      closeModal: this.props.closeModal,
    });
  };

  bulkNotificationHandler = () => {
    this.props.openModal({
      modalType: 'BULK_NOTIFY_PARENT_MODAL',
      show: true,
      surveyId: this.props.location.state.surveyId,
      surveyStatus: this.props.surveyDetails.surveyStatus,
      closeModal: this.props.closeModal,
    });
  };
  
  bulkPdfHandler = () => {
    this.props.openModal({
      modalType: 'BULK_PDF_MODAL',
      participants: this.props.surveyDetails.participants,
      surveyId: this.props.location.state.surveyId,
      show: true,
      closeModal: this.props.closeModal,
    });
  };

  bulkInviteHandler = () => {
    this.props.openModal({
      modalType: 'BULK_INVITE_MODAL',
      participants: this.props.surveyDetails.participants,
      surveyId: this.props.location.state.surveyId,
      show: true,
      closeModal: this.props.closeModal,
    });
  };

  printStudentList = () => {
    window.print();
  };
  getReportIcon = (num, item) => {
    return (
      <Link
        className="button-text report-link"
        to={{
          pathname: `/admin/reports/student?pId=${item.surveyParticipantId}`,
        }}
        target="_blank"
      >
        <span className="report-icon" /*data-num={num > 0 ? num : null}*/ data-contacted={item.contacted}>
          <ReportIcon />
        </span>
      </Link>
    );
  };

  getContextMenu = item => {
    return (
      <div className="dropdown">
        <div className="small-gray-button-outlined context-menu">...</div>
        <div className="dropdown-content">
          <div>
            <span onClick={() => this.editParticipantHandler(item)}>
              <Edit className="blue-fill-icon" /> Edit
            </span>
            {this.props.surveyDetails.surveyStatus === 'Created' ? (
              <span onClick={() => this.deleteItemHandler(item)}>
                <Delete className="blue-fill-icon" />
                Delete
              </span>
            ) : null}
            {item.participantStatus === 'Completed' ? (
              item.parentEmail || item.participantEmail || item.parentMobile ? (
                item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
                  <span onClick={() => this.notifyParentReportHandler(item)}>
                    <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                    Resend Notification
                  </span>
                ) : (
                  <span onClick={() => this.notifyParentReportHandler(item)}>
                    <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                    Send Notification
                  </span>
                )
              ) : null
            ) : null}
            {item.participantStatus !== 'Completed' ? (
              item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
                <span onClick={() => this.notifyHandler(item)}>
                  <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                  Resend Invite
                </span>
              ) : (
                <span onClick={() => this.notifyHandler(item)}>
                  <NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
                  Send Invite
                </span>
              )
            ) : null}

            {item.emailStatus ? (
              <span onClick={() => this.notificationLogHandler(item)}>
                <DescriptionIcon className="blue-fill-icon" onClick={() => {}} />
                Notification History
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  generateTableData = () => {
    // Waits for survey details to be populated
    if (this.props.surveyDetails && this.props.surveyDetails.participants && this.props.surveyDetails.participants.length > 0) {
      if (this.props.surveyDetails.LastEvaluatedKey && this.props.surveyDetails.LastEvaluatedKey.surveyParticipantId) {
        this.setState({ nextKey: this.props.surveyDetails.LastEvaluatedKey.surveyParticipantId, next: true });
      }
      const isAdult = this.props.surveyDetails.surveyName.match(/Adult/i) === null;
      const gradeText = isAdult ? getContent('admin_participant-entry-grade') : getContent('admin_participant-entry-year');
      return this.props.surveyDetails.participants.map(item => {
        return {
          name:
            item.participantStatus !== 'Completed' || this.isEmployerAdmin() ? (
              <span>
                {`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
                <div className="grade-label">
                  {gradeText}: {item.grade}
                </div>
              </span>
            ) : (
              <span>
                  {`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
                <div className="grade-label">
                  {gradeText}: {item.grade}
                </div>
              </span>
            ),
          // grade: item.grade,
          DOB: adjustDate(item.birthDate).format(dateFormat),
          login: this.isEmployerAdmin() ? null : item.participantSurveyAuthCode.length === 16 ? (
            <span id="locator_survey-participant-code">{item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')}</span>
          ) : (
            <span id="locator_survey-participant-code">{item.participantSurveyAuthCode}</span>
          ),
          status: item.participantStatus,
          result: item.participantStatus !== 'Completed' || this.isEmployerAdmin() ? <span>-</span> : this.getReportIcon(item.priority, item),
          invite:
            item.parentEmail || item.participantEmail ? (
              item.parentEmail || item.participantEmail ? (
                item.inviteStatus && item.inviteStatus === 'Delivery' ? (
                  <InviteSuccessIcon />
                ) : item.inviteStatus === 'Bounce' ? (
                  <EmailFailIcon />
                ) : (
                  <EmailUnsent />
                )
              ) : (
                '-'
              )
            ) : (
              <span>{'-'}</span>
            ),
          notification:
            item.parentEmail || item.parentMobile || item.participantEmail ? (
              item.participantStatus === 'Completed' ? (
                <span className="report-icon">
                  {item.parentEmail || item.participantEmail ? (
                    item.emailStatus && item.emailStatus === 'Delivery' ? (
                      <EmailSuccessIcon />
                    ) : item.emailStatus === 'Bounce' ? (
                      <EmailFailIcon />
                    ) : (
                      <EmailUnsent />
                    )
                  ) : (
                    ''
                  )}
                  {item.parentMobile ? (
                    item.textStatus && item.textStatus === 'Delivery' ? (
                      <TextSuccessIcon />
                    ) : item.parentMobile && (!item.textStatus || item.textStatus === 'None') ? (
                      <TextUnsent />
                    ) : (
                      <TextFailIcon />
                    )
                  ) : (
                    ''
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            ) : (
              <span>-</span>
            ),
          menu: this.getContextMenu(item),
        };
      });
    }

    return [];
  };

  redirectToStudentReport = participantId => {
    this.props.history.push({
      pathname: '/admin/reports/student',
      state: {
        participantId,
      },
    });
  };

  redirectToParentReport = participantId => {
    this.props.history.push({
      pathname: '/admin/reports/parent',
      state: {
        participantId,
      },
    });
  };

  getBreadcrumbs = () => {
    return [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: 'Assessments',
      },
      {
        key: 3,
        url: '/admin/survey/details',
        label:
          this.props.surveyDetails && this.props.surveyDetails.surveyName
            ? this.isUK()
              ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary')
              : this.props.surveyDetails.surveyName
            : '',
      },
    ];
  };

  redirectToSurveyList = () => {
    this.props.history.push('/admin/survey/list');
  };

  exportCSVFile = async fileTitle => {
    await this.props.getAllParticipantData(this.props.location.state.surveyId);
    if (this.props && this.props.surveyParticipants) {
      console.log(this.props.surveyDetails.surveyName);
      const isAdult = this.props.surveyDetails.surveyName.match(/Adult/i) !== null;
      const gradeText = isAdult ? getContent('admin_participant-entry-year') : getContent('admin_participant-entry-grade');
      const items = this.props.surveyParticipants;
      const filteredItems = items.map(item => {
        return {
          'Auth Code': item.participantSurveyAuthCode.length === 16 ? item.participantSurveyAuthCode.match(/.{1,4}/g).join('-') : item.participantSurveyAuthCode,
          [gradeText]: item.grade,
          'First Name': item.firstName,
          'Last Name': item.lastName,
          'Middle Name': item.middleName,
          'Birth Date': adjustDate(item.birthDate).format(dateFormat),
          'Group Name': item.groupName,
          'Participant Email': item.participantEmail,
        };
      });
      await json2csv(
        filteredItems,
        (err, csv) => {
          let exportedFilename = fileTitle + '.csv' || 'export.csv';
          var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
          } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', exportedFilename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        },
        { emptyFieldValue: '' },
      );
    }
  };
  isEmployerAdmin = () => this.props.parentOrg && this.props.parentOrg.employerProvider && this.props.parentOrg.employerProvider === 'Employer' && this.props.realRole === 'district_champion';

  exportNotificationCSVFile = async fileTitle => {
    let exportedFilename = fileTitle + '.csv' || 'export.csv';
    await this.props.getAllNotificationData(this.props.location.state.surveyId);
    console.log('New Download URL: ', this.props.surveyNotificationData);
    let link = document.createElement('a');
    link.setAttribute('href', this.props.surveyNotificationData.url);
    link.setAttribute('type', 'application/csv');
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  getDropdownButtonList = () => {
    const dropdownItems = [
      ...(this.props.surveyDetails.surveyStatus === 'Completed'
        ? [
            {
              label: 'Bulk Notification',
              onClick: this.bulkNotificationHandler,
            },
            {
              label: 'Bulk Download Parent Report PDFs',
              onClick: this.bulkPdfHandler,
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus === 'Created'
        ? [
            {
              label: 'Bulk Delete Participant',
              onClick: this.bulkDeleteParticipantHandler,
            },
            {
              label: 'Bulk Invite Participants',
              onClick: this.bulkInviteHandler,
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus === 'In Progress'
        ? [
            {
              label: 'Bulk Edit Participant',
              onClick: this.bulkEditParticipantHandler,
            },
            {
              label: 'Bulk Invite Participants',
              onClick: this.bulkInviteHandler,
            },
            {
              label: 'Bulk Download Parent Report PDFs',
              onClick: this.bulkPdfHandler,
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus === 'In Progress' && !this.isEmployerAdmin()
        ? [
            {
              label: 'Download Participant List',
              onClick: () => {
                this.exportCSVFile(
                  (this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName) + '-participants',
                );
              },
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus === 'Created' && !this.isEmployerAdmin()
        ? [
            {
              label: 'Download Participant List',
              onClick: () => {
                this.exportCSVFile(
                  (this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName) + '-participants',
                );
              },
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus === 'Completed' && !this.isEmployerAdmin()
        ? [
            {
              label: 'Download Participant List',
              onClick: () => {
                this.exportCSVFile(
                  (this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName) + '-participants',
                );
              },
            },
          ]
        : []),
      ...(this.props.surveyDetails.surveyStatus !== 'Completed'
        ? [
            {
              label: 'Add Participant',
              onClick: this.addParticipantHandler,
            },
          ]
        : []),
      {
        label: 'Download Notification List',
        onClick: () => {
          this.exportNotificationCSVFile(
            (this.isUK() ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : this.props.surveyDetails.surveyName) + '-notifications',
          );
        },
      },
    ]
      .filter(Boolean)
      .filter(el => !Array.isArray(el));
    console.log('dropdownItems', dropdownItems);
    return dropdownItems;
  };

  render() {
    console.log('|| this.props.surveyDetails.participants.length', this.props.surveyDetails);
    console.log('parentOrg', this.props.parentOrg);
    console.log(this.getDropdownButtonList());
    const showParticipantInformation =
      this.props.realRole === 'school_champion' || this.props.realRole === 'district_champion' || (this.props.parentOrg && this.props.parentOrg.isTMAllowed && this.props.realRole === 'tm_admin');

    console.log('participantInformation', showParticipantInformation);
    if (this.props.surveyDetails && this.props.surveyDetails.surveyStatus && this.props.surveyDetails.surveyStatus === 'Adding Students') {
      this.surveyStatusModal();
    }

    return this.props.isLoading ? (
      <Backdrop>
        <Spinner />
      </Backdrop>
    ) : (
      <div className="create-survey-container">
        <div className="sub-header d-print-none">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
          <div>
          {this.props.surveyDetails.surveyStatus !== 'Completed' ? (
            <Button onClick={() => this.openDeleteSurveyConfirmationModal()} className="small-blue-button-outlined" id="locator_delete-survey-button">
              Delete Assessment
            </Button>
          ) : null}
        {/* </div>
        <div className="sub-header d-print-none"> */}
          {this.props.surveyDetails.surveyStatus !== 'Completed' ? (
            <Button onClick={() => this.openCloseSurveyConfirmationModal()} className="small-blue-button" id="locator_close-survey-button">
              Validate & Close
            </Button>
          ) : null}
          </div>
        </div>
        <div className="survey-info flex-row d-print-none">
          <div>
            <strong>Assessment Name</strong>
            <p>
              {this.props.surveyDetails
                ? this.isUK() && this.props.surveyDetails.surveyName
                  ? this.props.surveyDetails.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary')
                  : this.props.surveyDetails.surveyName
                : ''}
            </p>
          </div>
          <div>
            <strong>Assessment Status</strong>
            <p>{this.props.surveyDetails ? this.props.surveyDetails.surveyStatus : ''}</p>
          </div>
        </div>
        <div className="d-print-none">
          <strong>Assessment Questions</strong>
          <div>
            <ul>
              {this.props.surveyDetails && this.props.surveyDetails.indicators
                ? this.props.surveyDetails.indicators.map(item => {
                    return (
                      <li key={item}>
                        {this.isUK()
                          ? item
                              .replace('Global Satisfaction', 'Happiness')
                              .replace('Ostracism', 'Social Exclusion')
                              .replace('Grit', 'Tenacity')
                              .replace('Intimate Partner Violence', 'Domestic Violence')
                          : item}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
        <div className="sub-header d-print-none">
          <p className="mb-0">{`Participant List Size - ${this.props.surveyDetails &&
            (this.props.surveyDetails.count || (this.props.surveyDetails.participants && this.props.surveyDetails.participants.length) || 0)} Participants`}</p>
          {showParticipantInformation ? (
            <div className="survey-list-button-header-container">
              <DropdownButton items={this.getDropdownButtonList()} label={`Options`} className="surveyDetailsDropDownButton" />
            </div>
          ) : null}
        </div>
        <div className="mb-0 statusCountText d-print-none">
          {this.props.surveyDetails && this.props.surveyDetails.statusCounts
            ? `Not Started: ${this.props.surveyDetails.statusCounts.notStarted} \u00A0 Not Completed: ${this.props.surveyDetails.statusCounts.notCompleted} \u00A0 Completed: ${this.props.surveyDetails.statusCounts.completed}`
            : null}
        </div>
        {/* {
            this.isClever ? <TimeGate grades={this.constructGrades} save={this.setTimegateForSelectedSurveyParticipants} /> : null
          } */}
        {this.isClever() ? <Timegate grades={this.constructGrades()} save={this.setTimegateForSelectedSurveyParticipants} isLoading={this.state.loading} /> : null}
        {showParticipantInformation ? (
          <TableGenerator
            pagination={{
              next: this.props.surveyDetails.lastEvaluatedKey,
              previous: this.props.surveyDetails.pageNumber - 1 >= 0 && this.props.surveyDetails.currentExclusiveStartKey && this.props.surveyDetails.currentExclusiveStartKey.surveyParticipantId,
            }}
            getPrevious={() => {
              const { currentExclusiveStartKey } = this.props.surveyDetails;
              let lastKey;
              let sortKey;
              let pageNumber = this.props.surveyDetails.pageNumber - 1;
              console.log('pageNumber: ', pageNumber);

              if (currentExclusiveStartKey && currentExclusiveStartKey.surveyParticipantId && currentExclusiveStartKey['surveyParticipant-sortKey']) {
                if (!this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1].currentExclusiveStartKey) {
                  lastKey = null;
                  sortKey = null;
                } else {
                  lastKey = this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1].currentExclusiveStartKey.surveyParticipantId;
                  sortKey = this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1].currentExclusiveStartKey['surveyParticipant-sortKey'];
                }
              }
              // this.searchSubmissionHandler(lastKey, sortKey, pageNumber);
              this.props.getSurvey(this.props.location.state.surveyId, lastKey, sortKey, pageNumber);
            }}
            getNext={() => {
              const { lastEvaluatedKey } = this.props.surveyDetails;
              let lastKey;
              let sortKey;
              if (lastEvaluatedKey && lastEvaluatedKey.surveyParticipantId && lastEvaluatedKey['surveyParticipant-sortKey']) {
                lastKey = lastEvaluatedKey.surveyParticipantId;
                sortKey = lastEvaluatedKey['surveyParticipant-sortKey'];
              }
              this.props.getSurvey(this.props.location.state.surveyId, lastKey, sortKey, this.props.surveyDetails.pageNumber + 1);
            }}
            config={{
              headers: ['PARTICIPANT', 'DATE OF BIRTH', 'LOGIN', 'STATUS', 'RESULT', 'INVITATION', 'NOTIFICATIONS', ''],
              contentRows: this.generateTableData(),
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('SurveyDetails State: ', state);
  return {
    isLoading: state.surveyCrud.isLoading,
    surveyDetails: state.surveyCrud.surveyDetails,
    surveyParticipants: state.surveyCrud.surveyParticipants,
    surveyNotificationData: state.surveyCrud.surveyNotificationData,
    surveyNotificationResponses: state.surveyCrud.surveyNotificationResponses,
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg ? state.organizations.organization.parentOrg : state.organizations.organization,
    role: state.auth.role,
    realRole: state.auth.realRole,
    idToken: state.auth.idToken,
    grades: state.surveyCrud.surveyDetails.grades,
    studentsByGrade: state.surveyCrud.surveyDetails.studentsByGrade,
    timestampsByGrade: state.surveyCrud.surveyDetails.timestampsByGrade,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    getSurvey: (surveyId, direction, lastKey, sortKey) => dispatch(actions.getSurvey(surveyId, direction, lastKey, sortKey)),
    deleteSurvey: (surveyId, cb) => dispatch(actions.deleteSurvey(surveyId, cb)),
    validateSurvey: (surveyId, cb) => dispatch(actions.validateSurvey(surveyId, cb)),
    clearSurveyDetails: () => dispatch(actions.clearSurveyDetails()),
    deleteParticipantFromSurvey: (participantId, surveyId) => dispatch(actions.deleteParticipantFromSurvey(participantId, surveyId)),
    getAllParticipantData: surveyId => dispatch(actions.getAllParticipantData(surveyId)),
    getAllNotificationData: surveyId => dispatch(actions.getAllNotificationData(surveyId)),
    getAllNotificationResponses: surveyId => dispatch(actions.getAllNotificationResponses(surveyId)),
    getNotificationLog: (participantId, surveyId) => dispatch(actions.getNotificationLog(participantId, surveyId)),
    clearCreateSurveyState: () => dispatch(actions.clearCreateSurveyState()),
    toggleGradeForSurvey: grade => dispatch(actions.toggleGradeForSurvey(grade)),
    setStudentsByGrade: students => dispatch(actions.setStudentsByGrade(students)),
    setTimestampsByGrade: timestampsByGrade => dispatch(actions.setTimestampsByGrade(timestampsByGrade)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyDetails));
