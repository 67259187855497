import React from 'react';
import { withRouter } from 'react-router-dom';
// import parse from 'html-react-parser';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';

import TMCard from '../../../components/TMCard/TMCard';
import TMButton from '../../../components/TMButton/TMButton';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';
import { getLogo } from './../../../assets/helper';

const SurveyIntro = props => {
  const redirectToConfirmation = () => {
    props.history.push('/survey/verify');
  };

  return (
    <div className="page-container-survey-intro">
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          redirectToConfirmation();
        }}
      />
      <TMCard
        className={`tm-card-stepper`}
        header={[
          <div className="text-center mb-4">
            <img className="w-75 " src={getLogo()} alt="Logo" />
          </div>,
          <div className="mb-3">
            <LanguagePicker/>
          </div>,
          <p>{getContent('survey_content-intro-1')}</p>,
          <p className="font-weight-bold">{getContent('survey_content-intro-2')}</p>,
          <AudioButton
            label={getContent('survey_button-listen')}
            playlist={[
              getAudio('survey_content-intro-1'),
              getAudio('survey_content-intro-2'),
            ]}
          />,
        ]}
      >
        <TMButton className="btn-block btn-round btn-green btn-lg" locatorId='locator_redirect-to-confirmation' onClick={redirectToConfirmation} tabIndex={0} iconRight={ArrowRight}>
          {getContent('survey_button-next')}
        </TMButton>
      </TMCard>
    </div>
  );
};

export default withRouter(SurveyIntro);
