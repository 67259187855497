import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Spinner from '../../../../../components/Spinner/Spinner';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import * as actions from '../../../../../store/actions';

class CleverStudentSelection extends Component {
  state = {
    students: [],
    grades: [],
    studentsByGrade: {},
    loadingParticipants: true,
  };

  async componentDidMount() {
    const participants = await this.getParticipants();
    this.setParticipants(participants);
  }

  setParticipants = data => {
    const grades = [];
    const studentsByGrade = {};
    data.forEach(item => {
      if (!grades.includes(item.grade)) {
        grades.push(item.grade);
      }

      if (!studentsByGrade[item.grade]) {
        studentsByGrade[item.grade] = [];
      }

      studentsByGrade[item.grade].push(item.id);
    });
    this.props.setStudentsByGrade(studentsByGrade);
    this.setState({ students: data, grades, studentsByGrade, loadingParticipants: false });
  };

  getParticipants = async lastKey => {
    const orgId = this.props.organization ? this.props.organization.orgId : '';
    let url = `${process.env.REACT_APP_API_URL}/clever-participants?orgId=${orgId}`;

    if (lastKey) {
      url = `${url}&lastKey=${lastKey.id}`;
    }

    try {
      let items = [];
      const { data } = await axios({
        url,
        method: 'GET',
        headers: { Authorization: this.props.idToken },
      });

      items = items.concat(data.items);

      if (data.lastKey) {
        const next = await this.getParticipants(data.lastKey);
        items = items.concat(next);
      }

      return items;
    } catch (err) {
      console.log('Error clever participant error: ', err);
    }
  };

  isAllGrades = () => {
    return !!this.props.grades && this.props.grades.length === this.state.grades.length;
  };

  toggleAllGrades = () => {
    this.state.grades.forEach(grade => {
      if (!this.props.grades || !this.props.grades.includes(grade)) {
        this.props.toggleGradeForSurvey(grade);
      }
    });
  };

  unToggleAllGrades = () => {
    this.state.grades.forEach(grade => {
      if (this.props.grades && this.props.grades.includes(grade)) {
        this.props.toggleGradeForSurvey(grade);
      }
    });
  };

  handleToggleAllGrades = () => {
    this.isAllGrades() ? this.unToggleAllGrades() : this.toggleAllGrades();
  };

  render() {
    return (
      <div>
        <p>
          <strong>Add participants from Clever</strong>
        </p>
        <p>Select the grade(s) for the survey. Remove all nonapplicapable students on the next screen.</p>
        {this.state.loadingParticipants ? (
          <Spinner />
        ) : (
          <div className="clever-student-selection-wrapper">
            <div className="clever-all-grades-wrapper">
              <CheckBox
                key="all"
                locatorId={'locator_clever-all-grades'}
                label="All Grades"
                name="all-grades"
                value="all-grades"
                isChecked={!!this.isAllGrades()}
                onClick={() => this.handleToggleAllGrades()}
              />
            </div>
            <div className="clever-student-selection">
              {this.state.grades
                ? this.state.grades.map(grade => {
                    return (
                      <CheckBox
                        key={grade}
                        label={`${grade}`}
                        locatorId={`locator_clever-grade-${grade}`}
                        name="grade"
                        value={`${grade}`}
                        isChecked={this.props.grades && this.props.grades.includes(`${grade}`) ? true : undefined}
                        onClick={() => this.props.toggleGradeForSurvey(`${grade}`)}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.isAuthenticated,
    organization: state.organizations.organization,
    role: state.auth.role,
    idToken: state.auth.idToken,
    grades: state.surveyCrud.grades,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleGradeForSurvey: grade => dispatch(actions.toggleGradeForSurvey(grade)),
    setStudentsByGrade: students => dispatch(actions.setStudentsByGrade(students)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleverStudentSelection);
