import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ParentSuggestionPage from './SuggestionsPage';
import TFSReport from '../Shared/TFSReport';
import ParticipantHeader from '../Shared/ParticipantHeader';
import ParentIntroPage from './IntroPage';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';


import * as actions from '../../../../store/actions/';

class ParentReport extends Component {
	componentDidMount() {
		//const participantId = this.props.location.state && this.props.location.state.participantId ? this.props.location.state.participantId : null;
		const parsed = queryString.parse(this.props.location.search);
		const participantId = parsed.pId;
		console.log('THIS.PROPS IN PARENT REPORT: ', this.props);
		this.props.getReport(participantId);
	}

	render() {
		return (
			<div className="tm-report tm-report-student tm-report-parent">
				{this.props.isLoading === false && this.props.report ? (
					<>
						{/* Header is sticky so it prints on each page */}
						<ParticipantHeader payload={this.props.report} showLocalization={true} showPrint={true} />
						{/**  Start Parent  **/}
						<ParentIntroPage payload={this.props.report} showReturnButton={this.props.showReturnButton} />
						<TFSReport payload={this.props.report} showTimeBox={false} isParentReport={true} />
						<ParentSuggestionPage payload={this.props.report} />
					</>
				) : (
						<Backdrop>
							<Spinner />
						</Backdrop>
					)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		report: state.reports.report,
		isLoading: state.reports.isLoading,
		lang: state.meta.lang
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getReport: participantId => dispatch(actions.getReport(participantId))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ParentReport));
