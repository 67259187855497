import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconSchool } from '../../../../../assets/imgs/icon-school.svg';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import displayPhoneNumber from '../../../../../utilities/displayPhoneNumber';
import ModalSplash from '../ModalSplash';
import activateItem from '../../../../../utilities/activateItem';
import CreateSchoolForm from './CreateSchoolForm';
import CreateSchoolFormClever from './CreateSchoolFormClever';
import CreateSchoolFormWrapper from './CreateSchoolFormWrapper';
import * as actions from '../../../../../store/actions';

class CreateSchoolModal extends Component {
  state = {
    currentPage: 0,
    champions: [],
  };

  componentDidMount() {
    if (this.props.item && this.props.item.cleverItem) {
      this.setState({ currentPage: 2 });
      return;
    }

    if (this.props.item) {
      console.log('item exists');
      this.setState({ currentPage: 1 });
      return;
    }
    // TODO: For editing champions in this modal later down the road
    // console.log('this.props.item: ', this.props.item);
    // if (this.props.item && this.props.item.schoolChampions) {
    //   if (this.props.item.schoolChampions.length > 0) {
    //     const schoolChampions = this.props.item.schoolChampions.map((item, index) => {
    //       const champion = {};
    //       champion[`firstName-${index}`] = item.firstName;
    //       champion[`lastName-${index}`] = item.lastName;
    //       champion[`phoneNumber-${index}`] = item.phoneNumber;
    //       champion[`email-${index}`] = item.email;
    //       return champion;
    //     });
    //     this.setState({ champions: schoolChampions });
    //   }
    // }
  }

  addChampion = e => {
    e.preventDefault();
    this.setState({ champions: [...this.state.champions, {}] });
  };

  removeChampion = (e, index) => {
    e.preventDefault();
    const champions = [...this.state.champions];
    champions.splice(index, 1);
    this.setState({ champions: champions });
  };

  isUK = () => (process.env.REACT_APP_STAGE === 'uk');

  handleSubmit = async values => {
    console.log('CREATE School SUBMIT VALUES: ', values, ' item: ', this.props.item);
    const newItem = {
      name: values.name,
      address: values.address,
      city: values.city,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
      classification: 'school',
      // NOTE: These fields get duplicated from parent org
      parentOrgId: this.props.organization.orgId,
      state: this.props.organization.state,
      country: this.props.organization.country,
    };

    const champions = this.state.champions.map((item, index) => {
      return {
        firstName: values[`firstName-${index}`],
        lastName: values[`lastName-${index}`],
        phoneNumber: sanitizePhoneNumber(values[`phoneNumber-${index}`]),
        email: values[`email-${index}`],
      };
    });



    // Clever Item
    if (values.search) {
      const champions = await Promise.all(this.state.champions.map(async (item, index) => {
        const champion = {
          id: values[`searchUser-${index}`][0].sub,
          isActivated: true,
          type: 'user',
          firstName: values[`firstName-${index}`],
          lastName: values[`lastName-${index}`],
          phoneNumber: sanitizePhoneNumber(values[`phoneNumber-${index}`]),
          email: values[`email-${index}`],
        };
        await activateItem(champion, this.props.idToken);
        return champion;
      }));
      const org = {
        ...values.search[0],
        orgId: values.search[0].orgId,
        isActivated: true,
        employerProvider: values.employerProvider,
        primary: values.primary,
        secondary: values.secondary,
        adult: values.adult,
      };
      await this.props.updateOrganization(org);
      return;
    }

    // this is after clever due to backwards compatability
    //   We don't want to create or update champions for clever items using the old way
    newItem.schoolChampions = champions;

    // Brand new item
    if (!this.props.item && !values.search) {
      newItem.firstName = values.firstName;
      newItem.lastName = values.lastName;
      newItem.email = values.email;
      newItem.phoneNumber = sanitizePhoneNumber(values.phoneNumber);

      if (!champions || champions.length === 0) {
        this.props.confirmCreationWithoutChampion(newItem);
      } else {
        this.props.createOrganization(newItem);
      }
      return;
    }

    // Updating Item
    if (this.props.item) {
      newItem.orgId = this.props.item.orgId;
      this.props.updateOrganization(newItem);
    }
  };

  render() {
    let initialValues = {};
    if (this.props.item) {
      // Initial Values Added here
      // NOTE: Saved for later editing champions on this modal
      // if (this.state.champions.length > 0) {
      //   initialValues = { ...this.state.champions.flatten() };
      // }
      initialValues.name = this.props.item.name;
      initialValues.address = this.props.item.address;
      initialValues.city = this.props.item.city;
      initialValues.phoneNumber = displayPhoneNumber(this.props.item.phoneNumber);
      console.log('Initial Values: ', initialValues);
    }

    return (
      <ModalWrapper show={this.props.show} size="lg" resetModalErrorsOnClose={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IconSchool style={{ marginRight: '1rem' }} />
            {this.props.item ? `${this.isUK() ? 'Edit Organisation' : 'Edit Group'}` : `${this.isUK() ? 'Create new Organisation' : 'Create new Group'}`}
          </Modal.Title>
        </Modal.Header>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <Modal.Body bsPrefix="modalContent">
          {/* {this.state.currentPage === 0 && <ModalSplash
            title="Create a new district or add one from Clever."
            textOne="Create New"
            textTwo="Add from Clever"
            handleClickOne={() => this.setState({ currentPage: 1 })}
            handleClickTwo={() => this.setState({ currentPage: 2 })}
          />} */}
          {<CreateSchoolFormWrapper item={this.props.item}>
            <CreateSchoolForm
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              saveButtonText={this.props.item ? 'Save Edits' : `${this.isUK() ? 'Add Organisation' : 'Add Group'}`}
              champions={this.state.champions}
              addChampion={this.props.item ? null : this.addChampion}
              removeChampion={this.removeChampion}
              closeModal={this.props.closeModal}
            /></CreateSchoolFormWrapper>}
          {/* {this.state.currentPage === 2 && <CreateSchoolFormWrapper item={this.props.item}>
            <CreateSchoolFormClever
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              saveButtonText={this.props.item ? 'Save Edits' : `${this.isUK() ? 'Add Organisation' : 'Add School'}`}
              closeModal={this.props.closeModal}
              champions={this.state.champions}
              addChampion={this.props.item ? null : this.addChampion}
              removeChampion={this.removeChampion}
              item={this.props.item}
              error={this.props.error}
              role={this.props.role}
              organization={this.props.organization}
              idToken={this.props.idToken}
            />
          </CreateSchoolFormWrapper>} */}

        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    error: state.organizations.error,
    role: state.auth.role,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(actions.createOrganization(values)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSchoolModal);
