import React, { Component } from 'react'
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from 'react-bootstrap/Button';
import DropdownList from 'react-widgets/lib/DropdownList';

import * as actions from '../../../../../store/actions';


class SearchSurveyForm extends Component {
  componentDidMount() {
    if (this.props.role === 'district_champion') {
      this.props.updateSearchSurveyObj({ name: 'surveySearchDistrict', value: { name: '', orgId: this.props.orgInfo.orgId } });
      this.props.setSearchValues();
    } else {
      this.props.setSearchValues();
    }
  };

  isUK = ()=>( process.env.REACT_APP_STAGE === 'uk');


  dropdownChangeHandler = (dropdownName, value) => {
    if (dropdownName === 'surveySearchCountryRegion') {
      this.props.clearStateOptions();
    }
    if (dropdownName === 'surveySearchStateProvince') {
      this.props.clearDistrictOptions();
      this.props.clearSchoolOptions();
    }
    if (dropdownName === 'surveySearchDistrict') {
      this.props.clearSchoolOptions();
    }
    this.props.updateSearchSurveyObj({ name: dropdownName, value: value });
    this.props.setSearchValues();
  };

  render() {
    // this.props.getSearchValues()

    var yearOptions = [];
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < 10; index++) {
      yearOptions.push(currentYear + index);
    }
    console.log('this.props.surveySearchDistrict', this.props.surveySearchDistrict)
    return (
      <div className={classnames('container-search-survey', this.props.className)}>
        <p className='search-survey-header'>Search Assessments</p>
        <form className='search-survey-form' onSubmit={this.props.onSubmit}>
          <div className='input-container-search-survey'>
            {(this.props.role && this.props.role === 'tm_admin') &&
              <div>
                <label className="label">Country/Region</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={this.props.searchCountryRegionOptions}
                  onChange={value => this.dropdownChangeHandler('surveySearchCountryRegion', value)}
                  value={this.props.surveySearchCountryRegion}
                  placeholder="- Select -"
                />
              </div>
            }
            {(this.props.role && this.props.role === 'tm_admin') &&
              <div>
                <label className="label">{this.isUK() ? 'County':'State/Province'}</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={this.props.searchStateProvinceOptions}
                  onChange={(value) => this.dropdownChangeHandler('surveySearchStateProvince', value)}
                  value={this.props.surveySearchStateProvince}
                  placeholder={(this.props.surveySearchCountryRegion === '') ? '' : '- Select -'}
                  disabled={this.props.surveySearchCountryRegion === ''}
                />
              </div>
            }
            {(this.props.role && this.props.role === 'tm_admin') &&
              <div>
                <label className="label">{this.isUK() ? 'Group':'Organization'}</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={this.props.searchDistrictOptions}
                  onChange={(value) => this.dropdownChangeHandler('surveySearchDistrict', value)}
                  value={(this.props.surveySearchDistrict && this.props.surveySearchDistrict.name) ? this.props.surveySearchDistrict.name : ''}
                  placeholder={(this.props.surveySearchStateProvince === '') ? '' : `- Select ${this.isUK() ? 'Group':'Organization'} -`}
                  disabled={this.props.surveySearchStateProvince === ''}
                />
              </div>
            }
            {(this.props.role && ['district_champion', 'tm_admin'].includes(this.props.role)) &&
              <div>
                <label className="label">{this.isUK() ? 'Organisation' :'Group'}</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={this.props.searchSchoolOptions}
                  onChange={(value) => this.dropdownChangeHandler('surveySearchSchool', value)}
                  value={this.props.surveySearchSchool.name}
                  placeholder={(this.props.surveySearchDistrict && this.props.surveySearchDistrict.name === '') ? '' : `- Select ${this.isUK() ? 'Organisation': 'Group'} -`}
                  disabled={this.props.surveySearchDistrict && this.props.surveySearchDistrict.name === '' && this.props.role !== 'district_champion'}
                />
              </div>
            }
            <div>
              <label className="label">Year</label>
              <DropdownList
                className="tm-dropdown short"
                data={yearOptions}
                onChange={(value) => this.props.updateSearchSurveyObj({ name: 'surveySearchYear', value: value })}
                value={this.props.surveySearchYear}
              />
            </div>
            <div>
              <label className="label survey-name-label">Assessment Name</label>
              <input
                type='text'
                placeholder="Full Assessment Name"
                className='search-survey-input'
                onChange={(event) => this.props.updateSearchSurveyObj({ name: 'surveySearchName', value: event.target.value })}
              />
            </div>
          </div>
          <div className='search-survey-submit-container'>
            <Button onClick={this.props.onSubmit} className='small-blue-button' disabled={this.props.isLoading}>Search Assessments</Button>
            <Button onClick={this.props.hideSurveySearch} className='small-blue-button-outlined'>Cancel</Button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    surveySearchCountryRegion: state.surveyCrud.surveySearchCountryRegion,
    surveySearchStateProvince: state.surveyCrud.surveySearchStateProvince,
    surveySearchDistrict: state.surveyCrud.surveySearchDistrict,
    surveySearchSchool: state.surveyCrud.surveySearchSchool,
    surveySearchYear: state.surveyCrud.surveySearchYear,
    searchCountryRegionOptions: state.surveyCrud.searchCountryRegionOptions,
    searchStateProvinceOptions: state.surveyCrud.searchStateProvinceOptions,
    searchDistrictOptions: state.surveyCrud.searchDistrictOptions,
    searchSchoolOptions: state.surveyCrud.searchSchoolOptions,
    isLoading: state.surveyCrud.isLoading,
    role: state.auth.role,
    orgInfo: state.organizations.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSurveySearch: () => dispatch(actions.hideSurveySearch()),
    updateSearchSurveyObj: payload => dispatch(actions.updateSearchSurveyObj(payload)),
    setSearchValues: () => dispatch(actions.setSearchValues()),
    clearStateOptions: () => dispatch(actions.clearStateOptions()),
    clearDistrictOptions: () => dispatch(actions.clearDistrictOptions()),
    clearSchoolOptions: () => dispatch(actions.clearSchoolOptions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSurveyForm);
