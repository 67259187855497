import React from 'react';
import classnames from 'classnames';
import AudioButton from '../../../components/Button/ButtonAudio';
const TYPES = {
  FAT: 'fat',
  SKINNY: 'skinny',
};

const QuizButton = ({ text, onClick, quizType, icon, color, active, index, value, audio, elementary }) => {
  console.log(audio);
  return (
    <div
      onClick={() => {
        onClick(index, value);
      }}
      id={`locator_response-option-button-${index + 1}`}
      className={classnames('quiz-button', `${quizType}`, { clicked: active, 'has-audio': audio })}
    >
      <div className="qb-text">
        {icon && elementary ? <img src={icon} alt={text} style={{ width: '125px', height: 'auto' }} /> : ''} {text}
      </div>
      <div className="keyboard-indicator-styles">{index + 1}</div>
      <AudioButton playlist={[audio]} />
    </div>
  );
};

export const Fat = props => <QuizButton {...props} quizType={TYPES.FAT} />;
export const Skinny = props => <QuizButton {...props} quizType={TYPES.SKINNY} />;
