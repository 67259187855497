import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ButtonNav from '../../../components/Button/ButtonNav';
import classnames from 'classnames';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { ReactComponent as IconNavReports } from '../../../assets/imgs/icon-nav-reports.svg';
import { ReactComponent as IconNavSchools } from '../../../assets/imgs/icon-nav-schools.svg';
import { ReactComponent as IconNavSurveys } from '../../../assets/imgs/icon-nav-surveys.svg';
import { ReactComponent as IconNavUsers } from '../../../assets/imgs/icon-nav-users.svg';
import { ReactComponent as IconNavSearchParticipant } from '../../../assets/imgs/icon-nav-search-participant.svg';
import { ReactComponent as IconNavSearch } from '../../../assets/imgs/icon-nav-search.svg';
import Spinner from '../../../components/Spinner/Spinner';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { Redirect } from 'react-router-dom';

const AdminHome = props => {
  const [isTMAllowed, setIsTMAllowed] = useState(props.isTMAllowed || false);
  const isUK = process.env.REACT_APP_STAGE === 'uk';

  const openConfirmationModal = (confirm, isAllowed) => {
    props.openModal({
      modalType: 'ACCESS_CONFIRMATION_MODAL',
      show: true,
      closeModal: props.closeModal(),
      isAllowed,
      confirm,
    });
  };
  const getRoleBasedData = (role, type, item) => {
    const rolesText = {
      ADMIN: {
        orgs: {
          show: true,
          text: `${isUK ? 'Groups' : 'Organizations'}`,
          descr: `${isUK ? 'Add new Groups or edit existing Groups' : 'Add new Organizations or edit existing Organizations'}`,
        },
        surveys: {
          show: true,
          text: 'Assessments',
          descr: 'View Created Assessments',
        },
        search: {
          show: false,
          text: 'Search participant',
          descr: 'View all assessments of a participant',
        },
        reports: {
          show: true,
          text: 'Reports',
          descr: 'View results from individual Assessment',
        },
        users: {
          show: true,
          text: `${isUK ? 'Add MyMynd User' : 'Add Terrace User'}`,
          descr: `'Set up or edit other ${isUK ? 'MyMynd' : 'Terrace'} Admin users'`,
        },
      },
      ORGANIZATION: {
        orgs: {
          show: true,
          text: `${isUK ? 'Organisations' : 'Groups'}`,
          descr: `${isUK ? 'View, add, or edit organisations within your group' : 'View, add or edit groups within your organizations'}`,
        },
        surveys: {
          show: true,
          text: 'Assessments',
          descr: 'View Created Assessments',
        },
        search: {
          show: true,
          text: 'Search participant',
          descr: 'View all assessments of a participant',
        },
        reports: {
          show: true,
          text: 'Reports',
          descr: 'View Individual Reports',
        },
        users: {
          show: true,
          text: `${isUK ? 'Group Champions' : 'Organization Champions'}`,
          descr: 'View, add or edit other users',
        },
      },
      GROUP: {
        orgs: {
          show: false,
          text: '',
          descr: '',
        },
        surveys: {
          show: true,
          text: 'Assessments',
          descr: 'Create new assessments, edit or view existing assessments',
        },
        search: {
          show: true,
          text: 'Search participant',
          descr: 'View all assessments of a participant',
        },
        reports: {
          show: true,
          text: 'Reports',
          descr: 'View Individual Reports',
        },
        users: {
          show: true,
          text: `${isUK ? 'Organisation Champions' : 'Group Champions'}`,
          descr: 'View, add or edit other users',
        },
      },
    };
    return rolesText[role][type][item];
  };

  const toggleAllowed = () => {
    setIsTMAllowed(!isTMAllowed);
    const newItem = {
      isTMAllowed: !isTMAllowed,
      orgId: props.orgId,
      name: props.orgName,
    };
    props.updateOrganization(newItem);
  };

  useEffect(() => {
    props.getAggregatedOverallReport();
  }, []);

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/organizations/list',
        label: 'Home',
      },
    ];
    if (props && props.bc_level && (props.bc_level === 'ORGANIZATION' || props.bc_level === 'GROUP')) {
      breadcrumbs.push({
        key: 2,
        url: '/admin/organizations/list',
        label: props.bc_orgName,
        shouldReset: true,
      });
    }
    if (props && props.bc_level && props.bc_level === 'GROUP') {
      breadcrumbs[1].label = props.bc_higherOrgName;
      breadcrumbs.push({
        key: 3,
        url: '/admin/organizations/list',
        label: props.bc_orgName,
      });
    }
    return breadcrumbs;
  };

  return (
    <>
      {props.isLoading ? (
        <Spinner />
      ) : props.role === 'responder' || props.realRole === 'responder' ? (
        <Redirect to="/responder" />
      ) : (
        <div className="page admin-home">
          <div className="sub-header">
            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
          </div>

          <div className="row my-5 nav-buttons">
            {getRoleBasedData(props.bc_level, 'orgs', 'show') ? (
              <ButtonNav
                locatorId={props.role === 'tm_admin' ? 'locator_districts-button' : props.role === 'district_champion' ? 'locator_schools-button' : null}
                isNormal={false}
                text={getRoleBasedData(props.bc_level, 'orgs', 'text')}
                icon={<IconNavSchools />}
                desc={getRoleBasedData(props.bc_level, 'orgs', 'descr')}
                onClick={() => {
                  props.history.push('/admin/organizations');
                }}
              />
            ) : null}
            {getRoleBasedData(props.bc_level, 'surveys', 'show') ? (
              <ButtonNav
                isNormal={false}
                locatorId={props.role === 'school_champion' ? 'locator_survey-button' : null}
                text={getRoleBasedData(props.bc_level, 'surveys', 'text')}
                icon={<IconNavSurveys />}
                desc={getRoleBasedData(props.bc_level, 'surveys', 'descr')}
                onClick={() => {
                  props.history.push('/admin/survey/list');
                }}
              />
            ) : null}
            {getRoleBasedData(props.bc_level, 'search', 'show') ? (
              <ButtonNav
                isNormal={false}
                locatorId={props.role === 'school_champion' ? 'locator_search_survey-button' : null}
                text={getRoleBasedData(props.bc_level, 'search', 'text')}
                icon={<><div className='search-icon-container'><IconNavSearchParticipant /><div className="search-icon"><IconNavSearch/></div></div></>}
                desc={getRoleBasedData(props.bc_level, 'search', 'descr')}
                onClick={() => {
                  props.history.push('/admin/user/surveys');
                }}
              />
            ) : null}
            <div className={classnames('w-100', 'divider')} />
            {getRoleBasedData(props.bc_level, 'reports', 'show') ? (
              <ButtonNav
                isNormal={false}
                text={getRoleBasedData(props.bc_level, 'reports', 'text')}
                imgType={'report'}
                icon={<IconNavReports />}
                desc={getRoleBasedData(props.bc_level, 'reports', 'descr')}
                onClick={() => {
                  props.history.push('/admin/reports/search');
                }}
              />
            ) : null}
            {getRoleBasedData(props.bc_level, 'users', 'show') ? (
              <ButtonNav
                isNormal={false}
                text={getRoleBasedData(props.bc_level, 'users', 'text')}
                imgType={'user'}
                icon={<IconNavUsers />}
                desc={getRoleBasedData(props.bc_level, 'users', 'descr')}
                onClick={() => {
                  props.history.push('/admin/users');
                }}
              />
            ) : null}
            {props.role === 'tm_admin' ? (
              <ButtonNav
                isNormal={false}
                text={'Add Responders'}
                imgType={'user'}
                icon={<IconNavUsers />}
                desc={'View, Add, or Edit Responders'}
                onClick={() => {
                  props.history.push('/admin/responders');
                }}
              />
            ) : null}
          </div>
          {props.realRole !== 'tm_admin' && props.role !== 'tm_admin' && props.role !== 'school_champion' && props.realRole !== 'school_champion' && props.role === 'district_champion' ? (
            <div className="d-flex justify-content-end">
              <CheckBox
                className="d-flex justify-content-center"
                labelClasses={['indicator-headers d-flex justify-content-center']}
                label={`Allow ${isUK ? 'MyMynd' : 'Terrace Metrics'} Access to All our Data`}
                name={'isTMAllowed'}
                value={isTMAllowed}
                isChecked={isTMAllowed ? 'checked' : undefined}
                onClick={() => {
                  openConfirmationModal(toggleAllowed, isTMAllowed);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    resetRole: () => dispatch(actions.resetRole()),
    resetOrganization: () => dispatch(actions.resetOrganization()),
    getAggregatedOverallReport: () => dispatch(actions.getAggregatedOverallReport()),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
    closeModal: () => dispatch(actions.closeModal()),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
  };
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    realRole: state.auth.realRole,
    orgId: state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgId: state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    bc_higherOrgName: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.name,
    bc_higherOrgId: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.orgId,
    //bc_level: (state.auth.role === "tm_admin") ? "ADMIN": (state.auth.role === "district_champion") ? "ORGANIZATION": (state.auth.role === "school_champion") ? "GROUP": "NA",
    bc_level:
      state.auth.role === 'tm_admin'
        ? 'ADMIN'
        : state.auth.role === 'school_champion' || (state.auth.role === 'district_champion' && state.organizations.organization.parentOrg)
        ? 'GROUP'
        : state.auth.role === 'district_champion'
        ? 'ORGANIZATION'
        : 'NA',
    isLoading: state.organizations.isLoading,
    reportDetails: state.surveyCrud.reportDetails,
    isTMAllowed: state.organizations && state.organizations.organization && state.organizations.organization.isTMAllowed,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminHome));
