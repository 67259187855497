import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loggedIn: false,
  redirectFromLogIn: true,
  userId: null,
  isLoading: false,
  isLoaded: false,
  error: false,
  userName: null,
  userGrade: null,
  userSchoolName: null,
  showErrorMsg: false,
};

const loginStudentStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoaded: { $set: false },
    isLoading: { $set: true },
    showErrorMsg: { $set: false },
  });
};

const loginStudentSuccess = (state, action) => {
  console.log(action);
  const { userId, grade: userGrade, userName, schoolName } = action;
  return update(state, {
    userId: { $set: userId },
    userGrade: { $set: userGrade },
    userName: { $set: userName },
    userSchoolName: { $set: schoolName },
    isLoaded: { $set: true },
    isLoading: { $set: false },
    loggedIn: { $set: true },
    redirectFromLogIn: { $set: true },
    showErrorMsg: { $set: false },
  });
};

const loginStudentFail = (state, action) => {
  const { message } = action.error;
  return update(state, {
    error: { $set: message || 'Invalid invite code.' },
    isLoading: { $set: false },
    showErrorMsg: { $set: true },
  });
};

const studentAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_STUDENT_START:
      return loginStudentStart(state, action);
    case actionTypes.LOGIN_STUDENT_SUCCESS:
      return loginStudentSuccess(state, action);
    case actionTypes.LOGIN_STUDENT_FAIL:
      return loginStudentFail(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default studentAuthReducer;
