import React from 'react';
import AudioButton from '../Button/ButtonAudio';

const RadioButton = ({ isChecked, text, index, handler, value, tabIndex, audio, field }) => (
  <label className={`container-radio-btn${audio? ' has-audio' : ''}`}>
    <input
      onChange={() => {
        handler(index);
      }}
      id={`locator_${field}`}
      checked={isChecked}
      type="radio"
      value={value}
      tabIndex={tabIndex}
    />
    {text}
    {audio ? (
      <AudioButton
        className="small"
        playlist={[audio]}
      />
    ) : null}
  </label>
);
export default RadioButton;
