import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';

import BootStrapButton from 'react-bootstrap/Button';
import RenderField from '../../../../components/RenderField/RenderField';
import RenderPhoneField from '../../../../components/RenderPhoneField/RenderPhoneField';
import CheckedImage from '../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../assets/imgs/unchecked.png';
import displayPhoneNumber from '../../../../utilities/displayPhoneNumber';
import { confirmPasswordsMatch, required } from '../../../../utilities/validation';
import PasswordConfirmation from './PasswordConfirmation';
import UsersTypeahead from '../../../../components/Typeahead/UsersTypeahead/UsersTypeahead';
import handleUserSearch from '../../../../components/Typeahead/UsersTypeahead/handleUserSearch';

class AddUserForm extends Component {
  state = {
    isDesignatedChecked: this.props.initialValues.isDesignated,
    isActivatedChecked: this.props.initialValues.isActivated
  };

  isUK = () => (process.env.REACT_APP_STAGE === 'uk');

  toggleDesignated = () => {
    this.setState({ isDesignatedChecked: this.state.isDesignatedChecked === true ? false : true });
  };
  toggleActivated = () => {
    this.setState({ isActivatedChecked: this.state.isActivatedChecked === true ? false : true });
  };

  userChangeHandler = (value) => {
    console.log('userChangeHandler: ', value);
    const formName = 'add-user-form';
    const item = value[0];
    if (!item) return;
    this.props.dispatch(change(formName), 'searchUser', value);
    this.props.dispatch(change(formName, 'firstName', item.firstName || item.given_name || ''));
    this.props.dispatch(change(formName, 'lastName', item.lastName || item.family_name || ''));
    this.props.dispatch(change(formName, 'phoneNumber', displayPhoneNumber(item.phoneNumber) || ''));
    this.props.dispatch(change(formName, 'email', item.email || ''));
  }

  render() {
    console.log('this.props.item: ', this.props.item);
    const isEditable = !this.props.item || (this.props.item && !this.props.item.cleverData);
    const { invalid, pristine, submitting, isCleverOrg } = this.props;
    console.log('this.props.item: (USER)', this.props.item);
    console.log('this.props from AddUserForm: ', this.props);
    // const orgSearchId = this.props.organization && this.props.organization.orgId ? this.props.organization.orgId : '';
    const disableForCleverOrgs = isCleverOrg;
    return (
      <form onSubmit={this.props.handleSubmit}>
        {/* {
          isCleverOrg ? <div className="label-field-container">
            <label className="label" htmlFor="searchUser">Search Users</label>
            <Field
              name="searchUser"
              component={UsersTypeahead}
              handleSearch={(query, setOptions, setIsLoading) => handleUserSearch(query, setOptions, setIsLoading, orgSearchId, this.props.idToken)}
              orgSearch={orgSearchId}
              onChange={this.userChangeHandler}
              disabled={this.props.item}
            />
          </div> : null} */}
        <div>
          <label className="label" htmlFor="firstName">{this.props.labelText} First Name</label>
          <Field
            name="firstName"
            placeholder="First Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={isEditable ? null : [required]}
            disabled={!isEditable}
          />
        </div>
        <div>
          <label className="label" htmlFor="lastName">{this.props.labelText} Last Name</label>
          <Field
            name="lastName"
            placeholder="Last Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={isEditable ? null : [required]}
            disabled={!isEditable}
          />
        </div>
        <div>
          <label className="label" htmlFor="email">{this.props.labelText} Email</label>
          <Field
            name="email"
            placeholder="Email Address"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={isEditable ? null : [required]}
            disabled={!isEditable}
          />
        </div>
        <div>
          <label className="label" htmlFor="phoneNumber">{this.props.labelText} Phone</label>
          <div className="modalInput p-0">
            <Field
              name="phoneNumber"
              type="tel"
              component={RenderPhoneField}
              validate={isEditable ? null : [required]}
              disabled={!isEditable}
            />
          </div>
        </div>
        {this.props.showDesignatedToggle ?
          <div className="mt-1">
            <label>
              <img className='checkbox' alt='Checkbox' src={this.state.isDesignatedChecked ? CheckedImage : UncheckedImage} />
              <Field
                name="isDesignated"
                type='checkbox'
                component="input"
                onChange={this.toggleDesignated}
                disabled={!isEditable}
              />
              Designated {this.isUK() ? 'Group' : 'Organization'} Champion
            </label>
          </div>
          : null}
        {this.props.isCleverOrg && this.props.item && typeof this.props.item.isActivated === 'boolean' ?
          <div className="mt-1">
            <label>
              <img className='checkbox' alt='Checkbox' src={this.state.isActivatedChecked ? CheckedImage : UncheckedImage} />
              <Field
                name="isActivated"
                type='checkbox'
                component="input"
                onChange={this.toggleActivated}
                disabled={!disableForCleverOrgs}
              />
              User Activated
            </label>
          </div>
          : null}
        {this.props.isUserProfile && !(this.props.isCleverOrg && this.props.item && typeof this.props.item.isActivated === 'boolean') ?
          <PasswordConfirmation />
          : null}
        <div className='btnContainer mt-5'>
          <BootStrapButton
            type="submit"
            className='small-button green'
            size="lg"
            variant="success"
            disabled={invalid || pristine || submitting}
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton
            onClick={(e) => this.props.closeModal(e)}
            className='small-blue-button-outlined'
            size="lg"
            variant="outline-primary"
          >
            Cancel
          </BootStrapButton>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'add-user-form',
  validate: confirmPasswordsMatch,
})(AddUserForm);
