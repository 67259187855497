export default {
  at_risk: {
    label: 'Low',
    color: 'grey',
    url: {
      default: 'http://www.terracemetrics.org/parents/',
      adult: 'http://www.terracemetrics.org/adults/',
      defaultUK: 'http://www.mymynd.co.uk/parents/',
      adultUK: 'http://www.mymynd.co.uk/adults/',
    }
  },
  moderate: {
    label: 'Moderate',
    color: 'grey',
  },
  satisfactory: {
    label: 'Satisfactory',
    color: 'light-green',
  },
  optimal: {
    label: 'High',
    color: 'grey',
  },
  no_concern: {
    label: 'No Concern',
    color: 'grey',
  },
  no_tfs: {
    label: 'TFS Not Available',
    color: 'grey',
  },
};
