import { Auth } from 'aws-amplify';

const getAuthenticatedUser = async () => {
  const cleverUserRaw = localStorage.getItem('cleverUserData');
  if (cleverUserRaw) {
    return JSON.parse(cleverUserRaw);
  } else {
    const user = await Auth.currentAuthenticatedUser()
      .then(res => res)
      .catch(err => null);
    return user;
  }
};

export default getAuthenticatedUser;
