import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../store/actions';

class OrgPicker extends Component {
  state = {
    orgs: [],
  }

  async componentDidMount() {
    this.getOrgs();
  }

  getOrgs = async () => {
    if (this.props.orgs) {
      console.log('this.props.orgs in orgPicker: ', this.props.orgs);
      const orgs = await Promise.all(this.props.orgs.map(async orgId => {
        const { REACT_APP_API_URL } = process.env;
        const url = `${REACT_APP_API_URL}/org?orgId=${orgId}`;
        const response = await axios({
          url,
          method: 'GET',
          headers: { Authorization: this.props.idToken },
        });

        console.log('response from get org: ', response);
        return response.data;
      }));

      this.setState({ orgs });
    }
  }

  render() {
    console.log('this.props.org: ', this.props.org);
    console.log('this.state.orgs: ', this.state.orgs);
    //console.log('finding org: ', this.state.orgs.find(item => item.orgId === this.props.org.orgId));
    return (
      <>
        {
          this.state.orgs.length > 1 && this.props.org ? <select
            className="language-picker"
            onChange={(event) => {
              this.props.getOrganization(event.target.value);
            }
            }
            value={this.props.org.orgId}
          >
            {
              this.state.orgs.map((item) =>
                <option
                  key={item.orgId}
                  value={item.orgId}
                >
                  {item.name}
                </option>
              )
            }
          </select>
            : null
        }

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    org: state.organizations.organization,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    getOrganization: orgId => dispatch(actions.getOrganization(orgId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPicker);
