import React, { Component } from 'react'
import { connect } from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import "react-widgets/dist/css/react-widgets.css";
import { getContent } from '../../../../../utilities/getContent/getContent';

import * as actions from '../../../../../store/actions';

class SurveyDateSelect extends Component {
  onQuarterChange = (value) => {
    console.log('value', value);
  }

  render() {
    var yearOptions = [];
    const appliedTo = this.props.appliedTo;
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < 10; index++) {
      yearOptions.push(currentYear + index);
    }

    const termOptions = {
      'en-US': [
        'Fall',
        'Winter',
        'Spring',
        'Summer',
        'First',
        'Second',
        'Third',
        'Fourth',
      ],
      'en-UK': [
        'Winter',
        'Spring',
        'Summer',
        'Autumn'
      ],
      'en-UK-adult': [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November'
      ]
    };

    return (
      <div className="form-container-create-survey">
        <div className="dropdown-container">
          <p className="dropdown-label">{getContent('admin_label-term')}</p>
          <DropdownList
            className="tm-dropdown"
            data={termOptions[`${this.props.lang}${appliedTo === 'adult' && this.props.lang === 'en-UK' ? '-adult' : ''}`]}
            onChange={this.props.setSurveyQuarter}
            value={this.props.quarter}
            placeholder="Please select..."
            id='locator_select-date-period'
          />
        </div>
        <div className="dropdown-container">
          <p className="dropdown-label">Year</p>
          <DropdownList
            className="tm-dropdown"
            data={yearOptions}
            onChange={this.props.setSurveyYear}
            value={this.props.year}
            placeholder="Please select..."
            id='locator_select-date-year'
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    quarter: state.surveyCrud.createSurveyObj.quarter,
    year: state.surveyCrud.createSurveyObj.year,
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSurveyQuarter: (value) => dispatch(actions.setSurveyQuarter(value)),
    setSurveyYear: (value) => dispatch(actions.setSurveyYear(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDateSelect);
