import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  selectedRadioGroupIndex: null,
  selectedRadioGroupValue: null,
  demographicQuestionIsSelected: false,
  demographicQuestionAnswerValue: null,
  demographicQuestionIndex: 0,
  isAnswerSelected: false,
  answerValue: null,
  indicatorIndex: 0,
  indicatorResponseIndex: 0,
  receivedSurveyData: [],
  currentQuestion: {},
  currentAnswer: {},
  surveyDataToSend: {
    surveyId: null,
    surveyParticipantId: null,
    grade: null,
    surveyStartedAt: null,
    surveySubmittedAt: null,
    surveyName:null,
    indicators: [],
    createdAt: null,
    demographicQuestions: [],
    validityQuestions: [],
  },
  isSaveSurveyResponseLoading: false,
  saveSurveyResponseError: null,
  questionIndex:1,
  totalQuestions:0
};

// Create Initial Survey Response
const createInitialSurveyResponse = (state, action) => {

  var tempInitialSurveyData = action.initialSurveyData;
  console.log('INITIAL SURVEY DATA: ', tempInitialSurveyData)

  action.initialSurveyData.validityQuestions.forEach((question, index) => {
    if (index > tempInitialSurveyData.indicators.length - 1) {
      // dont push
    } else {
      tempInitialSurveyData.indicators[index].questions.push(question);
    }
  });

  const accumulateQuestions = (accum,currentValue)=>accum+currentValue;
  const totalSurveyQuestions = tempInitialSurveyData.indicators.map(indicator=>{
    return indicator.questions.length;
  }).reduce(accumulateQuestions);

  return update(state, {
    receivedSurveyData: { $set: tempInitialSurveyData },
    totalQuestions: {$set:totalSurveyQuestions},
    surveyDataToSend: {
      surveyId: { $set: action.initialSurveyData.surveyId },
      surveyParticipantId: { $set: action.initialSurveyData.surveyParticipantId },
      surveyName: {$set: action.initialSurveyData.surveyName},
      grade: { $set: action.initialSurveyData.grade },
      createdAt: { $set: new Date() },
      surveyStartedAt: { $set: Date.now() }, // surveyStartedAt should be set on confirmation in ParticipantInfoConfirmation
      surveySubmittedAt: { $set: null },
      isAllCore: { $set: action.initialSurveyData.isAllCore },
      orgId: { $set: action.initialSurveyData.orgId },
    },
  });
};

// Push Selected Answer
const pushSelectedAnswer = (state, action) => {
  console.log('PushSelectAnswer()', state, action);

  // Find this indictor in the state.surveyDataToSend
  const indexOfMatch = state.surveyDataToSend.indicators.findIndex(indicator => {
    return indicator.indicator === action.indicatorData.name;
  });

  // If we have a Match
  if (indexOfMatch >= 0) {
    // Clone the state survey Data
    const newSurveyDataToSend = { ...state.surveyDataToSend };
    // Clone array of Answered Questions
    const arrayOfAnsweredQuestions = [...state.surveyDataToSend.indicators[indexOfMatch].responses];
    // Push this answer to the question
    arrayOfAnsweredQuestions.push(action.answerData);
    // Push Response to indicator
    newSurveyDataToSend.indicators[indexOfMatch].responses = arrayOfAnsweredQuestions;
    // Update the whole damn thing.
    return update(state, {
      surveyDataToSend: { $set: newSurveyDataToSend },
      isAnswerSelected: { $set: false },
      answerValue: { $set: null },
    });
  } else {
    return update(state, {
      isAnswerSelected: { $set: false },
      answerValue: { $set: null },
      surveyDataToSend: {
        indicators: {
          $push: [
            {
              indicator: action.indicatorData.name,
              contentId: action.indicatorData.contentId,
              tags: action.indicatorData.tags,
              responses: [action.answerData],
            },
          ],
        },
      },
    });
  }
};

const pushSelectedValidityAnswer = (state, action) => {
  const { responseOptionField, responseScore, questionText, } = action.answerData;
  return update(state, {
    surveyDataToSend: {
      validityQuestions: {
        $push: [{ questionText, responseOptionField, responseScore }],
      },
    },
  });
};

const setSurveySubmittedAt = (state, action) => {
  return update(state, {
    surveyDataToSend: {
      surveySubmittedAt: { $set: Date.now() },
    }
  });
}

const saveSurveyParticipantResponseData = (state, action) => {
  console.log('reducers/survey.js : saveSurveyParticipantResponseData()');
  return update(state, {
    surveyDataToSend: {
      dataSent: { $set: true },
    },
  });
};

const incrementSurveyIndicatorResponseIndex = (state, action) => {
  console.log('reducers/survey.js : incrementSurveyIndicatorResponseIndex()');

  return update(state, {
    indicatorResponseIndex: { $set: state.indicatorResponseIndex + 1 },
    isAnswerSelected: { $set: false },
    answerValue: { $set: null }
  });
};

const incrementSurveyIndicatorIndex = (state, action) => {
  console.log('reducers/survey.js : incrementSurveyIndicatorIndex()');

//  const nextIndicator = state.receivedSurveyData.indicators[state.indicatorIndex + 1];
//  const indicatorHasAtRiskResponses = nextIndicator
//    && nextIndicator.responses_b.length > 0
//    && Object.keys(nextIndicator).includes('questions_b') ? true  : false;

  return update(state, {
    indicatorIndex: { $set: state.indicatorIndex + 1 },
    indicatorResponseIndex: { $set: 0 },
    isAnswerSelected: { $set: false },
    answerValue: { $set: null },
    // indicatorHasAtRiskResponses: { $set: indicatorHasAtRiskResponses },
  });
};

const toggleSurveyAnswer = (state, action) => {
  const { isSelected, answerValue, indexOptionSelected } = action;
  console.log('reducer: action', action);
  return update(state, {
    isAnswerSelected: { $set: isSelected },
    answerValue: { $set: answerValue },
    indicatorResponseOptionsIndex: { $set: indexOptionSelected },
  });
};

const pushSecondaryIndicatorResponses = (state, action) => {
  console.log('reducers/survey.js : pushSecondaryIndicatorResponses()');
  const {
    indicatorIndex: currentIndicatorIndex,
    receivedSurveyData: { indicators },
  } = state;

  const { questions_b } = indicators[currentIndicatorIndex];

  const newRecievedSurveyData = {
    ...state.receivedSurveyData
  };

  if (questions_b && questions_b.length > 0) {
    questions_b.forEach((question,index )=> {
      // Make sure the question hasn't already been pushed from another question
      const isMatch = newRecievedSurveyData.indicators[currentIndicatorIndex].questions.find((item) => {
        return item.questionId === question.questionId;
      })

      if (!isMatch) {
        const primaryQuestionLength = newRecievedSurveyData.indicators[currentIndicatorIndex].questions.length;
        newRecievedSurveyData.indicators[currentIndicatorIndex].questions.splice((index+primaryQuestionLength),0,question);
      }
    });

    const newQuestions = newRecievedSurveyData.indicators[currentIndicatorIndex].questions_b.length;

    return update(state, {
      receivedSurveyData: { $set: newRecievedSurveyData },
      totalQuestions: {$set: state.totalQuestions + newQuestions}
    });
  }

  return state;
};

const demographicAnswerSelected = (state, action) => {
  return update(state, {
    selectedRadioGroupIndex: { $set: null },
    surveyDataToSend: {
      demographicQuestions: {
        $push: [
          {
            questionText: action.questionText,
            responseOptionField: state.selectedRadioGroupValue,
            responseScore: state.selectedRadioGroupValue
          },
        ],
      },
    },
  });
};

const incrementDemographicQuestionIndex = (state, action) => {
  return update(state, {
    demographicQuestionIndex: { $set: state.demographicQuestionIndex + 1 },
    demographicQuestionIsSelected: { $set: false },
    demographicQuestionAnswerValue: { $set: null },
  });
};

const incrementQuestionIndex = (state,action)=>{
  return update(state,{
    questionIndex: {$set:state.questionIndex + 1}
  })
}

const toggleDemographicRadioButton = (state, action) => {
  return update(state, {
    selectedRadioGroupIndex: { $set: action.index },
    selectedRadioGroupValue: { $set: action.value },
    demographicQuestionIsSelected: { $set: true },
  });
};

const saveSurveyResponseStart = (state, action) => {
  return update(state, {
    isSaveSurveyResponseLoading: { $set: true },
    saveSurveyResponseError: { $set: null }
  });
};

const saveSurveyResponseSuccess = (state, action) => {
  return update(state, {
    isSaveSurveyResponseLoading: { $set: false },
  });
};

const saveSurveyResponseFail = (state, action) => {
  return update(state, {
    isSaveSurveyResponseLoading: { $set: false },
    saveSurveyResponseError: { $set: action.err }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SURVEY_RESPONSE:
      return createInitialSurveyResponse(state, action);
    case actionTypes.PUSH_SELECTED_ANSWER:
      return pushSelectedAnswer(state, action);
    case actionTypes.SAVE_SURVEY_RESPONSE:
      return saveSurveyParticipantResponseData(state, action);
    case actionTypes.INCREMENT_SURVEY_INDICATOR_INDEX:
      return incrementSurveyIndicatorIndex(state, action);
    case actionTypes.TOGGLE_SURVEY_ANSWER:
      return toggleSurveyAnswer(state, action);
    case actionTypes.INCREMENT_SURVEY_INDICATOR_RESPONSE_INDEX:
      return incrementSurveyIndicatorResponseIndex(state, action);
    case actionTypes.PUSH_SECONDARY_INDICATOR_RESPONSES:
      return pushSecondaryIndicatorResponses(state, action);
    case actionTypes.DEMO_ANSWER_SELECTED:
      return demographicAnswerSelected(state, action);
    case actionTypes.INC_DEMO_INDEX:
      return incrementDemographicQuestionIndex(state, action);
    case actionTypes.TOGGLE_DEMO_RADIO:
      return toggleDemographicRadioButton(state, action);
    case actionTypes.PUSH_SELECTED_VALIDITY_ANSWER:
      return pushSelectedValidityAnswer(state, action);
    case actionTypes.SET_SURVEY_SUBMITTED_AT:
      return setSurveySubmittedAt(state, action);
    case actionTypes.SAVE_SURVEY_RESPONSE_START:
      return saveSurveyResponseStart(state, action);
    case actionTypes.SAVE_SURVEY_RESPONSE_SUCCESS:
      return saveSurveyResponseSuccess(state, action);
    case actionTypes.SAVE_SURVEY_RESPONSE_FAIL:
      return saveSurveyResponseFail(state, action);
    case actionTypes.INCREMENT_QUESTION_INDEX:
      return incrementQuestionIndex(state,action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
