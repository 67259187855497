import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';

import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends Component {
  resetPassword = (username, code, newPassword) => {
    // Collect confirmation code and new password and submit
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(data => {
        console.log(data);
        this.props.history.push('/login');
      })
      .catch(err => console.log(err));
  };

  handleSubmit = values => {
    console.log('VALUES: ', values);
    this.resetPassword(values.username, values.code, values['new-password']);
  };

  render() {
    const breadcrumbs = [
      {
        key: 0,
        url: '/admin',
        label: 'Home',
      },
      {
        key: 1,
        label: 'Reset Password',
      },
    ];

    return (
      <div className="page reset-password">
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={breadcrumbs} />
        </div>
        <h1 className="mb-4">Reset Password</h1>
        <ResetPasswordForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default withRouter(ResetPassword);
