import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

class ForgotPasswordStartForm extends Component {
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className="text-center"
      >
        <Field
          name="username"
          placeholder="Email address"
          label="username"
          type="text"
          component="input"
          className="form-control"
        />
        <button type="submit" className="btn green small-button">Get New Password</button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'forgot-password-start'
})(ForgotPasswordStartForm);
