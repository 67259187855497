import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';

import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Button from 'react-bootstrap/Button';
import SearchSurveysForm from './SearchSurveyForm/SearchSurveyForm';
import * as actions from '../../../../store/actions';
import { dateFormat } from '../../../../utilities/dates';

class ListSurvey extends Component {
  componentDidMount() {
    if (this.props.isSurveySearchVisible) {
      this.props.hideSurveySearch();
    }
    this.props.listSurveys();
  }

  searchSubmissionHandler = () => {
    const values = {
      country: this.props.surveySearchCountryRegion,
      state: this.props.surveySearchStateProvince,
      district: this.props.surveySearchDistrict && this.props.surveySearchDistrict.orgId ? this.props.surveySearchDistrict.orgId : '',
      school: this.props.surveySearchSchool.id,
      year: this.props.surveySearchYear,
      name: this.props.surveySearchName,
    };
    console.log('search firing');
    this.props.listSurveys(values);
  };

  redirectToSurveyDetails = id => {
    this.props.history.push({
      pathname: '/admin/survey/details',
      state: {
        surveyId: id,
      },
    });
  };

  generateTableData = () => {
    // Waits for survey details to be populated
    if (this.props.surveys && this.props.surveys.length > 0) {
      // TODO: Do a Role Check here to determine what properties to set
      return this.props.surveys.map(item => {
        const obj = {};
        // if (this.props.role && this.props.role === 'tm_admin') {
        //   obj.state = item.state || this.props.surveySearchStateProvince || '';
        //   obj.districtName = <button className="button-text" onClick={() => this.changeRoleAndOrg(item, true)}>{item.districtName || `${this.props.surveySearchDistrict.name}`}</button> || '';
        // }

        if (this.props.role && this.props.role === 'tm_admin') {
          obj.schoolName =
            (
              <button className="button-text" onClick={() => this.changeRoleAndOrg(item)}>
                {item.schoolName}
              </button>
            ) || '';
        }

        // if and only if you are district champion you will see a school name with plain text instead of the context switch link
        if (this.props.role === 'district_champion') {
          obj.schoolName = item.schoolName;
        }

        obj.surveyName = (
          <button onClick={() => this.redirectToSurveyDetails(item.surveyId)} className="button-text">
            {this.isUK() ? item.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName}
          </button>
        );
        obj.date = moment(item.createdAt).format(dateFormat);
        obj.surveyStatus = item.surveyStatus;
        return obj;
      });
    }

    return [];
  };

  changeRoleAndOrg = (item, isParentOrg) => {
    console.log('CHANGE ROLE AND ORG ITEM: ', item);
    let orgId = item.orgId;
    let role = 'school_champion';
    if (isParentOrg) {
      orgId = item.parentOrgId;
      role = 'district_champion';
    }

    // CHANGE ROLE
    this.props.changeRole(role);
    // CHANGE ORGANIZATION
    this.props.changeOrganization(orgId);
    // REDIRECT
    this.props.history.push('/admin');
  };
  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  getBreadcrumbs = () => {
    return [
      {
        key: 1,
        url: '/admin/',
        label: `${this.isUK() ? 'Organisation Home' : 'Home'}`,
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: 'Assessments',
      },
    ];
  };

  render() {
    let headers = [`${this.isUK() ? 'ORGANISATION NAME' : 'GROUP NAME'}`, 'ASSESSMENT NAME', 'ASSESSMENT DATE', 'ASSESSMENT STATUS'];

    if (this.props.role && this.props.role === 'district_champion') {
      headers = [`${this.isUK() ? 'ORGANISATION NAME' : 'GROUP NAME'}`, 'ASSESSMENT NAME', 'ASSESSMENT DATE', 'ASSESSMENT STATUS'];
    }

    if (this.props.role && this.props.role === 'school_champion') {
      headers = ['ASSESSMENT NAME', 'ASSESSMENT DATE', 'ASSESSMENT STATUS'];
    }
    console.log('list surveys: ', this.props.surveys);

    return (
      <div className="survey-list-container">
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
          <div className="survey-list-button-header-container">
            {this.props.role === 'school_champion' ? (
              <Button id="locator_create-survey-button" onClick={() => this.props.history.push('/admin/survey/create')} className="small-button green">
                Create Assessment
              </Button>
            ) : null}
            <Button onClick={this.props.showSurveySearch} className="small-blue-button" disabled={this.props.isSurveySearchVisible}>
              Search Assessments
            </Button>
          </div>
        </div>
        <SearchSurveysForm onSubmit={this.searchSubmissionHandler} className={this.props.isSurveySearchVisible ? null : 'hidden'} />

        {this.props.isLoading ? (
          <Backdrop>
            <Spinner />
          </Backdrop>
        ) : (
          <>
            <p>{`${this.props.surveys && this.props.surveys.length} Assessment Results`}</p>
            <div className={this.props.surveys && this.props.surveys.length > 0 ? null : 'hidden'}>
              <TableGenerator
                config={{
                  headers: headers,
                  contentRows: this.generateTableData(),
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // isRosterUploading: state.surveyCrud.isRosterUploading
    isSurveySearchVisible: state.surveyCrud.isSurveySearchVisible,
    surveys: state.surveyCrud.surveys,
    surveySearchCountryRegion: state.surveyCrud.surveySearchCountryRegion,
    surveySearchStateProvince: state.surveyCrud.surveySearchStateProvince,
    surveySearchDistrict: state.surveyCrud.surveySearchDistrict,
    surveySearchSchool: state.surveyCrud.surveySearchSchool,
    surveySearchYear: state.surveyCrud.surveySearchYear,
    surveySearchName: state.surveyCrud.surveySearchName,
    isLoading: state.surveyCrud.isLoading,
    role: state.auth.role,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listSurveys: values => dispatch(actions.listSurveys(values)),
    showSurveySearch: () => dispatch(actions.showSurveySearch()),
    submitSurveySearch: () => dispatch(actions.submitSurveySearchStart()),
    changeRole: role => dispatch(actions.changeRole(role)),
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    hideSurveySearch: () => dispatch(actions.hideSurveySearch()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListSurvey));
