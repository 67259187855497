import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../../store/actions';
class SurveyNameSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      holdValue: '',
      categories: '0',
    };
  }

  capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleKeyDown = e => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      var value = this.state.value.trim();
      if (value) {
        this.setState({
          holdValue: value,
        });

      }
    }
  };
  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  handleBlur = e => {
    e.preventDefault();
    var value = this.state.value;
    if (value > '') {
      this.setState({
        holdValue: value,
      });
    } else {
      let placeholder = document.getElementById("surveyName").getAttribute("placeholder");
          this.setState({
            value: placeholder,
            holdValue: placeholder,
          });
      }
  };

  componentDidUpdate(prevProps, prevState){
    if (this.state.holdValue.length > 1){
      var surveyName = this.state.holdValue
      this.props.setSurveyNameSelect(surveyName)
    } 
    const chosenIndicators = this.props.chosenIndicators;
    const indicatorIdsByCategory = this.props.indicatorIdsByCategory
    const setCategories = Object.keys(indicatorIdsByCategory).filter(categoryName => {
      if (indicatorIdsByCategory[categoryName].some(elem => chosenIndicators.indexOf(elem) > -1)) {
        return categoryName
      } 
      return setCategories
    });
    if (prevProps.chosenIndicators !== this.props.chosenIndicators){
      let setStateCategories = setCategories.join(" ").toString().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());   
      this.setState({
        categories: setStateCategories,
      })
      // eslint-disable-next-line array-callback-return
    }
  }

	render() {

    const year = this.props.year;
    const quarter = this.props.quarter;
    const appliedTo = this.capitalize(this.props.appliedTo);
    const orgName = this.props.orgName;
    const value = this.state.value.length;
    const categories = this.state.categories;

    
    const generatedName = orgName + ' ' + quarter + ' ' + year + ' ' + appliedTo + ' ' + categories;
    if (value !== ''){
      var surveyName = generatedName
      this.props.setSurveyNameSelect(surveyName)
    }
  

		return (
			<>
				<div className="file-input-create-survey-step-group">				
          <div className="email-recipient-step">
            <input
              type="text"
              style={{width: '400px'}}
              className={"participant-entry-field input "}
              value={this.state.value}
              id={"surveyName"}
              placeholder={generatedName}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              maxLength={255}
            />
          </div>
        </div>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
    setSurveyNameSelect: (value) => dispatch(actions.setSurveyNameSelect(value))
  };
};

export default withRouter(
	connect(
    null,
		mapDispatchToProps,
	)(SurveyNameSelect)
);
