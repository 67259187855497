import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import getAuthenticatedUser from '../../utilities/getAuthenticatedUser';

const isAuthenticated = (WrappedComponent) => {
  class isAuthenticatedComponent extends Component {
    async componentDidMount() {
      await this.checkAndRedirect();
      await this.props.checkAuthState();
    }

    componentDidUpdate() {
      this.checkAndRedirect();
    }

    checkAndRedirect = async () => {
      const { history } = this.props;
      const user = await getAuthenticatedUser();

      if (!user) {
        history.push('/login');
      }
    };

    render() {
      return <>{this.props.isAuthenticated ? <WrappedComponent {...this.props} /> : null}</>;
    }
  }

  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      checkAuthState: () => dispatch(actions.checkAuthState())
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(isAuthenticatedComponent);
};



export default isAuthenticated;
