import React,{useState} from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import moment from 'moment';
const DateInput = ({ answerSelected }) => {
	const RenderDropDown = ({ input, data, placeholder, className, onChange, value }) => (
		<DropdownList {...input} className={className} placeholder={placeholder} data={data} onChange={onChange} value={value} />
	);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const days = [...Array(32).keys()].slice(1);
	var yearOptions = [];
	const currentYear = new Date().getFullYear();
	for (let index = 0; index < 79; index++) {
		yearOptions.push(currentYear - index); 
  }
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  if (day && month && year) {
    console.log(day, month, year)
    const date = moment(`${day}-${month}-${year}`, `DD-MMM-YYYY`).valueOf();
    answerSelected(true,date);
  }
	return (
		<div className="answerContainer">
      <div className="d-flex align-items-end mb-2">
        <div className="label-field-container">
          <label className="label">Month</label>
          <RenderDropDown data={months} placeholder="Month" name={`month`} className="tm-dropdown" onChange={value=>setMonth(value)} value={month}/>
        </div>
        <div className="label-field-container">
          <label className="label">Day</label>
          <RenderDropDown data={days} placeholder="Day" name={`day`} className="tm-dropdown" onChange={value=>setDay(value)} value={day}/>
        </div>
        <div className="label-field-container">
          <label className="label">Year</label>
          <RenderDropDown data={yearOptions} placeholder="Year" name={`year`} className="tm-dropdown" onChange={value=>setYear(value)} value={year}/>
        </div>
        
			</div>
		</div>
	);
};

export default DateInput;
