import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import BootStrapButton from 'react-bootstrap/Button';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../store/actions';

class AccessConfirmationModal extends Component {
	render() {
    const isUK = process.env.REACT_APP_STAGE === 'uk';
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<Modal.Header closeButton>
    <Modal.Title>{this.props.isAllowed ? 'Remove' : 'Allow' } {isUK ? 'MyMynd' : 'Terrace Metrics'} Access {this.props.isAllowed ? 'from' : 'to'} all our data</Modal.Title>
				</Modal.Header>
				<Modal.Body bsPrefix="modalContent survey-status-modal">
    <p>Do you confirm that you wish to {this.props.isAllowed ? 'remove' : 'grant' } access for {isUK ? 'MyMynd' : 'Terrace Metrics'} {this.props.isAllowed ? 'from' : 'to'} all of your user data?</p>
					<div className="btnContainer-edit-participant">
						<BootStrapButton
							onClick={() => {
								this.props.confirm();
							}}
							type="submit"
							className="modalBtn"
							size="lg"
							variant="success"
						>
						  Yes
						</BootStrapButton>
						<BootStrapButton
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							No
						</BootStrapButton>
					</div>
				</Modal.Body>
			</ModalWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(actions.closeModal())
	};
};

export default connect(
	null,
	mapDispatchToProps
)(AccessConfirmationModal);
