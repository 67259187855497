import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

import ModalWrapper from '../RootModal/ModalWrapper';
import icon from '../../assets/imgs/icon-warning.png';


class GenericModal extends Component {

  render() {
    return (
      <ModalWrapper show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img alt="Warning Icon" style={{ marginRight: '1rem' }} src={icon} />
            {this.props.headline}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent'>
          {this.props.content}
        </Modal.Body>
      </ModalWrapper>
    );
  }
};

export default GenericModal;
