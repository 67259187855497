import React, { Component } from 'react';
import { getLogo } from './../../../../assets/helper';


class GroupHeader extends Component {
  render() {
    const payload = this.props.payload;
    return (
      <header>
        <div className="container group-header">
          <div className="d-flex d-row">
            <div className="branding p-2">
              <img src={getLogo()} alt="Logo" />
            </div>
            <div className="ml-auto p-3 summary">
              <h2 className="mb-1">Resiliency Assessment</h2>
              <h3>Summary of Results</h3>
              <div className="locations">
                {payload.district}
                <br />
                {payload.school}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default GroupHeader;
