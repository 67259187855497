import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import BootStrapButton from 'react-bootstrap/Button';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import { dateFormat, adjustDate } from '../../../../../utilities/dates';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../../store/actions';
import DropdownList from 'react-widgets/lib/DropdownList';
class BulkEditModal extends Component {
	state = {
		participants: [],
		searchedParticipants: [],
    search: '',
    status: 'Not Completed'
	};
	componentWillMount() {
		const initialParticipants = this.props.participants
			.filter(
				participant =>
					participant.participantStatus === 'Not Started' || participant.participantStatus === 'Not Completed'
			)
			.map((participant, index) => {
				participant.checked = false;
				participant.position = index;
				return participant;
			});
		console.log(initialParticipants);
		this.setState({ participants: initialParticipants });
  }
  
  renderDropdown = ({ input, ...rest }) => <DropdownList {...input} {...rest} onChange={input.onChange} />;

  handleStatusChange = value => {
    console.log(value);
    this.setState({ status: value });
  };

	handleSubmit = () => {
		const surveyParticipantIds = this.state.participants
			.filter(participant => participant.checked === true)
			.map(participant => {
				return participant.surveyParticipantId;
      });
    const editParticipantsObject = {participantIds:surveyParticipantIds,status: this.state.status,surveyId:this.props.surveyId};
    console.log(editParticipantsObject);
    this.props.bulkUpdateParticipant(editParticipantsObject);
	};

	searchForNames = e => {
		const searchedParticipants = this.props.participants.filter(
			participant =>
				participant.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
				participant.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
				(participant.firstName.toLowerCase() + ' ' + participant.lastName.toLowerCase()).includes(
					e.target.value.toLowerCase()
				)
		);

		this.setState({ searchedParticipants, search: e.target.value });
	};

	handleClick = index => {
		console.log(index);
		const getClickedParticipant = () => {
			if (this.state.search !== '') {
				const participants = this.state.participants.map(participant => {
					if (participant.position === this.state.searchedParticipants[index].position) {
						participant.checked = !participant.checked;
					}
					return participant;
				});
				this.setState({ participants });
			} else {
				const participants = this.state.participants.map(participant => {
					if (participant.position === index) {
						participant.checked = !participant.checked;
					}
					return participant;
				});
				this.setState({ participants });
			}
		};
		getClickedParticipant();
	};

	generateTableData = () => {
		const result = this.state.participants.map(participant => ({
			name: `${participant.firstName} ${participant.lastName}`,
			DOB: adjustDate(participant.birthDate).format(dateFormat),
			selected: (
				<img
					className="checkbox  border border-dark rounded"
					style={{ cursor: 'pointer' }}
					alt="Checkbox"
					src={participant.checked ? CheckedImage : UncheckedImage}
				/>
			)
		}));
		if (this.state.search !== '')
			return this.state.searchedParticipants.map(participant => ({
				name: `${participant.firstName} ${participant.lastName}`,
				DOB: adjustDate(participant.birthDate).format(dateFormat),
				selected: (
					<img
						className="checkbox border border-dark rounded"
						alt="Checkbox"
						src={participant.checked ? CheckedImage : UncheckedImage}
					/>
				)
			}));
		return result;
	};
  handleIsSelectAll = () => {
    this.setState({
      isSelectAll: !this.state.isSelectAll,
      participants: this.state.participants.map(participant => {
        participant.checked = !this.state.isSelectAll;
        return participant;
      }),
    });
  };
	render() {
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Bulk Edit Participants</Modal.Title>
				</Modal.Header>
				<Modal.Body bsPrefix="modalContent survey-status-modal">
					<label className="label" htmlFor="status">
						Status
					</label>
          <input
						className="form-control"
						style={{ backgroundColor: '#eaeaea' }}
						type="text"
						onChange={this.searchForNames}
						value={this.state.search}
					/>
					<DropdownList
						data={['Not Started', 'Not Completed']}
						className="tm-dropdown"
						onChange={this.handleStatusChange}
						value={this.state.status}
					/>
					<label className="label">Search</label>
          <CheckBox
            className="select-all-invite-container"
            labelClasses={['select-all-invite']}
            label="Select All"
            name="Select All"
            value="Select All"
            isChecked={this.state.isSelectAll}
            onClick={this.handleIsSelectAll}
          />

          <div className='modal-table-container'>
            <TableGenerator
						config={{
							headers: ['PARTICIPANT', 'DATE OF BIRTH', ''],
							contentRows: this.generateTableData(),
							rowFunction: this.handleClick
						}}
					/>
          </div>
					

					<div className="btnContainer-edit-participant">
						<BootStrapButton
							onClick={() => this.handleSubmit()}
							type="submit"
							className="modalBtn"
							size="lg"
							variant="success"
						>
							Edit Participants
						</BootStrapButton>
						<BootStrapButton
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							Cancel
						</BootStrapButton>
					</div>
				</Modal.Body>
			</ModalWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		bulkUpdateParticipant: data => dispatch(actions.bulkUpdateParticipant(data)),
		closeModal: () => dispatch(actions.closeModal())
	};
};

export default connect(
	null,
	mapDispatchToProps
)(BulkEditModal);
