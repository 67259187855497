import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import axios from 'axios';
import BootStrapButton from 'react-bootstrap/Button';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import Typeahead from '../../../../../components/Typeahead/Typeahead';
import displayPhoneNumber from '../../../../../utilities/displayPhoneNumber';
const { country, stateData } = require('../../../../../utilities/locale/').getLocale();

// const renderOrgsTypeahead = (props) => {
//   console.log('typeahead props: ', props);
//   const { input } = props;
//   return (
//     <Typeahead
//       {...input}
//       handleSearch={props.handleSearch}
//       onChange={input.onChange}
//     />
//   );
// };

// const renderUsersTypeahead = (props) => {
//   console.log('typeahead props: ', props);
//   const { input } = props;
//   return (
//     <Typeahead
//       {...input}
//       handleSearch={props.handleSearch}
//       onChange={input.onChange}
//       labelKey="email"
//       disabled={!props.orgSearch}
//       renderMenuItemChildren={(option, props) => {
//         return (
//           <>
//             <span>{option.given_name} {option.family_name} ({option.email})</span>
//           </>
//         );
//       }}
//     />
//   );
// };

class CreateDistrictFormClever extends Component {
  state = {
    primary: this.props.initialValues.primary || false,
    secondary: this.props.initialValues.secondary || false,
    adult: this.props.initialValues.adult || false
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  toggleSurveyTypeCheckedPrimary = type => {
    if (type === 'primary') {
      this.setState({ primary: !this.state.primary });
    } else if (type === 'secondary') {
      this.setState({ secondary: !this.state.secondary });
    } else if (type === 'adult') {
      this.setState({ adult: !this.state.adult });
    }
  };

  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  handleOrgSearch = (query, setOptions, setIsLoading) => {
    console.log('query', query);
    console.log('setOptions: ', setOptions);

    setIsLoading(true);

    let classification = 'school-district';
    if (this.props.role === 'district_champion') {
      classification = 'school';
    }
    console.log('organization: ', this.props.organization);
    const { REACT_APP_API_URL } = process.env;
    const orgId = this.props.organization ? this.props.organization.orgId : '';
    const url = `${REACT_APP_API_URL}/org/search?classification=${classification}&parentOrgId=${orgId}&name=${query || ''}&elastic=true`;
    let options = [];

    axios({
      url,
      method: 'GET',
      headers: { Authorization: this.props.idToken },
    })
      .then(res => {
        //res.data.hasSearched = Object.entries(values).length > 0 && values.constructor === Object;
        // const data = res.data.map(org => {
        //   if (org.responderIds) {
        //     org.responderIds = org.responderIds.map(responderId => responderId.S);
        //   }
        //   return org;
        // });

        options = res.data;
        setOptions(options);
        setIsLoading(false);
        console.log('res Organizations success: ', res.data);
      })
      .catch(err => {
        console.log('Error searching orgs: ', err);
        setOptions(options);
        setIsLoading(false);
      });
  };

  handleUserSearch = (query, setOptions, setIsLoading) => {
    console.log('query', query);
    console.log('setOptions: ', setOptions);

    setIsLoading(true);
    const { REACT_APP_API_URL } = process.env;
    const orgId = this.props.orgSearch ? this.props.orgSearch[0].orgId : '';
    console.log('this.props in createDistrictFormClever : ', this.props);
    console.log('orgId in use search: ', orgId);
    const url = `${REACT_APP_API_URL}/users/search?orgId=${orgId}&name=${query || ''}`;
    let options = [];

    axios({
      url,
      method: 'GET',
      headers: { Authorization: this.props.idToken },
    })
      .then(res => {
        //res.data.hasSearched = Object.entries(values).length > 0 && values.constructor === Object;
        // const data = res.data.map(org => {
        //   if (org.responderIds) {
        //     org.responderIds = org.responderIds.map(responderId => responderId.S);
        //   }
        //   return org;
        // });

        console.log('response from user search: ', res);

        options = res.data;
        setOptions(options);
        setIsLoading(false);
        console.log('res Organizations success: ', res.data);
      })
      .catch(err => {
        console.log('Error searching orgs: ', err);
        setOptions(options);
        setIsLoading(false);
      });
  };

  renderDropdown = ({ input, data, valueField, textField, className, disabled }) => {
    return (
      <Dropdown
        {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        onChange={input.onChange}
        className={className}
        disabled={disabled}
      />
    );
  };

  orgChangeHandler = (value) => {
    console.log('orgChangeHandler: ', value);
    const formName = 'create-district-form-clever';
    const item = value[0];
    if (!item) return;
    this.props.dispatch(change(formName), 'search', value);
    this.props.dispatch(change(formName, 'name', item.name));
    this.props.dispatch(change(formName, 'country', item.country));
    this.props.dispatch(change(formName, 'state', { abbreviation: item.state }));
  }

  userChangeHandler = (value) => {
    console.log('userChangeHandler: ', value);
    const formName = 'create-district-form-clever';
    const item = value[0];
    if (!item) return;
    this.props.dispatch(change(formName), 'searchUser', value);
    this.props.dispatch(change(formName, 'firstName', item.firstName || item.given_name || ''));
    this.props.dispatch(change(formName, 'lastName', item.lastName || item.family_name || ''));
    this.props.dispatch(change(formName, 'phoneNumber', displayPhoneNumber(item.phoneNumber) || ''));
    this.props.dispatch(change(formName, 'email', item.email || ''));
  }

  renderOrgsTypeahead = (props) => {
    console.log('typeahead props: ', props);
    const { input } = props;
    return (
      <Typeahead
        {...input}
        handleSearch={props.handleSearch}
        disabled={props.disabled}
        onChange={input.onChange}
      />
    );
  };

  renderUsersTypeahead = (props) => {
    console.log('typeahead props: ', props);
    const { input } = props;
    return (
      <Typeahead
        {...input}
        handleSearch={props.handleSearch}
        onChange={input.onChange}
        labelKey="email"
        disabled={!props.orgSearch || props.disabled}
        renderMenuItemChildren={(option, props) => {
          return (
            <>
              <span>{option.given_name} {option.family_name} ({option.email})</span>
            </>
          );
        }}
      />
    );
  };

  render() {
    console.log('this.props for create district form: ', this.props);
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="label-field-container">
          <label className="label" htmlFor="search">Search {this.isUK() ? 'Groups' : 'Organizations'}</label>
          <Field name="search" disabled={this.props.item} component={this.renderOrgsTypeahead} handleSearch={this.handleOrgSearch} onChange={this.orgChangeHandler} />
        </div>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container">
            <label className="label" htmlFor="country">
              Country/Region
						</label>
            <Field name="country" component={this.renderDropdown} data={[country]} className="short" disabled={true} />
          </div>
          <div className="label-field-container">
            <label className="label" htmlFor="state">
              {this.isUK() ? 'County' : 'State/Province'}
            </label>
            <Field
              name="state"
              component={this.renderDropdown}
              data={stateData}
              textField="abbreviation"
              valueField="abbreviation"
              className="short"
              disabled={true}
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="name">
              {this.isUK() ? 'Group' : 'Organization'} Name
						</label>
            <Field
              name="name"
              placeholder={`Full ${this.isUK() ? 'Group' : 'Organization'} Name`}
              type="text"
              component="input"
              className="participant-entry-field"
              disabled={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-space-between mb-2">
          {this.props.initialValues.employerProvider ? (
            ''
          ) : (
              <div className="label-field-container">
                <label className="label" htmlFor="employerProvider">
                  {`Provider${this.isUK() ? '' : '(School)'}/Employer`}
                </label>
                <Field
                  name="employerProvider"
                  component={this.renderDropdown}
                  data={['Provider', 'Employer']}
                  className="tm-dropdown"
                />
              </div>
            )}

          <div className="label-field-container label-background-color">
            <label className="label">
              {this.isUK() ? 'Primary' : 'Elementary'}
              <img
                className="checkbox  border border-dark rounded"
                alt="Checkbox"
                src={this.state.primary ? CheckedImage : UncheckedImage}
              />
              <Field
                name="primary"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('primary');
                }}
              />
            </label>
          </div>
          <div className="label-field-container label-background-color">
            <label className="label">
              {this.isUK() ? 'Secondary' : 'Upper Grades'}
              <img
                className="checkbox border border-dark rounded"
                alt="Checkbox"
                src={this.state.secondary ? CheckedImage : UncheckedImage}
              />
              <Field
                name="secondary"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('secondary');
                }}
              />
            </label>
          </div>
          <div className="label-field-container label-background-color">
            <label className="label">
              Adult
							<img
                className="checkbox border border-dark rounded"
                alt="Checkbox"
                src={this.state.adult ? CheckedImage : UncheckedImage}
              />
              <Field
                name="adult"
                type="checkbox"
                component="input"
                onChange={() => {
                  this.toggleSurveyTypeCheckedPrimary('adult');
                }}
              />
            </label>
          </div>
        </div>
        {!this.props.item ? (
          <>
            <div className="label-field-container">
              <label className="label" htmlFor="searchUser">Search {this.isUK() ? 'Group' : 'Organization'} Users</label>
              <Field
                name="searchUser"
                component={this.renderUsersTypeahead}
                handleSearch={this.handleUserSearch}
                orgSearch={this.props.orgSearch}
                onChange={this.userChangeHandler}
                disabled={this.props.item}
              />
            </div>
            <div className="d-flex align-items-end mb-2">
              <div className="label-field-container">
                <label className="label" htmlFor="firstName">
                  Champion Name
								</label>
                <Field
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={true}
                />
              </div>
              <div className="label-field-container">
                <Field
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={true}
                />
              </div>
              <div className="label-field-container">
                <label className="label" htmlFor="email">
                  Champion Email
								</label>
                <Field
                  name="email"
                  placeholder="Email Address"
                  type="text"
                  component="input"
                  className="participant-entry-field"
                  disabled={true}
                />
              </div>
            </div>
            <div className="d-flex col-4 mb-4 p-0">
              <div className="label-field-container mr-2">
                <label className="label" htmlFor="phoneNumber">
                  Champion Phone
								</label>
                <Field
                  name="phoneNumber"
                  type="tel"
                  component={RenderPhoneField}
                  className="participant-entry-field"
                  disabled={true}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="btnContainer justify-content-end">
          <BootStrapButton
            type="submit"
            className="small-button green"
            size="lg"
            variant="success"
            disabled={invalid || pristine || submitting}
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton
            onClick={this.props.closeModal}
            className="small-blue-button-outlined"
            size="lg"
            variant="outline-primary"
          >
            Cancel
					</BootStrapButton>
        </div>
        <div className="d-flex align-items-center">
          {typeof this.props.error === 'string' ? <span className="error">{this.props.error}</span> : null}
        </div>
      </form>
    );
  }
}



CreateDistrictFormClever = reduxForm({
  form: 'create-district-form-clever'
})(CreateDistrictFormClever);

const selector = formValueSelector('create-district-form-clever') // <-- same as form name
CreateDistrictFormClever = connect(state => {
  // can select values individually
  const orgSearch = selector(state, 'search');
  return {
    orgSearch,
  }
})(CreateDistrictFormClever);

export default CreateDistrictFormClever;