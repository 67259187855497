


import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// import HOCS
import withLayout from '../../../hoc/Layout/Layout';

import isAuthenticated from '../../../hoc/isAuthenticated/isAuthenticated';
import ResponderHome from './ResponderHome/ResponderHome';


// Errors
import Error404 from '../../Errors/Error404';

class Responder extends Component {
  render() {
    return (
      <Switch>
        <Route path="/responder" exact component={isAuthenticated(withLayout(ResponderHome,{ showLogo: true, showContact: true, showUser: true }))} />
        <Route component={withLayout(Error404, { showLogo: true, showContact: true, showUser: true })} />
      </Switch>
    );
  }
}

export default Responder;

        
