import React, { Component } from 'react';
import { dateFormat } from '../../../../utilities/dates';
import dayjs from 'dayjs';
import ScoreBlock from './ScoreBlock';
import { withRouter } from 'react-router-dom';

import scoreMap from '../scoreMap';
import { getContent } from '../../../../utilities/getContent/getContent';
import moment from 'moment';

import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';

import BootStrapButton from 'react-bootstrap/Button';

import CheckBox from '../../../../components/CheckBox/CheckBox';

const isUK = process.env.REACT_APP_STAGE === 'uk';
const replacements = [
  {
    match: '{LEARNMORE_URL}',
    replacement: isUK ? 'https://www.mymynd.co.uk/parents' : 'http://www.terracemetrics.org/parents',
  },
  {
    match: '{LEARNMORE_PASSWORD}',
    replacement: 'TMparents',
  },
  {
    match: '{LEARNMORE_URL_ADULT}',
    replacement: isUK ? 'https://www.mymynd.co.uk/adults' : 'http://www.terracemetrics.org/adults',
  },
  {
    match: '{LEARNMORE_PASSWORD_ADULT}',
    replacement: 'TMadults',
  },
];

class TFSReport extends Component {
  handleIsShowingNotConcerningResponses = () => {
    this.props.toggleShowNotConcerningResponses();
  };

  getTimeDiff(start, stop) {
    start = new Date(start);
    stop = new Date(stop);
    let seconds = Math.floor((stop - start) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return { hours, minutes, seconds };
  }

  openConfirmationModal = confirm => {
    this.props.openModal({
      modalType: 'RETAKE_CONFIRMATION_MODAL',
      show: true,
      closeModal: this.props.closeModal(),
      confirm,
    });
  };
  /**
   * TimeSpan
   * Get h m s from two dates
   * @param {Date} start
   * @param {Date} stop
   */
  timeSpan(start, stop) {
    let timeDiff = this.getTimeDiff(start, stop);
    if (timeDiff.hours) {
      return `${timeDiff.hours}h ${timeDiff.minutes}m ${timeDiff.seconds}s`;
    } else {
      return `${timeDiff.minutes}m ${timeDiff.seconds}s`;
    }
  }

  /**
   * getPreviousScore is used to look at the previous
   * survey and return the previous risk level if possible
   * returns riskLevel or null
   * @param {Object} previousSurvey
   * @param {String} indicatorName
   */
  getPreviousScore(previousSurvey, indicatorName) {
    if (!previousSurvey || !previousSurvey.indicators || !indicatorName) {
      return null;
    }
    const { indicators } = previousSurvey;
    const foundPreviousIndicator = indicators.find(i => i.indicator === indicatorName);
    if (!foundPreviousIndicator || !foundPreviousIndicator.riskLevel) {
      return null;
    }
    return foundPreviousIndicator.riskLevel;
  }

  getIndicatorRiskLevel(indicators, indicatorName) {
    if (!indicators || !indicatorName) {
      return null;
    }
    const matchedIndicator = indicators.find(indicator => indicator.indicator === indicatorName);
    return matchedIndicator && matchedIndicator.riskLevel ? matchedIndicator.riskLevel : null;
  }

  render() {
    const payload = this.props.payload;
    const isAdult = payload.surveyName && payload.surveyName.includes('Adult');
    const tooFast = this.props.showTimeBox && payload && payload.createdAt && payload.isAllCore ? this.getTimeDiff(payload.surveyStartedAt, payload.surveySubmittedAt).minutes <= 3 : false;
    // const supplementalIndicators = payload.indicators.filter(indicator => indicator.category === 'supplemental');
    const indicatorsByType = {};
    const isParentReport = this.props.isParentReport;

    payload.indicators
      .filter(indicator => indicator.category !== 'supplemental')
      .forEach(indicator => {
        if (!indicatorsByType[indicator.type]) {
          indicatorsByType[indicator.type] = [];
        }
        indicatorsByType[indicator.type].push(indicator);
      });

    // const supplementalSurveyIndicators = payload.surveyIndicators.filter(indicator => indicator.category === 'supplemental');
    const surveyIndicatorsByType = {};
    payload.surveyIndicators
      .filter(indicator => indicator.category !== 'supplemental')
      .forEach(indicator => {
        if (!surveyIndicatorsByType[indicator.type]) {
          surveyIndicatorsByType[indicator.type] = [];
        }
        surveyIndicatorsByType[indicator.type].push(indicator);
      });
    console.log('surveyIndicatorsByType', surveyIndicatorsByType);
    const previousReportExists = !!payload.previous;

    const createdAt = dayjs(payload.createdAt).format(dateFormat);
    let previousCreatedAt;
    if (previousReportExists && payload && payload.previous) {
      previousCreatedAt = payload.previous.createdAt ? dayjs(payload.previous.createdAt).format(dateFormat) : null;
    }

    const professionQuestions = payload.professionQuestions;
    return (
      <div className="page page-report-results container">
        <div className="row d-print-block clearfix">
          {/* Main Content */}
          <main className="col-12 col-md-7 col-print-7">

            {/* Specific Indicator Results */}
            <h1 className="mb-2 mt-4">{getContent('report_heading-particular-indicator-scores')}</h1>
            <p className="mb-4">{getContent('report_content-particular-indicator-scores').replace('{payload.participant.firstName}', payload.participant.firstName)}</p>

            {/* {surveyIndicatorsByType['resilience'] && surveyIndicatorsByType['resilience'].length > 0 ? ( */}
              <table className="table table-sm table-1-3 table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>Sub Category</th>

                    <th>{getContent('report_table-heading-current-category')}</th>
                    {previousReportExists ? <th>{getContent('report_table-heading-previous-category')}</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {payload.indicators
                    .sort((a, b) => {
                      return a.type > b.type ? 1 : -1;
                    })
                    .map((node, ni) => {
                      return (
                        <tr key={ni}>
                          <td>
                            <div className="d-flex flex-row align-items-center justify-space-between">{getContent(node.contentId || node.indicator)}</div>
                          </td>

                          <td>
                            <ScoreBlock
                              score={this.getIndicatorRiskLevel(payload.indicators, node.indicator)}
                              solid={true}
                              showLink={this.props.isParentReport ? true : false}
                              indicator={node.indicator}
                              isAdult={isAdult}
                            />
                          </td>
                          {this.getPreviousScore(payload.previous, node.indicator) ? (
                            <td>
                              <ScoreBlock score={this.getPreviousScore(payload.previous, node.indicator)} solid={false} showLink={false} />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            {/* ) : null} */}

            {/* {isParentReport ? null : (
              <CheckBox
                className='show-not-concerning-responses'
                labelClasses={['show-not-concerning-responses']}
                label='Show responses'
                name='Show not concerning responses'
                value='Show not concerning responses'
                isChecked={this.props.isShowingNotConcerningResponses}
                onClick={this.handleIsShowingNotConcerningResponses}
              />
            )} */}
          </main>

          {/* Side Content */}
          <aside className="col-12 col-md-4 col-print-4 offset-print-1 offset-md-1">
            
            {tooFast ? (
              <div className={`mb-4 p-3 block text-center ${tooFast ? 'block-outline-red' : 'block-outline-grey'}`}>
                <div className="mb-2 font-size-sm">
                  Time Spent: <strong>{this.timeSpan(payload.surveyStartedAt, payload.surveySubmittedAt)}</strong>
                </div>
                {payload.avgSurveyTime ? (
                  <div className="font-size-xs">
                    Avg Time: <strong>{this.timeSpan(0, payload.avgSurveyTime)}</strong>
                  </div>
                ) : null}
              </div>
            ) : null}

            <h2 className="mb-2">{getContent('report_heading-interpreting-categories')}</h2>
            <p className="mb-4">{getContent('report_content-interpreting-categories')}</p>
          </aside>
          {this.props.isParentReport && !this.props.isWellnessCenterHome ? (
            <div className="page-footer mt-3 col-12">
              <h2 className="mb-2">{!payload.surveyName.includes('Adults') ? getContent('report_heading-for-more-information') : getContent('report_heading-for-more-information-adult')}</h2>
              {isAdult ? <p>{getContent('report_content-for-more-information-adult', replacements)}</p> : <p>{getContent('report_content-for-more-information', replacements)}</p>}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isShowingNotConcerningResponses: state.reports.isShowingNotConcerningResponses,
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(actions.closeModal()),
  openModal: modalProps => dispatch(actions.openModal(modalProps)),
  toggleShowNotConcerningResponses: () => dispatch(actions.toggleShowNotConcerningResponses())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TFSReport));
