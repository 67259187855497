import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { formValueSelector } from 'redux-form';

const RenderPhoneField = ({ input, meta: { touched, error, warning }, className, disabled }) => {
  
  return (
    <>
      <PhoneInput
        inputClass={`${className} form-control`}
        defaultCountry={'us'}
        onlyCountries={['us']}
        placeholder="(513) 555-5555"
        disableAreaCodes={true}
        disableCountryCode={true}
        disableDropdown={true}
        buttonStyle={{ display: 'none' }}
        inputStyle={{ paddingLeft: '12px' }}
        {...input}
        isValid = {(value, country) => {
          return value.length >= 10;
        }}
      />
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
    </>
  );
};

export default RenderPhoneField;
