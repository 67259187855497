import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';

import 'react-widgets/dist/css/react-widgets.css';

const RenderDropdown = ({ input, data, placeholder, className, ...rest }) => {
  return <DropdownList {...input} {...rest} className={className} placeholder={placeholder} data={data} />;
};

export default RenderDropdown;
