import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import BootStrapButton from 'react-bootstrap/Button';
import { getContent } from '../../../utilities/getContent/getContent';
import ButtonArrow from '../../../components/Button/ButtonArrow';
import CheckedImage from '../../../assets/imgs/checked.png';
import UncheckedImage from '../../../assets/imgs/unchecked.png';
// import RenderDropdown from '../../../components/RenderDropdown/RenderDropdown';
// import { required } from '../../../utilities/validation';
import MaskedInput from 'react-text-mask';
// import { months, days, years } from '../../../utilities/dates';

const v1AccessCodeMask = false;
const v2AccessCodeMask = [
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
];

const renderKeyCode = ({ input, data, className, accessCodeVersion }) => (
  <MaskedInput
    mask={accessCodeVersion === 2 ? v2AccessCodeMask : v1AccessCodeMask}
    className={`${className}${accessCodeVersion === 2 ? ' v2' : ''}`}
    placeholder={accessCodeVersion === 2 ? 'XXXX-XXXX-XXXX-XXXX' : getContent('wellnesscenter_label-key-code')}
    data={data}
    {...input}
  />
);

class WellnessCenterAccessForm extends Component {
  constructor() {
    super();
    this.codeRecoveryEnabled = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange() {
    this.codeRecoveryEnabled.checked = !this.codeRecoveryEnabled.checked;
    console.log("CodeRecoveryEnabled:", this.codeRecoveryEnabled.checked);
    this.setState({ checked: this.codeRecoveryEnabled.checked });
  }
  render() {
    const CodeInputClass = this.codeRecoveryEnabled.checked ? "hidden" : "";
    const EmailInputClass = !this.codeRecoveryEnabled.checked ? "hidden" : "";
    return (
      <form className="access-form form-group" onSubmit={this.props.handleSubmit}>
        <div>
          <div>
            <label className="label">{getContent('wellnesscenter_label-first-name')}</label>
            <Field name="firstName" type="text" component="input" className="form-control input" />
          </div>
          <div>
            <label className="label">{getContent('wellnesscenter_label-last-name')}</label>
            <Field name="lastName" type="text" component="input" className="form-control input" />
          </div>
          <div id="codeInput" className={ CodeInputClass }>
            <label className="label">{getContent('wellnesscenter_label-key-code')}</label>
            <Field name="inviteCode" type="text" component={renderKeyCode} className="form-control input" accessCodeVersion={this.props.accessCodeVersion} />
            <button className="btn btn-link" onClick={this.props.toggleAccessCodeVersion}>
              {getContent('survey_button-my-key-code-looks-different')}
            </button>
          </div>
          <div id="emailInput" className={ EmailInputClass }>
            <p>To recover your key code, please enter your email address along with your first and last name. The code will be sent to you if this information matches in our system.</p>
            <label className="label">Email Address</label>
            <Field name="email" type="text" component="input" className="form-control input" />
          </div>
          <div className="label-field-container label-background-color">
            <label className="label">
              <span>
                I Don't Have My Invite Code
              </span>
							<img
                className="checkbox border border-dark rounded"
                alt="Checkbox"
                src={this.codeRecoveryEnabled.checked ? CheckedImage : UncheckedImage}
                />
              <Field
                name="codeRecovery"
                type="checkbox"
                component="input"
                checked={ this.codeRecoveryEnabled.checked }
                onChange={ this.handleChange }
                />
            </label>
          </div>
          {/* <div>
            <label className="label">{getContent('wellnesscenter_label-birthdate')}</label>
            <div className={`row birthday-fields ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order' : ''}`}>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-1' : ''}`}>
                <Field name="month" type="text" placeholder="Month" className="tm-dropdown short" data={months} textField="label" valueField="value" component={RenderDropdown} validate={[required]} />
              </div>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-0' : ''}`}>
                <Field name="day" type="text" placeholder="Day" className="tm-dropdown short" data={days} component={RenderDropdown} validate={[required]} />
              </div>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-2' : ''}`}>
                <Field name="year" type="text" placeholder="Year" className="tm-dropdown short" data={years} component={RenderDropdown} validate={[required]} />
              </div>
            </div>
          </div> */}
          <div className="flex-center">
            <ButtonArrow className="button green" type="submit" text={getContent('wellnesscenter_button-continue')} />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.meta.lang,
});

WellnessCenterAccessForm = connect(mapStateToProps)(WellnessCenterAccessForm);

export default reduxForm({
  form: 'wellness-center-access',
})(WellnessCenterAccessForm);
