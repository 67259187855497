import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RenderField from '../../../../components/RenderField/RenderField';
import { isValidPassword, confirmPasswordsMatch, required } from '../../../../utilities/validation';

class ChangePasswordForm extends Component {
  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        {/* <div className="form-group">
          <label
            htmlFor="oldPassword"
            className="label"
          >
            Old Password
          </label>
          <Field
            name="oldPassword"
            placeholder="Old Password"
            type="password"
            component={RenderField}
            className="input search-survey-input"
            validate={[required]}
          />
        </div> */}
        <div className="form-group">
          <label
            htmlFor="newPassword"
            className="label"
          >New Password</label>
          <Field
            name="newPassword"
            placeholder="New Password"
            type="password"
            component={RenderField}
            className="input search-survey-input"
            validate={[
              isValidPassword,
              required,
            ]}
          />
        </div>
        <div className="form-group mb-5">
          <label
            htmlFor="confirmPassword"
            className="label"
          >
            Confirm Password
          </label>
          <Field
            name="confirmPassword"
            placeholder="Confirm Password"
            type="password"
            component={RenderField}
            className="input search-survey-input"
            validate={[required]}
          />
        </div>
        <button
          type="submit"
          className="btn small-blue-button"
          disabled={invalid || pristine || submitting}
        >
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'change-password-form',
  validate: confirmPasswordsMatch,
})(ChangePasswordForm);
